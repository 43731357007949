import { useCallback, useEffect, useState } from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import MicIcon from "@mui/icons-material/Mic";
import Box from "../Unknown/Box";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import supabaseClient from "../../common/supabaseClient";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import HStack from "../Unknown/HStack";
import MenuLayout from "../Layout/MenuLayout";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { format } from "date-fns";

const Declarations: React.FC = () => {
  const [declarations, setDeclarations] = useState<any>([]);
  const [cDeclarations, setCdeclarations] = useState<any>([]);
  console.log("🚀 ~ cDeclarations:", cDeclarations);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");

  useMountedWaitAsyncEffect(
    async () => {
      if (text.trim().length > 3) {
        const { data } = await supabaseClient
          .from("declarations")
          .select()
          .ilike("name", `%${text.trim().toLowerCase()}%`)
          .limit(20);

        if (data) setCdeclarations(data);
      }
    },
    [text],
    1500,
  );

  useMountedWaitAsyncEffect(async () => {
    if (!declarations.length) {
      const { data } = await supabaseClient
        .from("declarations")
        .select()
        .order("order")
        .limit(100)
        .or("verified.is.false,verified.is.null");

      if (data) setDeclarations(data);
    }
  }, [declarations]);

  const updateButtons = useCallback(
    (d: any) => {
      const updateDeclaration = async () => {};

      return (
        <HStack>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            disabled={d.verified}
            onClick={async () => {
              try {
                setIsLoading(true);
                await supabaseClient
                  .from("declarations")
                  .update({
                    verified: true,
                    last_interaction: null,
                  })
                  .eq("id", d.id)
                  .throwOnError();
                const { data } = await supabaseClient
                  .from("declarations")
                  .select()
                  .eq("id", d.id)
                  .single()
                  .throwOnError();

                if (data && data.verified) {
                  setDeclarations(
                    declarations.map((dec: any) => {
                      if (dec.id === d.id) {
                        return {
                          ...dec,
                          ...data,
                        };
                      }
                      return dec;
                    }),
                  );
                  setCdeclarations(
                    cDeclarations.map((dec: any) => {
                      if (dec.id === d.id) {
                        return {
                          ...dec,
                          ...data,
                        };
                      }
                      return dec;
                    }),
                  );

                  const count: number = await getNotVerifiedCount();
                  setDCount(count);

                  setIsLoading(false);
                }
              } catch (err: any) {
                alert(err.message);
                setIsLoading(false);
              }
            }}
          >
            Обновлено
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="warning"
            // disabled={!d.verified}
            onClick={async () => {
              try {
                setIsLoading(true);
                await supabaseClient
                  .from("declarations")
                  .update({
                    order: d.order + 2000,
                    last_interaction: new Date().toISOString(),
                  })
                  .eq("id", d.id)
                  .throwOnError();

                const { data } = await supabaseClient
                  .from("declarations")
                  .select()
                  .eq("id", d.id)
                  .single()
                  .throwOnError();

                if (data) {
                  setDeclarations(
                    declarations.map((dec: any) => {
                      if (dec.id === d.id) {
                        return {
                          ...dec,
                          ...data,
                        };
                      }
                      return dec;
                    }),
                  );
                  setCdeclarations(
                    cDeclarations.map((dec: any) => {
                      if (dec.id === d.id) {
                        return {
                          ...dec,
                          ...data,
                        };
                      }
                      return dec;
                    }),
                  );

                  const count: number = await getNotVerifiedCount();
                  setDCount(count);

                  setIsLoading(false);
                }
              } catch (err: any) {
                console.log("🚀 ~ err:", err);
                alert(err?.message);
                setIsLoading(false);
              }
            }}
          >
            <AccessTimeIcon sx={{ fontSize: 28 }} />
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="error"
            disabled={!d.verified}
            onClick={async () => {
              try {
                setIsLoading(true);
                const result = await supabaseClient
                  .from("declarations")
                  .update({
                    verified: false,
                  })
                  .eq("id", d.id)
                  .throwOnError();

                const { data } = await supabaseClient
                  .from("declarations")
                  .select()
                  .eq("id", d.id)
                  .single()
                  .throwOnError();

                if (data) {
                  setDeclarations(
                    declarations.map((dec: any) => {
                      if (dec.id === d.id) {
                        return {
                          ...dec,
                          verified: data.verified,
                        };
                      }
                      return dec;
                    }),
                  );
                  setCdeclarations(
                    cDeclarations.map((dec: any) => {
                      if (dec.id === d.id) {
                        return {
                          ...dec,
                          verified: data.verified,
                        };
                      }
                      return dec;
                    }),
                  );

                  const count: number = await getNotVerifiedCount();
                  setDCount(count);

                  setIsLoading(false);
                }
              } catch (err: any) {
                console.log("🚀 ~ err:", err);
                alert(err?.message);
                setIsLoading(false);
              }
            }}
          >
            Потребує обновленння
          </LoadingButton>
        </HStack>
      );
    },
    [cDeclarations, declarations, isLoading],
  );

  const [dCount, setDCount] = useState(0);

  const getNotVerifiedCount = async () => {
    const { data } = await supabaseClient.rpc("get_not_verified_declarations");

    return data as number;
  };
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  useEffect(() => {
    setText(transcript);
  }, [transcript]);

  useEffect(() => {
    if (!text.trim()) {
      setCdeclarations([]);
    }
  }, [text]);

  useMountedWaitAsyncEffect(async () => {
    const count: number = await getNotVerifiedCount();
    setDCount(count);
  }, []);

  return (
    <MenuLayout isFluid>
      <Box p={5} mx={-5}>
        <Box mb={5}>
          <HStack>
            <Typography variant="h3">Декларації</Typography>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={async () => {
                const { data } = await supabaseClient
                  .from("declarations")
                  .select()
                  .order("order")
                  .limit(100)
                  .or("verified.is.false,verified.is.null");

                if (data) setDeclarations(data);
              }}
            >
              Загрузити 100 декларацій
            </Button>
            <HStack width="100%">
              <IconButton
                onClick={() => {
                  SpeechRecognition.startListening({ language: "uk-UA" });
                }}
              >
                <MicIcon
                  sx={{
                    fontSize: 30,
                    color: listening ? "#4aa805" : "black",
                  }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  setText("");
                }}
              >
                <ClearIcon
                  sx={{
                    fontSize: 30,
                    color: "red",
                  }}
                />
              </IconButton>
            </HStack>
            <TextField
              color="primary"
              value={text}
              fullWidth
              placeholder="Імʼя"
              label="Декларація"
              onChange={(event) => {
                setText(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
          </HStack>
        </Box>
        {!!cDeclarations.length && (
          <Box
            pb={3}
            sx={{
              backgroundColor: "#f5fff2",
              padding: "12px",
              marginBottom: "30px",
              borderLeft: "5px solid #76f876",
            }}
          >
            <Typography variant="h3" color="error">
              Вибрані декларації
            </Typography>
            {cDeclarations.map((d: any, index: any) => {
              return (
                <Box key={index} mb={2}>
                  {!!d.last_interaction && (
                    <Typography
                      color="error"
                      fontWeight={700}
                      display="flex"
                      alignItems="center"
                      sx={{ py: 2 }}
                    >
                      <PriorityHighIcon /> Недоступно:{" "}
                      {format(new Date(d.last_interaction), "yyyy-MM-dd HH:mm")}
                    </Typography>
                  )}
                  <Typography
                    variant="h4"
                    sx={{
                      textDecoration: d.verified ? "line-through" : "auto",
                    }}
                  >
                    {d.name}
                  </Typography>
                  <Typography
                    sx={{
                      textDecoration: d.verified ? "line-through" : "auto",
                    }}
                  >
                    {d.birth}
                  </Typography>
                  <Box component="a" sx={{ display: "inline-block" }} href={`tel:${d.phone}`}>
                    <Typography
                      sx={{
                        textDecoration: d.verified ? "line-through" : "auto",
                      }}
                    >
                      {d.phone}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      textDecoration: d.verified ? "line-through" : "auto",
                    }}
                  >
                    {d.address}
                  </Typography>
                  {updateButtons(d)}
                </Box>
              );
            })}
          </Box>
        )}
        <Box>
          <Box mb={3}>
            <Typography variant="h3" color="error">
              Необновлені декларації
            </Typography>
            <HStack gap={5}>
              <Typography variant="caption" color="error">
                Показано: {declarations.length}
              </Typography>
              <Typography variant="caption" color="error">
                Всі: {dCount}
              </Typography>
            </HStack>
          </Box>
          {declarations.map((d: any, index: any) => {
            return (
              <Box key={index} mb={2}>
                <Typography
                  variant="h4"
                  sx={{
                    textDecoration: d.verified ? "line-through" : "auto",
                  }}
                >
                  {d.name}
                </Typography>
                <Typography
                  sx={{
                    textDecoration: d.verified ? "line-through" : "auto",
                  }}
                >
                  {d.birth}
                </Typography>
                <Box component="a" sx={{ display: "inline-block" }} href={`tel:${d.phone}`}>
                  <Typography
                    sx={{
                      textDecoration: d.verified ? "line-through" : "auto",
                    }}
                  >
                    {d.phone}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    textDecoration: d.verified ? "line-through" : "auto",
                  }}
                >
                  {d.address}
                </Typography>
                {!!d.last_interaction && (
                  <Typography
                    color="error"
                    fontWeight={700}
                    display="flex"
                    alignItems="center"
                    sx={{ py: 2 }}
                  >
                    <PriorityHighIcon /> Недоступно:{" "}
                    {format(new Date(d.last_interaction), "yyyy-MM-dd HH:mm")}
                  </Typography>
                )}
                {updateButtons(d)}
              </Box>
            );
          })}
        </Box>
      </Box>
    </MenuLayout>
  );
};

export default Declarations;
