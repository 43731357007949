import { Box, Button, Typography } from "@mui/material";
import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import ModalScheme from "../../../ModalScheme";
import { useWindowSize } from "react-use";
import removePunctuationFromString from "../../../../common/removePunctuationFromString";
import ItalicTitle from "../../../Title/ItalicTitle";
import Quote from "../../../Unknown/Quote";
import VStack from "../../../Unknown/VStack";
import HStack from "../../../Unknown/HStack";
import LinearProgress from "../../../User/LinearProgress";

export const EnWordsSyllables: React.FC = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  return (
    <Box>
      <Box mb={2}>
        <ItalicTitle text="Слова які мають дві голосні але мають один склад:" />
        <Typography gutterBottom>
          <strong>Правило: </strong>голосна літера не створює склад якщо ця літера не вимовляється
        </Typography>
        <ItalicTitle text="line" />, &nbsp;
        <ItalicTitle text="nine" />, &nbsp;
        <ItalicTitle text="pine" />, &nbsp;
        <ItalicTitle text="site" />
        <br />
        <br />
        <ItalicTitle text="Винятки:" />
        <Typography>
          <strong>Правило: </strong> <strong>приголосна</strong> літера + <strong>le</strong> у
          кінці слова <strong>завжди</strong> є складом
        </Typography>
        <ItalicTitle text="little" />, &nbsp;
        <ItalicTitle text="bottle" />, &nbsp;
        <ItalicTitle text="battle" />, &nbsp;
        <ItalicTitle text="bible" />
      </Box>

      <VStack py={10}>
        <Typography gutterBottom variant="h3">
          Розділи слова на склади
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <SimpleCarousel
            elements={[
              {
                rule: (
                  <>
                    будь яка <strong>приголосна літера</strong> + <strong>le</strong> у кінці слова
                    завжди є складом.
                    <br />
                    Наприклад: <strong>ple, ble, tle, gle, mle, nle...</strong>
                  </>
                ),
                words: ["lit-tle", "ap-ple", "bot-tle", "bat-tle", "bi-ble"],
              },
              {
                rule: (
                  <>
                    якщо у слові <strong>дві одинакові приголосні</strong> підряд, та після є{" "}
                    <strong>голосна літера</strong> або <strong>le</strong>, то склади розділяються
                    між ними
                  </>
                ),
                words: ["lit-tle", "ap-ple", "ten-nis"],
              },
            ]}
          />
        </Box>
      </VStack>
    </Box>
  );
};

const SimpleCarousel: React.FC<{ elements: any }> = ({ elements: elm }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const elements: { word: string; rule: string }[] = useMemo(() => {
    return elm
      .map((i: any) => {
        return i.words.map((w: string) => {
          return { word: w, rule: i.rule };
        });
      })
      .flat();
  }, [elm]);

  const currentElement = useMemo(() => {
    return elements[currentIndex];
  }, [elements, currentIndex]);

  const letters = useMemo(() => {
    return currentElement?.word?.split("");
  }, [currentElement]);

  const [currentWord, setCurrentWord] = useState("");

  useEffect(() => {
    setCurrentWord(currentElement?.word?.replaceAll("-", "_"));
  }, [currentElement, currentIndex]);

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    let timer: any = null;
    if (currentElement?.word === currentWord) {
      timer = setTimeout(() => {
        setIsSelected(true);
      }, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [currentWord, currentElement]);

  if (currentIndex === elements.length) {
    return (
      <Box>
        <Box py={5}>
          <Typography textAlign="center" variant="h3">
            На цьому все
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            setCurrentIndex(0);
          }}
        >
          Почати з початку
        </Button>
      </Box>
    );
  }

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <LinearProgress height={5} currentValue={currentIndex} elementsCount={elements.length - 1} />

      <Box mb={5} mt={4}>
        <Box mb={4}>
          <Typography gutterBottom maxWidth={400}>
            <strong>Правило:</strong> {currentElement.rule}
          </Typography>
        </Box>
        <VStack>
          <HStack gap={0}>
            {isSelected && <ItalicTitle size={25} text={currentElement.word} />}
            {!isSelected &&
              letters.map((l, i) => {
                return (
                  <Fragment key={i}>
                    {l !== "-" ? (
                      <Letter
                        isSilent={
                          l === "e" &&
                          i === letters.length - 1 &&
                          letters[letters.length - 2] !== "l"
                        }
                        letters={letters.join("")}
                        letter={l}
                      />
                    ) : (
                      ""
                    )}
                    {letters[i + 1] !== "-" && i !== letters.length - 1 && (
                      <Dash
                        index={i}
                        letters={letters}
                        onSuccess={() => {
                          setCurrentWord(
                            currentWord
                              .split("")
                              .map((l, index) => {
                                if (index === i) return "-";
                                return l;
                              })
                              .join(""),
                          );
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
          </HStack>
        </VStack>
      </Box>
      <Button
        variant="outlined"
        disabled={!isSelected}
        onClick={() => {
          setCurrentIndex((prev) => prev + 1);
          setIsSelected(false);
        }}
      >
        Далі
      </Button>
    </Box>
  );
};

const Dash: React.FC<{ letters: string[]; index: number; onSuccess: () => void }> = ({
  letters,
  index,
  onSuccess,
}) => {
  const [color, setColor] = useState("hsl(0deg 0% 50.2% / 14%)");

  useEffect(() => {
    let timer: any;
    if (color === "red") {
      timer = setTimeout(() => {
        setColor("hsl(0deg 0% 50.2% / 14%)");
      }, 2000);

      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [color]);

  return (
    <Typography
      sx={{
        width: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: `rgb(${Math.floor(Math.random() * 255)},${Math.floor(
        //   Math.random() * 255,
        // )},${Math.floor(Math.random() * 255)})`,
        fontWeight: 700,
        fontSize: 30,
        color: color,
        cursor: "pointer",
      }}
      onClick={() => {
        if (letters[index] === "-") {
          setColor("green");
          onSuccess();
        } else {
          setColor("red");
        }
      }}
    >
      -
    </Typography>
  );
};
const Letter: React.FC<{ isSilent: boolean; letter: string; letters: string }> = ({
  letter,
  letters,
  isSilent,
}) => {
  const [color, setColor] = useState("white");

  useEffect(() => {
    setColor("white");
  }, [letters]);

  useEffect(() => {
    let timer: any;
    if (color === "red") {
      timer = setTimeout(() => {
        setColor("white");
      }, 1000);

      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [color]);

  return (
    <Typography
      sx={{
        cursor: "pointer",
        px: 1,
        position: "relative",
        "&::before": {
          content: '" "',
          width: "calc(100% + 2px)",
          left: "-1px",
          bottom: "-2px",
          position: "absolute",
          height: 4,
          backgroundColor: color === "green" ? "black" : "transparent",
        },
      }}
      onClick={() => {
        if (["a", "i", "o", "y", "e", "u"].includes(letter) && !isSilent) {
          setColor("green");
        } else {
          setColor("red");
        }
      }}
    >
      <ItalicTitle text={letter} />
    </Typography>
  );
};
