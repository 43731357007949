import { useEffect, useMemo, useState } from "react";
import { useLessonsStore } from "../../store/lessonsStore";
import { Word } from "../../types/supabase";
import useGlobalWords from "./getGlobalWords";

const useGlobalWordsMap = ({ words }: { words: string[] }): Record<string, Word> => {
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});

  const options = useMemo(() => {
    return { words };
  }, [words]);

  useGlobalWords(options);

  useEffect(() => {
    if (words?.length) {
      const map = globalWords
        .filter((w) => words.includes(w.name) || words.includes(w.name.toLowerCase()))
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
          };
        }, {});

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, words]);

  return curerntModuleWordsMap;
};

export default useGlobalWordsMap;
