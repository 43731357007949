import SimpleLessonTasks, { LessonRule } from "../../LearnToRead/SimpleLessonTasks";
import HStack from "../../Unknown/HStack";
import ShowLastAndPrevCountButtons from "../../Unknown/ShowLastAndPrevCountButtons";

const SimpleLessonTasksContainer: React.FC<{
  rules: LessonRule[];
}> = ({ rules }) => {
  return (
    <HStack>
      <ShowLastAndPrevCountButtons type="rules" />
      <SimpleLessonTasks rules={rules} />
    </HStack>
  );
};

export default SimpleLessonTasksContainer;
