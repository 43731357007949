import { Button, Checkbox } from "@mui/material";
import WordsCarousel from "../../Grammar/GrammarLesson/tabs/WordsCarousel";
import HStack from "../../Unknown/HStack";
import BuildWords from "../../LearnToRead/BuildWords";
import { useMemo, useState } from "react";
import { Word } from "../../../types/supabase";
import useGlobalWordsMap from "../../hooks/useGlobalWordsMap";
import getRandomGreetings from "../../../common/getRandomGreetings";
import ShowLastAndPrevCountButtons from "../../Unknown/ShowLastAndPrevCountButtons";

const BuildWordsContainer: React.FC<{
  words: string[];
}> = ({ words }) => {
  const currentModuleWordsMap = useGlobalWordsMap({ words });

  const wordsGreeting = useMemo(() => {
    return getRandomGreetings("Всі слова складені.");
  }, []);

  return (
    <HStack>
      <ShowLastAndPrevCountButtons type="build-words" />
      <BuildWords
        curentModuleWordsMap={currentModuleWordsMap}
        wordsGreeting={wordsGreeting}
        moduleWords={words || []}
        wordsLength={words.length}
        withoutWordNumber
      />
    </HStack>
  );
};

export default BuildWordsContainer;
