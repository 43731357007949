const parsed = (something: any, dfl: any): any => {
  let somethingParsed: any;
  try {
    if (something) {
      somethingParsed = JSON.parse(something);
      if (typeof somethingParsed === "string") {
        try {
          somethingParsed = JSON.parse(somethingParsed);
        } catch (err) {
          console.error(err);
        }
      }
    }
  } catch (err: any) {
    console.error(err.message);
  }

  return somethingParsed || dfl;
};

export default parsed;
