import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import "draft-js/dist/Draft.css";
import { useEffect, useRef, useState } from "react";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import AdminUsers from "../../AdminUsers";
import "./root.css";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import HStack from "../HStack";
import { useLocation } from "react-router-dom";
import { RPCUser } from "../../../common/fetchCurrentUser";
import VStack from "../VStack";

// const Size = Quill.import("attributors/style/size");
// Size.whitelist = ["small", "medium", "large", "huge"]; // Allowed sizes
// Quill.register(Size, true);

const fontSizes = ["small", "medium", "large", "huge"];

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [{ size: fontSizes }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    // [{ color: [] }], // Add the color picker to the toolbar
    [
      { color: "black" },
      { color: "rgb(230, 0, 0)" },
      { color: "rgb(255, 153, 0)" },
      { color: "rgb(0, 138, 0)" },
      { color: "rgb(0, 102, 204)" },
      { color: "rgb(153, 51, 255)" },
    ],
    ["clean"],
  ],
};

const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

const chatModalStyle = {
  position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 400,
  width: "100vw",
  height: "100%",
  overflow: "auto",
  bgcolor: "background.paper",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  zIndex: 999999999999,
  // justifyContent: "center",
  // alignItems: "center",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 4,
};

const ControllingStudentPage: React.FC<{ isNested?: boolean }> = ({ isNested }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);

    await supabaseClient
      .from("dialog-admin")
      .update({
        open: false,
      })
      .eq("id", "chat");
  };

  const inputRef = useRef<any>(null);
  const authUser = useLessonsStore((state) => state.authUser);
  const [users, setUsers] = useState<any[]>([]);
  const [dbChat, setDbChat] = useState<any>();
  const setRPCUser = useLessonsStore((state) => state.setRPCUser);
  // const [studentUserId, setStudentUserId] = useState<string | null>(null);
  // const [studentUser, setStudentUser] = useState<RPCUser | null>(null);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  const isUserBoardOpen = useLessonsStore((state) => state.isUserBoardOpen);
  const userBoardText = useLessonsStore((state) => state.userBoardText);
  const quillRef = useRef<any>(null);
  const [currentSize, setCurrentSize] = useState("medium");
  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [open, inputRef]);

  const endSession = async () => {
    const result = await supabaseClient
      .from("admin_control")
      .update({ user: null, is_teacher_lesson: false, board_is_open: false })
      .eq("id", 0);

    setStudentUser(null);
  };

  useMountedWaitAsyncEffect(async () => {
    // if (isAdmin(authUser?.id)) {
    // }
    const { data } = await supabaseClient
      .from("admin_control")
      .select()
      .eq("id", "0")
      .single()
      .throwOnError();
    if (data) setDbChat(data);

    // const channel = supabaseClient
    //   .channel("room5")
    //   .on(
    //     "postgres_changes",
    //     { event: "UPDATE", schema: "public", table: "dialog-admin" },
    //     (payload) => {
    //       // console.log("🚀 ~ payload:", payload, payload.new.id === "words_moving");
    //       if (payload.new.id === "chat") {
    //         setDbChat(payload.new as any);
    //       }
    //       if (payload.new.id === "words_moving") {
    //         if (authUser?.uuid === payload.new.user) {
    //           setRPCUser({
    //             ...authUser,
    //             type: payload.new.type,
    //             studentUserId: payload.new.user,
    //           } as any);
    //         }
    //       }
    //     },
    //   )
    //   .subscribe();

    // return () => {
    //   channel.unsubscribe();
    // };
  }, []);

  useMountedWaitAsyncEffect(async () => {
    if (isAdmin(authUser?.id) && !users.length) {
      const { data } = await supabaseClient.from("users").select();
      if (data) setUsers(data);
    }
  }, [users, authUser]);

  useEffect(() => {
    if (isAdmin(authUser?.id)) {
      const handleKeyDown = async (event: any) => {
        console.log("🚀 ~ event:", event);
        if (!event.ctrlKey && event.shiftKey && event.metaKey && event.keyCode === 13) {
          setOpen(true);
        } else if (event.ctrlKey && event.metaKey && event.keyCode === 13) {
          setEditorState("");

          await supabaseClient
            .from("admin_control")
            .update({
              board_text: "",
              board_is_open: true,
            })
            .eq("id", 0);
          setOpen(true);
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [authUser]);

  useEffect(() => {
    if (isUserBoardOpen && isTeacherLesson) {
      setOpen(true);
    } else if (authUser && !isAdmin(authUser.id)) {
      setOpen(false);
      // if (dbChat.user !== authUser.uuid || !dbChat.open) {
      // }
    }
  }, [dbChat, isTeacherLesson, isUserBoardOpen]);

  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useState("");
  console.log("🚀 ~ editorState:", editorState);

  useEffect(() => {
    if (!isAdmin(authUser?.id)) {
      setEditorState(userBoardText);
    }
  }, [authUser, userBoardText]);

  useMountedWaitAsyncEffect(
    async () => {
      if (isAdmin(authUser?.id)) {
        // const text = editorState
        //   .split("\n")
        //   .map((v) => v.trim())
        //   .filter(Boolean);
        console.log("🚀 ~ editorState:", editorState);

        await supabaseClient
          .from("admin_control")
          .update({
            board_text: editorState,
            // text: text.reverse(),
            // open: !!text.length,
            board_is_open: !!editorState.trim(),
          })
          .eq("id", 0);
      } else {
        // setEditorState(dbChat.text[0])
      }
    },
    // [textValue, authUser],
    [editorState, authUser],
    200,
  );

  useMountedWaitAsyncEffect(async () => {
    if (isAdmin(authUser?.id)) {
      await supabaseClient
        .from("admin_control")
        .update({
          board_is_open: open,
        })
        .eq("id", 0);
    }
  }, [open, authUser]);

  // useEffect(() => {
  //   if (!isAdmin(authUser?.id)) {
  //     setEditorState(dbChat.board_text);
  //   }
  // }, [dbChat]);

  const applyDefaultStyle = () => {
    const editor = document.querySelector(".ql-editor");
    console.log("🚀 ~ editor:", editor);
    if (editor) {
      // Apply default font size to all unstyled content
      // editor.querySelectorAll(":not([style])").forEach((el: any) => {
      //   el.style.fontSize = "16px"; // Set default font size
      //   el.style.fontFamily = "Arial, sans-serif"; // Set default font family
      // });
    }
  };

  const content = (
    <Box maxWidth={600} mb={2} width="100%">
      {!isAdmin(authUser?.id) && (
        <>
          <Box display="flex" flexDirection="column" alignItems="revert">
            {/* {dbChat.text.map((text: string) => {
          return (
            <Typography variant="body1" fontSize={25} width="100%" gutterBottom>
              {text}
            </Typography>
          );
        })} */}
            <ReactQuill
              theme="snow"
              value={editorState}
              // placeholder="Baba nam kevalam"
              onChange={setEditorState}
              readOnly={true} // Make the editor read-only
              modules={{ toolbar: false }} // Disable the toolbar
              // modules={modules}
              // formats={formats}
            />
          </Box>
        </>
      )}
      {isAdmin(authUser?.id) && (
        <>
          <HStack gap={2}>
            <Button variant="contained" size="small" color="error" onClick={endSession}>
              end session
            </Button>
            <AdminUsers
              users={users}
              onChange={async (user) => {
                await supabaseClient
                  .from("admin_control")
                  .update({ user: user?.id || null, is_teacher_lesson: !!user?.id })
                  .eq("id", 0);
              }}
            />
            {studentUser && studentUser.read_lesson && (
              <VStack gap={0} width="auto">
                <Typography variant="caption">reading</Typography>
                <Box display="flex" alignItems="center">
                  <Typography>{studentUser.read_lesson}</Typography>
                  <IconButton
                    size="small"
                    onClick={async () => {
                      await supabaseClient
                        .from("users")
                        .update({
                          read_lesson: studentUser.read_lesson! + 1,
                        })
                        .eq("id", studentUser.id);
                      const { data: updatedUser } = await supabaseClient
                        .from("users")
                        .select()
                        .eq("id", studentUser.id)
                        .single();

                      setStudentUser(updatedUser);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </VStack>
            )}
          </HStack>
          <Box mb={10}>
            <ReactQuill
              theme="snow"
              ref={quillRef}
              placeholder="Baba nam kevalam"
              value={editorState}
              onChange={(text, delta, source, editor) => {
                // console.log("🚀 ~ rest:", rest);
                applyDefaultStyle();
                setEditorState(text);

                // Persist font size when typing
                if (source === "user") {
                  const quill = quillRef.current.getEditor();
                  const currentFormat = quill.getFormat();

                  // If no size is set, apply the last selected size
                  if (!currentFormat.size) {
                    quill.format("size", currentSize);
                  }
                }
              }}
              modules={modules}
              formats={formats}
            />
          </Box>
        </>
      )}
    </Box>
  );

  const location = useLocation();

  useEffect(() => {
    console.log("🚀 ~ location:", location);
  }, [location]);

  // if (location.pathname.startsWith('/learn-to-read/')) {
  //   return content
  // }
  if (isNested) {
    return content;
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={chatModalStyle}>
          <Box mb={20}></Box>
          <Box maxWidth={600} mb={20} width="100%">
            {!isAdmin(authUser?.id) && (
              <>
                <Box display="flex" flexDirection="column" alignItems="revert">
                  {/* {dbChat.text.map((text: string) => {
                    return (
                      <Typography variant="body1" fontSize={25} width="100%" gutterBottom>
                        {text}
                      </Typography>
                    );
                  })} */}
                  <ReactQuill
                    theme="snow"
                    value={editorState}
                    // placeholder="Baba nam kevalam"
                    onChange={setEditorState}
                    readOnly={true} // Make the editor read-only
                    modules={{ toolbar: false }} // Disable the toolbar
                    // modules={modules}
                    // formats={formats}
                  />
                </Box>
              </>
            )}
            {isAdmin(authUser?.id) && (
              <>
                <HStack pb={5}>
                  {/* <AdminUsers
                    users={users}
                    onChange={(user) => {
                      // setStudentUserId(user?.id || null);
                    }}
                  /> */}
                  <Button variant="contained" color="error" onClick={endSession}>
                    end session
                  </Button>
                </HStack>
                <Box mb={10}>
                  <ReactQuill
                    theme="snow"
                    placeholder="Baba nam kevalam"
                    value={editorState}
                    onChange={(text, ...rest) => {
                      console.log("🚀 ~ rest:", rest);
                      applyDefaultStyle();
                      setEditorState(text);
                    }}
                    modules={modules}
                    formats={formats}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
      {isAdmin(authUser?.id) && (
        <Box
          position="fixed"
          sx={{
            right: 10,
            bottom: 10,
          }}
        >
          <IconButton onClick={handleOpen}>
            <ChatIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ControllingStudentPage;
