import { Button, Checkbox } from "@mui/material";
import WordsCarousel from "../../Grammar/GrammarLesson/tabs/WordsCarousel";
import HStack from "../../Unknown/HStack";
import ShowLastAndPrevCountButtons from "../../Unknown/ShowLastAndPrevCountButtons";

const WordsCarouselContainer: React.FC<{
  words: string[];
}> = ({ words }) => {
  return (
    <HStack>
      <ShowLastAndPrevCountButtons type="learn-words" />
      <WordsCarousel
        words={words}
        isReadToLearn
        buttonLabel="Вивчити/повторити слова"
        withoutWordsNumber
        withTracking
      />
    </HStack>
  );
};

export default WordsCarouselContainer;
