import { Typography } from "@mui/material";

interface ItalicTitleProps {
  text: any;
  color?: string;
  bg?: string;
  size?: number;
  fontFamily?: string;
  isItalic?: boolean;
  fontWeight?: number;
  lineHeight?: number;
}

const ItalicTitle = ({
  text,
  color,
  bg,
  fontFamily,
  fontWeight,
  isItalic = true,
  lineHeight,
  size,
}: ItalicTitleProps) => {
  return (
    <Typography
      variant="caption"
      fontStyle={isItalic ? "italic" : "normal"}
      fontFamily={fontFamily || "Rubik"}
      fontSize={size || 18}
      fontWeight={fontWeight || 700}
      // color="primary.dark"
      color={color || "secondary.dark"}
      sx={{
        ...(lineHeight ? { lineHeight } : {}),
        // maxWidth: "100%",
        // minWidth: 0,
        // textOverflow: "ellipsis",
        // wordWrap: "break-word",
        // overflowWrap: "break-word",
        // whiteSpace: "normal",
        // backgroundColor: bg || "white",
      }}
    >
      {text}
    </Typography>
  );
};

export default ItalicTitle;
