import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import React from "react";
import Box from "../Unknown/Box";

import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lightgallery.css";
import VStack from "../Unknown/VStack";
import { Typography } from "@mui/material";

const ImageCarouselModal: React.FC<{
  images?: string[];
  sentenceImages?: { en: string; ua: string }[];
}> = ({ images, sentenceImages }) => {
  if (images) {
    return (
      <div style={{ width: "100%" }}>
        <VStack>
          <LightGallery plugins={[lgThumbnail, lgZoom]}>
            {images.map((image, index) => (
              <Box
                component="a"
                href={image.includes(".jpg") ? image : `/image-sentences/${image}.jpg`}
                key={index}
                data-sub-html={image.includes(".jpg") ? "" : image}
                sx={{
                  width: 100,
                  position: "relative",
                }}
              >
                <Box
                  component="img"
                  alt=""
                  // src={image}
                  src={image.includes(".jpg") ? image : `/image-sentences/${image}.jpg`}
                  sx={{
                    display: "inline-block",
                    width: !index ? 200 : 0,
                    height: !index ? "auto" : 0,
                  }}
                />
              </Box>
            ))}
          </LightGallery>
        </VStack>
      </div>
    );
  }

  if (sentenceImages) {
    const itemCount = sentenceImages.length;
    return (
      <div style={{ width: "100%" }}>
        <VStack>
          <LightGallery plugins={[lgThumbnail, lgZoom]}>
            {[...sentenceImages, ...sentenceImages].map((image, index) => (
              <Box
                component="a"
                key={index}
                href={`/image-sentences/${image.en}.jpg`}
                data-sub-html={index >= itemCount ? image.ua : image.en}
                sx={{
                  width: 100,
                  position: "relative",
                }}
              >
                <Box
                  component="img"
                  alt=""
                  // src={image}
                  src={`/image-sentences/${image.en}.jpg`}
                  sx={{
                    display: "inline-block",
                    width: !index ? 200 : 0,
                    height: !index ? "auto" : 0,
                  }}
                />
              </Box>
            ))}
          </LightGallery>
        </VStack>
      </div>
    );
  }

  return null;
};

export default ImageCarouselModal;
