import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LearnWordSection from "../../../Unknown/LearnWordsSection";
import AdminUsers from "../../../AdminUsers";
import isAdmin from "../../../../common/isAdmin";
import { useLessonsStore } from "../../../../store/lessonsStore";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import supabaseClient from "../../../../common/supabaseClient";

const WordsCarousel: React.FC<{
  words: string[];
  buttonLabel?: string;
  withoutWordsNumber?: boolean;
  withTracking?: boolean;
  isReadToLearn?: boolean;
  lessonType?: string;
}> = ({ words, withTracking, lessonType, withoutWordsNumber, isReadToLearn, buttonLabel }) => {
  const { lessonId } = useParams();
  const [currentWords, setCurrentWords] = useState<string[]>(words);

  useEffect(() => {
    setCurrentWords(words);
  }, [words]);

  return (
    <>
      <LearnWordSection
        currentWords={currentWords}
        withoutExamples
        withoutWordsNumber={withoutWordsNumber}
        withTracking={withTracking}
        buttonLabel={buttonLabel}
        isReadToLearn={isReadToLearn}
        lessonType={lessonType}
        lessonId={`grammar_words_carousel_lesson_${lessonId}_${buttonLabel}`}
        onUpdateWords={(newWords) => {
          setCurrentWords(newWords);
        }}
      />
    </>
  );
};

export default memo(WordsCarousel);
