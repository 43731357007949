import MicIcon from "@mui/icons-material/Mic";
import { Box, Button, Checkbox, Drawer, IconButton, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import MicSentences from "../../Mic/MicSentences";
import SpeechRecognition, {
  ListeningOptions,
  useSpeechRecognition,
} from "react-speech-recognition";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import VStack from "../../Unknown/VStack";
import HStack from "../../Unknown/HStack";
import useGlobalWords from "../../hooks/getGlobalWords";
import useGlobalWordsMap from "../../hooks/useGlobalWordsMap";
import WordAudio from "../../Audio/WordAudio";
import { useWindowSize } from "react-use";
import CheckIcon from "@mui/icons-material/Check";
import LinearProgress from "../../User/LinearProgress";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const startListeningOptions: ListeningOptions = {
  language: "en-US",
};

const LearnToReadSpeaking: React.FC<{
  words: string[];
}> = ({ words }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const { height: windowHeight } = useWindowSize();
  const wordsMap = useGlobalWordsMap({ words });
  const handleDrawerClose = () => {
    setIsOpen(false);
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  const [speakingMap, setSpeakingMap] = useState<Record<string, boolean>>({});

  const currentWord = useMemo(() => {
    return words[currentIndex];
  }, [words, currentIndex]);

  useEffect(() => {
    resetTranscript();
    if (currentIndex === 0) {
      setSpeakingMap({});
    }
    if (isOpen) {
      SpeechRecognition.startListening(startListeningOptions);
    }
  }, [currentIndex, isOpen]);

  useEffect(() => {
    resetTranscript();
    setSpeakingMap({});
    setSpeakingMap({});
  }, [isOpen]);

  useEffect(() => {
    if (
      !speakingMap[currentWord] &&
      transcript.toLowerCase() === removePunctuationFromString(currentWord.toLowerCase())
    ) {
      setSpeakingMap((prev) => {
        return {
          ...prev,
          [currentWord]: true,
        };
      });
    }
  }, [speakingMap, transcript, currentWord]);

  const transcriptElement = (isVisible?: boolean) => {
    return (
      <VStack
        textAlign="center"
        sx={{
          opacity: isVisible ? 1 : 0,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Typography
          fontSize={30}
          color={
            transcript.toLowerCase() === currentWord || transcript === currentWord
              ? "primary"
              : "black"
          }
        >
          {transcript}
        </Typography>
      </VStack>
    );
  };

  return (
    <>
      <Button size="small" variant="outlined" onClick={() => setIsOpen(true)}>
        Говоріння
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9999999,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />
          <LinearProgress elementsCount={words.length - 1} currentValue={currentIndex} />
          <Box sx={{ backgroundColor: "white" }} py={10} px={3} width="100%">
            <VStack minHeight={70} alignItems="center" mt={5}>
              {transcriptElement(true)}
            </VStack>
            <VStack
              pt={5}
              pb={10}
              // justifyContent="flex-start"
              // alignItems="flex-start"
            >
              <>
                <VStack mb={5}>
                  <Box sx={{ position: "relative" }}>
                    <Typography variant="h3">{currentWord}</Typography>
                  </Box>
                  <HStack position="relative">
                    <CheckIcon
                      sx={{
                        fontSize: 30,
                        opacity: speakingMap[currentWord] ? 1 : 0,
                        position: "absolute",
                        left: -30,
                        top: 3,
                      }}
                      color="primary"
                    />
                    <IconButton
                      onClick={() => {
                        resetTranscript();
                        if (listening) {
                          SpeechRecognition.stopListening();
                        } else {
                          SpeechRecognition.startListening(startListeningOptions);
                        }
                      }}
                    >
                      <MicIcon
                        sx={{
                          fontSize: 30,
                          color: listening ? "#4aa805" : "black",
                        }}
                      />
                    </IconButton>
                    {wordsMap[currentWord]?.audio_url && (
                      <WordAudio small url={wordsMap[currentWord].audio_url} />
                    )}
                    {/* <Button onClick={() => SpeechRecognition.startListening(startListeningOptions)}>Start</Button> */}
                    {/* <Button onClick={SpeechRecognition.stopListening}>Stop</Button> */}
                    {/* <Button onClick={resetTranscript}>Reset</Button> */}
                  </HStack>
                  <Box py={3}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (currentIndex + 1 >= words.length) {
                          setCurrentIndex(0);
                        } else {
                          setCurrentIndex((prev) => prev + 1);
                        }
                      }}
                    >
                      далі
                    </Button>
                  </Box>
                </VStack>
              </>
            </VStack>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default LearnToReadSpeaking;
