import { useEffect } from "react";
import isAdmin from "../../../../../common/isAdmin";
import supabaseClient from "../../../../../common/supabaseClient";
import { useLessonsStore } from "../../../../../store/lessonsStore";

const useAdminControlRealtime = () => {
  const authUser = useLessonsStore((state) => state.authUser);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  const setIsUserBoardOpen = useLessonsStore((state) => state.setIsUserBoardOpen);
  const setUserBoardText = useLessonsStore((state) => state.setUserBoardText);
  const setAdminControlReadingSentenceIndex = useLessonsStore(
    (state) => state.setAdminControlReadingSentenceIndex,
  );

  useEffect(() => {
    const channel = supabaseClient
      .channel("room9")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "admin_control" },
        async (payload) => {
          if (
            (!isAdmin(authUser?.id) &&
              // only for current user but not for admin
              authUser?.id === payload.new.user &&
              // we have only one element in the db table
              payload.new.id === 0 &&
              // has already reloaded
              isTeacherLesson !== payload.new.is_teacher_lesson) ||
            // reload the page if the user is changed
            // to change isTeacherLesson prop in the state
            (!isAdmin(authUser?.id) && authUser?.id !== payload.new.user && isTeacherLesson)
          ) {
            window.location.reload();
          }
          if (!isAdmin(authUser?.id) && authUser?.id === payload.new.user && payload.new.id === 0) {
            setIsUserBoardOpen(payload.new.board_is_open);
            if (payload.new.new_reading_sentence_index) {
              setAdminControlReadingSentenceIndex(payload.new.new_reading_sentence_index);
            }
          }
          if (
            !isAdmin(authUser?.id) &&
            authUser?.id === payload.new.user &&
            payload.new.id === 0 &&
            payload.new.board_text
          ) {
            setUserBoardText(payload.new.board_text);
          }
        },
      )
      .subscribe();
    return () => {
      channel.unsubscribe();
    };
  }, [authUser, isTeacherLesson]);
};

export default useAdminControlRealtime;
