import { Button, Checkbox, Typography } from "@mui/material";
import { typesOfHomeWork } from "../../../types";
import HStack from "../HStack";
import VStack from "../VStack";
import useLastPrev from "./useLastPrev";

const ShowLastAndPrevCountButtons: React.FC<{
  type: typesOfHomeWork;
}> = ({ type }) => {
  const { last, prev } = useLastPrev({ type });

  return (
    <HStack gap={0}>
      {!last && (
        <HStack>
          <Checkbox sx={{ pointerEvents: "none", p: 0 }} checked={!last} />
          <Typography variant="caption">виконано</Typography>
        </HStack>
      )}
      {Boolean(last) && (
        <VStack alignItems="flex-start" gap={0}>
          <HStack>
            <Button
              size="small"
              sx={{
                pointerEvents: "none",
                minWidth: 0,
                py: 0,
                px: 0,
                fontSize: 15,
                lineHeight: 1.2,
              }}
              color="error"
              // variant="outlined"
            >
              {last}
            </Button>
            <Typography variant="caption">залишилось</Typography>
          </HStack>
          <HStack>
            <Button
              size="small"
              sx={{
                pointerEvents: "none",
                minWidth: 0,
                py: 0,
                px: 0,
                fontSize: 15,
                lineHeight: 1.2,
              }}
              color="primary"
              // variant="outlined"
            >
              {prev}
            </Button>
            <Typography variant="caption">повторено</Typography>
          </HStack>
        </VStack>
      )}
    </HStack>
  );
};

export default ShowLastAndPrevCountButtons;
