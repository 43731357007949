import { Box, Button, Modal } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { fullScreenModalStyle } from "../../common/constants";
import ModalCloseButton from "../Unknown/ModalCloseButton";
import { Background, ReactFlow, addEdge, useEdgesState, useNodesState } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import "./index.css";
import { useLessonsStore } from "../../store/lessonsStore";
import isAdmin from "../../common/isAdmin";

const ModalScheme: React.FC<{ initialNodes: any[]; initialEdges: any[] }> = ({
  initialEdges,
  initialNodes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const authUser = useLessonsStore((state) => state.authUser);

  // useEffect(() => {

  // }, [nodes]);

  // console.log(
  //   "🚀 ~ nodes:",
  //   nodes.map((node) => {
  //     return {
  //       id: node.id,
  //       position: node.position,
  //     };
  //   }),
  // );
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [menu, setMenu] = useState<any>(null);
  const ref = useRef<any>(null);

  const onConnect = useCallback(
    (params: any) => setEdges((els) => addEdge(params, els)),
    [setEdges],
  );

  const onNodeContextMenu = useCallback(
    (event: any, node: any) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = (ref.current as any)?.getBoundingClientRect();
      setMenu({
        id: node.id,
        top: event.clientY < pane.height - 200 && event.clientY,
        left: event.clientX < pane.width - 200 && event.clientX,
        right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setMenu],
  );

  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Схема
      </Button>
      <Modal open={isOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            zIndex: 99999,
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            bgcolor: "background.paper",
          }}
        >
          {isAdmin(authUser?.id) && (
            <>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    JSON.stringify(
                      nodes.map((node) => {
                        return {
                          id: node.id,
                          position: node.position,
                        };
                      }),
                    ),
                  );
                }}
              >
                Copy nodes
              </Button>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    JSON.stringify(
                      edges.map((edge) => {
                        return edge;
                      }),
                    ),
                  );
                }}
              >
                Copy edges
              </Button>
            </>
          )}
          <ModalCloseButton onClose={handleClose} />
          <ReactFlow
            ref={ref}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onPaneClick={onPaneClick}
            onNodeContextMenu={onNodeContextMenu}
            fitView
            style={{ backgroundColor: "#F7F9FB" }}
          >
            <Background />
            {/* {menu && <ContextMenu onClick={onPaneClick} {...menu} />} */}
          </ReactFlow>
        </Box>
      </Modal>
    </>
  );
};

export default ModalScheme;
