import { Box, Button, Drawer, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import VStack from "../../Unknown/VStack";
import { useParams } from "react-router-dom";
import HStack from "../../Unknown/HStack";
import LinearProgress from "../../User/LinearProgress";
import CongratulationsModal from "../UkTrainers/CongratulationsModal";

const LetterWordsCompare: React.FC<{ words: string[] }> = ({ words }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button size="small" variant="outlined" onClick={() => setIsOpen(true)}>
        Порівняти слова
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <DrawerContent isOpen={isOpen} words={words} onClose={handleDrawerClose} />
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const getConfig = (lessonId: string) => {
  const config: Record<
    string,
    { letters: string[]; underlineLetters?: string[]; words: string[] }[]
  > = {
    "1": [
      {
        letters: ["b", "d"],
        words: ["bell", "den", "bin", "dot", "doll", "belt", "end", "bed"],
      },
      {
        letters: ["l"],
        words: ["bell", "doll", "belt", "little"],
      },
    ],
    "4": [
      // {
      //   letters: ["b", "d"],
      //   words: ["bell", "den", "bin", "dot", "doll", "belt", "end", "bed"],
      // },
      {
        letters: ["i", "I"],
        words: ["is", "I", "it", "hi"],
      },
      {
        letters: ["e"],
        underlineLetters: ["i"],
        words: [
          "sit",
          "site",
          "lin",
          "line",
          "pin",
          "pine",
          "lik",
          "like",
          "fiv",
          "five",
          "kit",
          "kite",
          "bik",
          "bike",
          "lim",
          "lime",
          "tim",
          "time",
        ],
      },

      {
        letters: ["nn", "tt", "pp", "t-t", "p-p", "n-n"],
        words: [
          "little",
          "lit-tle",
          "tennis",
          "ten-nis",
          "bottle",
          "bot-tle",
          "battle",
          "bat-tle",
          "apple",
          "ap-ple",
        ],
      },
      {
        letters: ["ple", "tle", "ble"],
        words: [
          "little",
          "lit-tle",
          "bottle",
          "bot-tle",
          "battle",
          "bat-tle",
          "apple",
          "ap-ple",
          "bible",
          "bi-ble",
        ],
      },
      {
        letters: ["tle", "ble", "ple", "-"],
        words: ["little", "lit-tle", "bible", "bi-ble"],
      },
      {
        letters: ["l"],
        words: ["bell", "doll", "bells", "dolls", "belt", "little"],
      },
    ],
  };

  return config[lessonId];
};

const DrawerContent: React.FC<{
  words: string[];
  isOpen: boolean;
  onClose: () => void;
}> = ({ words, isOpen, onClose }) => {
  const { lessonId } = useParams();

  const reset = () => {
    setIsSuccess(false);
    setCurrentIndex(0);
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  const config = useMemo(() => {
    return getConfig(lessonId || "");
  }, [lessonId]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const currentConfigItem = useMemo(() => {
    return config[currentIndex];
  }, [config, currentIndex]);

  if (!lessonId || !config || !currentConfigItem)
    return <Typography>Something went wrong</Typography>;

  return (
    <Box sx={drawerContentStyle}>
      <ModalCloseButton onClose={onClose} />
      <LinearProgress elementsCount={config.length - 1} currentValue={currentIndex} />
      <VStack
        justifyContent="center"
        position="relative"
        width="100vw"
        flexWrap="nowrap"
        sx={{
          overflowX: "hidden",
        }}
      >
        <HStack width={320} gap={0} textAlign="center" mt={10}>
          {currentConfigItem.words.map((word, index) => {
            return (
              <Box sx={{ width: "50%" }} key={index}>
                {word.split("").map((l, index) => {
                  let color = currentConfigItem.letters.includes(l) ? "error" : "black";

                  if (
                    (index && currentConfigItem.letters.includes(`${word[index - 1]}${l}`)) ||
                    (index - 1 &&
                      currentConfigItem.letters.includes(
                        `${word[index - 2]}${word[index - 1]}${l}`,
                      )) ||
                    currentConfigItem.letters.includes(`${l}${word[index + 1]}`) ||
                    currentConfigItem.letters.includes(
                      `${l}${word[index + 1]}${word[index + 2]}`,
                    ) ||
                    currentConfigItem.letters.includes(`${word[index - 1]}${l}${word[index + 1]}`)
                  ) {
                    color = "error";
                  }

                  return (
                    <Typography
                      component="span"
                      fontSize={30}
                      color={color}
                      sx={{
                        textDecoration: currentConfigItem.underlineLetters?.includes(l)
                          ? "underline"
                          : "none",
                      }}
                    >
                      {l}
                    </Typography>
                  );
                })}
              </Box>
            );
          })}
          {Boolean(config.length > 1) && (
            <VStack py={7}>
              <Button
                onClick={() => {
                  if (currentIndex + 1 < config.length) {
                    setCurrentIndex(currentIndex + 1);
                  } else {
                    setIsSuccess(true);
                  }
                }}
                variant="contained"
              >
                Далі
              </Button>
            </VStack>
          )}
        </HStack>
        <CongratulationsModal
          open={isSuccess}
          message="На цьому все"
          onClose={() => {
            reset();
            onClose();
          }}
        />
      </VStack>
    </Box>
  );
};

export default LetterWordsCompare;
