import { useState } from "react";
import { useLessonsStore } from "../../../store/lessonsStore";
import { EnglishStory } from "../../../types/supabase";
import Stories from "../../Grammar/Stories";
import SimpleLessonTasks, { LessonRule } from "../../LearnToRead/SimpleLessonTasks";
import HStack from "../../Unknown/HStack";
import ShowLastAndPrevCountButtons from "../../Unknown/ShowLastAndPrevCountButtons";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import supabaseClient from "../../../common/supabaseClient";

const VideosContainer: React.FC = () => {
  const videosToRepeat = useLessonsStore((state) => state.homeWork.videosToRepeat);
  const [stories, setStories] = useState<EnglishStory[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (!videosToRepeat.length) return;

    const { data } = await supabaseClient.from("english_verses").select().in("id", videosToRepeat);

    if (data) setStories(data);
  }, [videosToRepeat]);

  if (!videosToRepeat.length) return null;

  return (
    <HStack>
      <ShowLastAndPrevCountButtons type="videos" />
      {/* <SimpleLessonTasks rules={rules} /> */}
      <Stories stories={stories} buttonLabel="Переглянути" buttonSize="small" />
    </HStack>
  );
};

export default VideosContainer;
