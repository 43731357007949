import { FirebaseError } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import GoogleButton from "react-google-button";
import Box from "../../Unknown/Box";
import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import VStack from "../../Unknown/VStack";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";

const provider = new GoogleAuthProvider();

const LoginForm: React.FC = () => {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setSession = useLessonsStore((state) => state.setSession);

  return null;

  return (
    <VStack mt={10} minWidth={300}>
      <Typography>Увійти за допомогою email</Typography>
      <TextField
        fullWidth
        color="primary"
        value={email}
        placeholder="Email"
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <TextField
        fullWidth
        color="primary"
        value={password}
        placeholder="Пароль"
        onChange={(event) => {
          setPassword(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <Button
        variant="outlined"
        fullWidth
        onClick={async () => {
          if (!password || !email) return;

          const { data, error } = await supabaseClient.auth.signInWithPassword({
            email,
            password,
          });
          if (error) alert(error.message);
          if (data) setSession(data.session);
          window.location.reload();
        }}
      >
        Увійти
      </Button>
    </VStack>
  );
};

export default LoginForm;
