import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { useEffect, useMemo, useState } from "react";
import Box from "../../../Unknown/Box";
import { CircularProgress, SxProps, Theme } from "@mui/material";
import { useLessonsStore } from "../../../../store/lessonsStore";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import supabaseClient from "../../../../common/supabaseClient";
import { format } from "date-fns";
import { userHomeWorkCurrentDay } from "../../../../common/constants";
import parsed from "../../../../common/parsed";

const HomeWorkCalendar = () => {
  const [highlightedDays, setHighlightedDays] = useState(["2024-12-03", 2, 15]);
  const setHomeworkDbProgress = useLessonsStore((state) => state.setHomeworkDbProgress);
  const homeworkDbProgress = useLessonsStore((state) => state.homeworkDbProgress);
  const authUser = useLessonsStore((state) => state.authUser);

  useMountedWaitAsyncEffect(
    async () => {
      if (!homeworkDbProgress.length && authUser) {
        const { data } = await supabaseClient
          .from("homework_progress")
          .select("id, progress")
          .eq("user", authUser.uuid)
          .limit(100);
        if (data) {
          setHomeworkDbProgress(
            data.map((d) => {
              return {
                day: d.id.replace(`${authUser.id}-`, ""),
                progress: d.progress,
              };
            }),
          );
        }
      }
    },
    [homeworkDbProgress, authUser],
    0,
  );

  const highlightedDates = useMemo(() => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const localStorageHomeWork = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});

    const progresses = homeworkDbProgress.map((d) => d.day);

    if (localStorageHomeWork[currentDate]?.progress) {
      return [progresses, localStorageHomeWork[currentDate]?.homeWorkProgress];
    }
    return progresses;
  }, [homeworkDbProgress]);

  const getColorForProgress = (progress: number): string => {
    // Ensure progress is between 0 and 100
    const normalizedProgress = Math.min(100, Math.max(0, progress));

    if (normalizedProgress <= 50) {
      // From red to yellow (0-50%)
      const g = Math.floor((normalizedProgress / 50) * 255);
      return `rgb(180, 0, 0)`;
    } else if (normalizedProgress <= 75) {
      return `rgb(237 167 12)`;
    } else {
      // From yellow to green (51-100%)
      const r = Math.floor(((100 - normalizedProgress) / 50) * 255);
      return `rgb(0, 180, 0)`;
    }
  };

  const ServerDay = (props: PickersDayProps<Dayjs>) => {
    const { day, outsideCurrentMonth, ...other } = props;

    const formattedDay = day.format("YYYY-MM-DD");
    const isHighlighted = highlightedDates.includes(formattedDay);

    let dayProgress = isHighlighted && homeworkDbProgress.find((d) => d.day === formattedDay);

    const backgroundColor = dayProgress ? getColorForProgress(dayProgress.progress) : undefined;

    const currentDate = format(new Date(), "yyyy-MM-dd");
    const localStorageHomeWork = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});
    const lsHMP = localStorageHomeWork[currentDate]?.homeWorkProgress;
    if (!dayProgress && formattedDay === currentDate) {
      if (lsHMP) {
        dayProgress = {
          day: currentDate,
          progress: lsHMP,
        };
      }
    }

    if (!dayProgress && new Date(formattedDay).getTime() < new Date(currentDate).getTime()) {
      dayProgress = {
        day: formattedDay,
        progress: 0,
      };
    }

    if (!dayProgress) {
      return (
        <PickersDay
          {...other}
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
          sx={{
            width: "32px !important",
            height: "32px !important",
            margin: "2px",
            "&.Mui-selected": {
              backgroundColor: "gray !important",
            },
          }}
        />
      );
    }

    const progressColor = getColorForProgress(dayProgress.progress);
    const backgroundProgressColor = dayProgress.progress === 0 && "rgb(255 218 218)";
    const progressAngle = (dayProgress.progress / 100) * 360;

    return (
      <div
        style={{
          position: "relative",
          width: "32px",
          height: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "2px",
        }}
      >
        <CircularProgress
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
              width: "32px",
              height: "32px",
              color: progressColor,
            },
          }}
          variant="determinate"
          value={dayProgress.progress}
        />
        <PickersDay
          {...other}
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
          sx={{
            width: "28px !important",
            height: "28px !important",
            // display: "flex",
            margin: "2px",
            backgroundColor: backgroundProgressColor ? backgroundProgressColor : "transparent",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.1)",
            },
            "&.Mui-selected": {
              backgroundColor: "gray !important",
            },
          }}
        />
      </div>
    );
    return (
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        sx={
          backgroundColor
            ? {
                backgroundColor,
                "&:hover": {
                  backgroundColor, // Keep the same color on hover
                  filter: "brightness(0.9)", // Slightly darken on hover
                },
              }
            : undefined
        }
      />
    );
  };

  const initialValue = useMemo(() => {
    const currentDateString = new Date().toString();

    return dayjs(currentDateString); // "2022-04-17"
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        defaultValue={initialValue}
        readOnly
        // loading={isLoading}
        // onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
      />
    </LocalizationProvider>
  );
};

export default HomeWorkCalendar;
