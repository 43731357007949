import { Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getRandomGreetings from "../../../../common/getRandomGreetings";
import shuffle from "../../../../common/shuffle";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import HStack from "../../../Unknown/HStack";
import VStack from "../../../Unknown/VStack";
import CheckAnim from "../CheckAnim";
// import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import StartMessage from "../StartMessage";
import { usePrevious } from "react-use";
import ErrorMessage from "../ErrorMessage";
import { TrainersConfig } from "../constants";
import CongratulationsModal from "../CongratulationsModal";
import WordAudio from "../../../Audio/WordAudio";
import formatLetterWithSound from "../../../../common/formatLetterWithSound";

const VOWELS = ["а ", "е", "и", "і", "о", "у", "ї", "є", "ю", "я"];

interface Position {
  x: number;
  y: number;
}

interface Item {
  id: number;
  content: string;
  position: Position;
  isVowel: boolean;
  initialPosition: Position;
  sorted: boolean;
}

interface DraggableItemProps {
  item: Item;
  onPositionChange: (id: number, newPosition: Position) => void;
  onDragEnd: (id: number, finalPosition: Position) => void;
}

const ZoneTitle: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Typography
      sx={{
        color: "text.secondary",
        width: "100%",
        textAlign: "center",
        position: "absolute",
        fontSize: 30,
        opacity: 0.6,
        fontFamily: "Rubik",
      }}
    >
      {text}
    </Typography>
  );
};

const greenColor = "#4CAF50";
const blueColor = "#2196F3";
const basicItemStyle: React.CSSProperties = {
  padding: "5px 15px",
  color: "white",
  borderRadius: "8px",
  userSelect: "none",
  WebkitUserSelect: "none",
  touchAction: "none",
  fontSize: "24px",
  fontWeight: "bold",
  margin: "5px",
  display: "inline-block",
  zIndex: 1,
  position: "relative",
};
const basicZoneStyle: React.CSSProperties = {
  width: "100%",
  minHeight: "150px",
  position: "relative",
  // borderTop: "2px dashed #666",
  border: "2px dashed #666",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  padding: "10px",
  maxWidth: 500,
  margin: "0 auto",
};

const DraggableItem: React.FC<DraggableItemProps> = ({ item, onPositionChange, onDragEnd }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragElement, setDragElement] = useState<HTMLDivElement | null>(null);
  const [translate, setTranslate] = useState<Position>({ x: 0, y: 0 });

  const createDragElement = (original: HTMLDivElement, initialX: number, initialY: number) => {
    const clone = original.cloneNode(true) as HTMLDivElement;
    const rect = original.getBoundingClientRect();

    clone.style.position = "fixed";
    clone.style.zIndex = "1000";
    clone.style.pointerEvents = "none";
    clone.style.width = `${rect.width}px`;
    clone.style.height = `${rect.height}px`;
    clone.style.left = `${initialX - rect.width / 2}px`;
    clone.style.top = `${initialY - rect.height / 2}px`;
    clone.style.margin = "0";
    clone.style.transform = "none";
    document.body.appendChild(clone);
    return clone;
  };

  const handleStart = (e: React.TouchEvent | React.MouseEvent): void => {
    if (!elementRef.current) return;
    const event = "touches" in e ? e.touches[0] : (e as React.MouseEvent);

    // Створюємо клон елемента точно під курсором/пальцем
    const clone = createDragElement(elementRef.current, event.clientX, event.clientY);
    setDragElement(clone);

    // Затемнюємо оригінальний елемент
    if (elementRef.current) {
      elementRef.current.style.opacity = "0.4";
    }

    setIsDragging(true);
    e.preventDefault();
  };

  const handleMove = useCallback(
    (e: TouchEvent | MouseEvent): void => {
      if (!isDragging || !dragElement) return;
      const event = "touches" in e ? e.touches[0] : (e as MouseEvent);

      // Оновлюємо позицію клону напряму через style для кращої продуктивності
      const rect = dragElement.getBoundingClientRect();
      const newX = event.clientX - rect.width / 2;
      const newY = event.clientY - rect.height / 2;

      dragElement.style.left = `${newX}px`;
      dragElement.style.top = `${newY}px`;

      // Оновлюємо translate для відстеження позиції
      setTranslate({ x: newX, y: newY });
      onPositionChange(item.id, { x: event.clientX, y: event.clientY });

      e.preventDefault();
    },
    [isDragging, dragElement, item.id, onPositionChange],
  );

  const handleEnd = (e: TouchEvent | MouseEvent): void => {
    if (!isDragging || !dragElement) return;

    const finalPosition = {
      x: translate.x,
      y: translate.y,
    };

    // Прибираємо клон
    dragElement.remove();
    setDragElement(null);

    // Відновлюємо прозорість оригінального елемента
    if (elementRef.current) {
      elementRef.current.style.opacity = "1";
    }

    setIsDragging(false);
    onDragEnd(item.id, finalPosition);
  };

  useEffect(() => {
    const handleTouchMove = (e: TouchEvent): void => {
      handleMove(e);
    };

    const handleMouseMove = (e: MouseEvent): void => {
      handleMove(e);
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove, { passive: false });
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("mouseup", handleEnd);
      window.addEventListener("touchend", handleEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("mouseup", handleEnd);
      window.removeEventListener("touchend", handleEnd);
    };
  }, [isDragging, handleMove]);

  const style: React.CSSProperties = useMemo(() => {
    const s: React.CSSProperties = {
      ...basicItemStyle,
      position: item.sorted ? "absolute" : "relative",
      cursor: isDragging ? "grabbing" : "grab",
      backgroundColor: "gray",
      boxShadow: isDragging ? "0px 8px 16px rgba(0,0,0,0.2)" : "0px 2px 4px rgba(0,0,0,0.1)",
    };

    if (item.sorted && !isDragging) {
      // s.boxShadow = "0px 8px 16px rgba(0,0,0,0.2)";
      s.position = "relative";
      s.pointerEvents = "none";
      s.opacity = 0.3;
    }

    return s;
  }, [item.sorted, isDragging]);

  return (
    <div ref={elementRef} style={style} onMouseDown={handleStart} onTouchStart={handleStart}>
      {formatLetterWithSound(item.content)}
    </div>
  );
};

const FindVowelLetter: React.FC<{
  type: string;
  vowels?: string[];
  consonants?: string[];
  onTaskClose: () => void;
}> = ({ type, vowels, consonants, onTaskClose }) => {
  const vowelsZoneRef = useRef<HTMLDivElement>(null);
  const consonantsZoneRef = useRef<HTMLDivElement>(null);

  const CONSONANTS: string[] = useMemo(() => {
    return (
      consonants || [
        "б",
        "к",
        "м",
        "н",
        "р",
        "с",
        "т",
        "х",
        "г",
        "ґ",
        "з",
        "д",
        "ж",
        "й",
        "л",
        "п",
        "ф",
        "ц",
        "ч",
        "в",
        "ш",
        "щ",
      ]
    );
  }, [consonants]);

  const VOWELS: string[] = useMemo(() => {
    return vowels || ["а", "е", "є", "и", "і", "ї", "о", "у", "ю", "я"];
  }, [vowels]);

  const [items, setItems] = useState<Item[]>(() => {
    return shuffle([...CONSONANTS, ...VOWELS]).map((letter, index) => ({
      id: index + 1,
      content: letter,
      position: { x: 0, y: 0 },
      initialPosition: { x: 0, y: 0 },
      isVowel: VOWELS.includes(letter),
      sorted: false,
    }));
  });

  const [score, setScore] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>("");
  const prevFeedback = usePrevious(feedback);
  const [startMessageIsVisible, setStartMessageIsVisible] = useState(true);

  const isInZone = (position: Position, zoneRef: React.RefObject<HTMLDivElement>): boolean => {
    if (!zoneRef.current) return false;

    const zoneRect = zoneRef.current.getBoundingClientRect();
    return (
      position.y >= zoneRect.top &&
      position.y <= zoneRect.bottom &&
      position.x >= zoneRect.left &&
      position.x <= zoneRect.right
    );
  };

  const handlePositionChange = (id: number, newPosition: Position): void => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, position: newPosition } : item)),
    );
  };

  const handleDragEnd = (id: number, finalPosition: Position): void => {
    setItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.id !== id) return item;

        const isVowelZone = isInZone(finalPosition, vowelsZoneRef);
        const isConsonantZone = isInZone(finalPosition, consonantsZoneRef);

        // Check if placement is correct
        const isCorrectPlacement =
          (item.isVowel && isVowelZone) || (!item.isVowel && isConsonantZone);

        if (isCorrectPlacement) {
          // setFeedback("Правильно!");
          setScore((prev) => prev + 1);
          return {
            ...item,
            position: finalPosition,
            sorted: true,
          };
        } else if ((item.isVowel && isConsonantZone) || (!item.isVowel && isVowelZone)) {
          setFeedback(item.content);
          // setItems((prev) => [...prev, item]);

          return {
            ...item,
            position: item.initialPosition,
            sorted: false,
          };
        } else {
          return {
            ...item,
            position: item.initialPosition,
            sorted: false,
          };
        }
      });
    });
  };

  const [errorLetter, setErrorLetter] = useState("");
  const [congratsIsVisible, setCongratsIsVisible] = useState(false);

  useEffect(() => {
    if (!items.filter((i) => !i.sorted).length) setCongratsIsVisible(true);
  }, [items]);

  useEffect(() => {
    if (prevFeedback !== feedback) {
      setErrorLetter(feedback);
    }
  }, [prevFeedback, feedback]);

  return (
    <>
      {startMessageIsVisible && (
        <StartMessage
          text={
            <>
              <Typography>Відсортуй літери:</Typography>
              <Typography color={greenColor}>вгорі ті які дають голосний звук</Typography>
              <Typography color={blueColor}>внизу ті які дають приголосний звук</Typography>
            </>
          }
          onStart={() => {
            setStartMessageIsVisible(false);
          }}
        />
      )}
      {!!errorLetter && (
        <ErrorMessage
          type={TrainersConfig.UA_SORT_VOWELS_AND_CONSONANTS}
          text={
            VOWELS.includes(errorLetter)
              ? `${errorLetter} не приголосна`
              : `${errorLetter} не голосна`
          }
          onExcept={() => {
            setErrorLetter("");
            setFeedback("");
          }}
        />
      )}
      <CongratulationsModal
        open={congratsIsVisible}
        onClose={() => {
          setCongratsIsVisible(false);
          onTaskClose();
        }}
      />
      <Box py={5}>
        {/* <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}> */}
        <Box>
          <Box
            ref={vowelsZoneRef}
            sx={{
              ...basicZoneStyle,
              height: "150px",
              backgroundColor: "rgba(144, 238, 144, 0.2)",
            }}
          >
            <ZoneTitle text="Голосні літери/звуки" />
            {items
              .filter((item) => item.sorted && item.isVowel)
              .map((item) => (
                <Box
                  sx={{
                    ...basicItemStyle,
                    backgroundColor: greenColor,
                  }}
                  key={item.id}
                >
                  {formatLetterWithSound(item.content)}
                  <WordAudio
                    autoPlay
                    boxHide
                    url={`/audio/${type === "ua" ? "ua_alphabet" : "en_alphabet"}/${
                      item.content
                    }.mp3`}
                  />
                </Box>
              ))}
          </Box>

          <VStack gap={1} alignItems="center" justifyContent="center" p={4} py={10}>
            <Box maxWidth={500}>
              {items
                // .filter((item) => !item.sorted)
                .map((item) => (
                  <DraggableItem
                    key={item.id}
                    item={item}
                    onPositionChange={handlePositionChange}
                    onDragEnd={handleDragEnd}
                  />
                ))}
            </Box>
          </VStack>

          <Box
            ref={consonantsZoneRef}
            sx={{
              ...basicZoneStyle,
              backgroundColor: "rgba(33, 150, 243, 0.1)",
            }}
          >
            <ZoneTitle text="Приголосниі літери/звуки" />

            {items
              .filter((item) => item.sorted && !item.isVowel)
              .map((item) => (
                <Box
                  sx={{
                    ...basicItemStyle,
                    backgroundColor: blueColor,
                  }}
                  key={item.id}
                >
                  {formatLetterWithSound(item.content)}
                  <WordAudio
                    autoPlay
                    boxHide
                    url={`/audio/${type === "ua" ? "ua_alphabet" : "en_alphabet"}/${
                      item.content
                    }.mp3`}
                  />
                </Box>
              ))}
          </Box>
        </Box>
        {/* </DndProvider> */}
      </Box>
    </>
  );
};
{
  /* {!isSuccess && (
  <Box py={5}>
    <CheckAnim />
  </Box>
)} */
}

export default FindVowelLetter;
