import { PropsWithChildren } from "react";
import Box from "../Box";

const Quote: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      m={2}
      py={2}
      pl={4}
      sx={{
        borderLeft: "4px solid #4aa805",
        backgroundColor: "rgb(74 168 5 / 5%)",
        boxShadow: "1px 1px 7px #d2d2d2",
      }}
    >
      {children}
    </Box>
  );
};

export default Quote;
