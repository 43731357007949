import { Box, Button, Typography } from "@mui/material";
import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import ModalScheme from "../../../ModalScheme";
import { useWindowSize } from "react-use";
import removePunctuationFromString from "../../../../common/removePunctuationFromString";
import ItalicTitle from "../../../Title/ItalicTitle";
import Quote from "../../../Unknown/Quote";
import VStack from "../../../Unknown/VStack";
import HStack from "../../../Unknown/HStack";
import LinearProgress from "../../../User/LinearProgress";

const initialNodes = (windowWidth: number) => [
  { id: "1", position: { x: 291.5, y: -41.5 }, data: { label: <span>б</span> } },
  { id: "2", position: { x: 254, y: 78 }, data: { label: <span>в</span> } },
  { id: "3", position: { x: 287.5, y: 68.5 }, data: { label: <span>г</span> } },
  { id: "4", position: { x: 244, y: -81.5 }, data: { label: <span>ґ</span> } },
  { id: "5", position: { x: 189.5, y: -80 }, data: { label: <span>д</span> } },
  { id: "6", position: { x: 149, y: -46.5 }, data: { label: <span>ж</span> } },
  { id: "7", position: { x: 148.5, y: 79 }, data: { label: <span>з</span> } },
  { id: "8", position: { x: 239.5, y: 106 }, data: { label: <span>й</span> } },
  { id: "9", position: { x: 111.5, y: 52.5 }, data: { label: <span>к</span> } },
  { id: "10", position: { x: 174.5, y: -49 }, data: { label: <span>л</span> } },
  { id: "11", position: { x: 271.5, y: 87 }, data: { label: <span>м</span> } },
  { id: "12", position: { x: 180.5, y: 92 }, data: { label: <span>н</span> } },
  { id: "13", position: { x: 271, y: -69 }, data: { label: <span>п</span> } },
  { id: "14", position: { x: 299.5, y: -65.5 }, data: { label: <span>р</span> } },
  { id: "15", position: { x: 137, y: 56.5 }, data: { label: <span>с</span> } },
  { id: "16", position: { x: 217.5, y: -81.5 }, data: { label: <span>т</span> } },
  { id: "17", position: { x: 207, y: 84.5 }, data: { label: <span>ф</span> } },
  { id: "18", position: { x: 176.5, y: 1 }, data: { label: "a / o / i / и / у / е" } },
];

const initialEdges: any[] = [
  { source: "10", target: "18", id: "xy-edge__10-18" },
  { source: "5", target: "18", id: "xy-edge__5-18" },
  { source: "16", target: "18", id: "xy-edge__16-18" },
  { source: "4", target: "18", id: "xy-edge__4-18" },
  { source: "13", target: "18", id: "xy-edge__13-18" },
  { source: "1", target: "18", id: "xy-edge__1-18" },
  { source: "14", target: "18", id: "xy-edge__14-18" },
  { source: "18", target: "3", id: "xy-edge__18-3", selected: false },
  { source: "18", target: "11", id: "xy-edge__18-11", selected: false },
  { source: "18", target: "2", id: "xy-edge__18-2" },
  { source: "18", target: "8", id: "xy-edge__18-8" },
  { source: "18", target: "15", id: "xy-edge__18-15" },
  { source: "18", target: "7", id: "xy-edge__18-7" },
  { source: "18", target: "9", id: "xy-edge__18-9" },
  { source: "18", target: "17", id: "xy-edge__18-17" },
  { source: "18", target: "12", id: "xy-edge__18-12" },
  { source: "6", target: "18", id: "xy-edge__6-18" },
];

const findNounsConfig = [
  {
    text: "Кіт сидів на килимку біля школи в місті.",
    nouns: ["кіт", "килимку", "школи", "місті"],
  },
  {
    text: "Діти гуляли в парку біля озера, де росли дерева.",
    nouns: ["діти", "парку", "озера", "дерева"],
  },
  {
    text: "Літак летів через гори, а потім приземлився на аеродром.",
    nouns: ["літак", "гори", "аеродром"],
  },
  {
    text: "Вчора ми відвідали музей і купили книги в магазині.",
    nouns: ["музей", "книги", "магазині"],
  },
];

export const UaWordsSyllables: React.FC = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">Склад</Typography>
        <Typography gutterBottom>
          це голосна літера з приголосними які можуть бути перед голосною або після.
        </Typography>
      </Box>
      <Quote>
        <Typography gutterBottom>Склад завжди вимовляється одним подихом повітря.</Typography>
      </Quote>
      <Box py={3}>
        <ModalScheme initialEdges={initialEdges} initialNodes={initialNodes(windowWidth)} />
      </Box>
      <Box>
        <Typography gutterBottom>
          <strong>Слово = склад</strong> якщо це слово має одну голосну літеру
        </Typography>
        <ItalicTitle text="чай" />, &nbsp;
        <ItalicTitle text="сон" />, &nbsp;
        <ItalicTitle text="сік" />, &nbsp;
        <ItalicTitle text="страх" />
      </Box>

      <VStack py={10}>
        <Typography gutterBottom variant="h3">
          Розділи слова на склади
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <SimpleCarousel
            elements={[
              {
                rule: (
                  <>
                    приголосна літера між голосними завжди відносить до наступного складу.
                    <br />
                    Наприклад:&nbsp;
                    <strong>о</strong>
                    <strong style={{ color: "red" }}>к</strong>
                    <strong>о</strong>. Літера <strong>к</strong> відноситься до наступної голосної
                    літери і створює склад <strong>ко</strong>.
                    <br />
                    <strong>о-ко</strong>, не{" "}
                    <strong style={{ textDecoration: "line-through" }}>ок-о</strong>
                  </>
                ),
                words: [
                  "го-ди-на",
                  "мо-ло-ко",
                  "ру-ка",
                  "ди-ня",
                  "ка-вун",
                  "бо-ло-то",
                  "до-ро-га",
                  "ма-ма",
                  "ри-ба",
                  "ву-ли-ця",
                ],
              },
              {
                rule: (
                  <>
                    два приголосні, які знаходяться між голосними звуками, належать до різних
                    складів
                    <br />
                    Наприклад:&nbsp;
                    <strong>
                      т<strong style={{ textDecoration: "underline" }}>о</strong>
                    </strong>
                    <strong style={{ color: "red" }}>нк</strong>
                    <strong>
                      <strong style={{ textDecoration: "underline" }}>и</strong>й
                    </strong>
                    . Літера <strong style={{ color: "red" }}>н</strong> відноситься до попереднього
                    складу, а літера <strong style={{ color: "red" }}>к</strong> до наступного
                    <br />
                    <strong>
                      то<strong style={{ color: "red" }}>н</strong>-
                      <strong style={{ color: "red" }}>к</strong>ий
                    </strong>
                    , не <strong style={{ textDecoration: "line-through" }}>то-нкий</strong>, та не{" "}
                    <strong style={{ textDecoration: "line-through" }}>тонк-ий</strong>
                  </>
                ),
                words: ["важ-кий", "вез-ти", "зір-ка", "під-ло-га", "тор-ба", "вов-ки", "доб-ре"],
              },
            ]}
          />
        </Box>
      </VStack>
    </Box>
  );
};

const SimpleCarousel: React.FC<{ elements: any }> = ({ elements: elm }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const elements: { word: string; rule: string }[] = useMemo(() => {
    return elm
      .map((i: any) => {
        return i.words.map((w: string) => {
          return { word: w, rule: i.rule };
        });
      })
      .flat();
  }, [elm]);

  const currentElement = useMemo(() => {
    return elements[currentIndex];
  }, [elements, currentIndex]);

  const letters = useMemo(() => {
    return currentElement?.word?.split("");
  }, [currentElement]);

  const [currentWord, setCurrentWord] = useState("");

  useEffect(() => {
    setCurrentWord(currentElement?.word?.replaceAll("-", "_"));
  }, [currentElement, currentIndex]);

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    let timer: any = null;
    if (currentElement?.word === currentWord) {
      timer = setTimeout(() => {
        setIsSelected(true);
      }, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [currentWord, currentElement]);

  if (currentIndex === elements.length) {
    return (
      <Box>
        <Box py={5}>
          <Typography textAlign="center" variant="h3">
            На цьому все
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            setCurrentIndex(0);
          }}
        >
          Почати з початку
        </Button>
      </Box>
    );
  }

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <LinearProgress height={5} currentValue={currentIndex} elementsCount={elements.length - 1} />

      <Box mb={5} mt={4}>
        <Box mb={4}>
          <Typography gutterBottom maxWidth={400}>
            <strong>Правило:</strong> {currentElement.rule}
          </Typography>
        </Box>
        <VStack>
          <HStack gap={0}>
            {isSelected && <ItalicTitle size={25} text={currentElement.word} />}
            {!isSelected &&
              letters.map((l, i) => {
                return (
                  <Fragment key={i}>
                    {l !== "-" ? <Letter letters={letters.join("")} letter={l} /> : ""}
                    {letters[i + 1] !== "-" && i !== letters.length - 1 && (
                      <Dash
                        index={i}
                        letters={letters}
                        onSuccess={() => {
                          setCurrentWord(
                            currentWord
                              .split("")
                              .map((l, index) => {
                                if (index === i) return "-";
                                return l;
                              })
                              .join(""),
                          );
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
          </HStack>
        </VStack>
      </Box>
      <Button
        variant="outlined"
        disabled={!isSelected}
        onClick={() => {
          setCurrentIndex((prev) => prev + 1);
          setIsSelected(false);
        }}
      >
        Далі
      </Button>
    </Box>
  );
};

const Dash: React.FC<{ letters: string[]; index: number; onSuccess: () => void }> = ({
  letters,
  index,
  onSuccess,
}) => {
  const [color, setColor] = useState("hsl(0deg 0% 50.2% / 14%)");

  useEffect(() => {
    let timer: any;
    if (color === "red") {
      timer = setTimeout(() => {
        setColor("hsl(0deg 0% 50.2% / 14%)");
      }, 2000);

      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [color]);

  return (
    <Typography
      sx={{
        width: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: `rgb(${Math.floor(Math.random() * 255)},${Math.floor(
        //   Math.random() * 255,
        // )},${Math.floor(Math.random() * 255)})`,
        fontWeight: 700,
        fontSize: 30,
        color: color,
        cursor: "pointer",
      }}
      onClick={() => {
        if (letters[index] === "-") {
          setColor("green");
          onSuccess();
        } else {
          setColor("red");
        }
      }}
    >
      -
    </Typography>
  );
};
const Letter: React.FC<{ letter: string; letters: string }> = ({ letter, letters }) => {
  const [color, setColor] = useState("white");

  useEffect(() => {
    setColor("white");
  }, [letters]);

  useEffect(() => {
    let timer: any;
    if (color === "red") {
      timer = setTimeout(() => {
        setColor("white");
      }, 1000);

      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [color]);

  return (
    <Typography
      sx={{
        cursor: "pointer",
        px: 1,
        position: "relative",
        "&::before": {
          content: '" "',
          width: "calc(100% + 2px)",
          left: "-1px",
          bottom: "-2px",
          position: "absolute",
          height: 4,
          backgroundColor: color === "green" ? "black" : "transparent",
        },
      }}
      onClick={() => {
        if (["а", "о", "і", "у", "и", "е", "ю", "я", "є", "ї"].includes(letter)) {
          setColor("green");
        } else {
          setColor("red");
        }
      }}
    >
      <ItalicTitle text={letter} />
    </Typography>
  );
};
