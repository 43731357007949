import isAdmin from "../../../../common/isAdmin";
import supabaseClient from "../../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../../store/lessonsStore";

const useIsTeacherLesson = () => {
  const authUser = useLessonsStore((state) => state.authUser);
  const setIsTeacherLesson = useLessonsStore((state) => state.setIsTeacherLesson);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);

  useMountedWaitAsyncEffect(async () => {
    if (authUser && !isAdmin(authUser.id)) {
      const { data } = await supabaseClient
        .from("admin_control")
        .select("is_teacher_lesson, user(*)")
        .eq("id", 0)
        .eq("user", authUser.id)
        .maybeSingle();
      if (data?.is_teacher_lesson) {
        setIsTeacherLesson(true);
      }
    }
    if (authUser && isAdmin(authUser.id)) {
      const { data } = await supabaseClient
        .from("admin_control")
        .select("user, user(*)")
        .eq("id", 0)
        .single();
      console.log("data?.user", data?.user);
      setStudentUser(data?.user);
    }
  }, [authUser]);
};

export default useIsTeacherLesson;
