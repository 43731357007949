export interface ITask {
  id: string;
  content: string;
}

export interface IColumn {
  id: string;
  title: string;
  taskIds: string[];
}

const initialData: {
  tasks: Record<string, ITask>;
  columns: IColumn[];
  columnOrder: string[];
} = {
  tasks: {
    a: { id: "a", content: "a" },
    b: { id: "b", content: "b" },
    c: { id: "c", content: "c" },
    d: { id: "d", content: "d" },
    e: { id: "e", content: "e" },
    f: { id: "f", content: "f" },
    g: { id: "g", content: "g" },
    h: { id: "h", content: "h" },
    m: { id: "m", content: "m" },
    n: { id: "n", content: "n" },
    o: { id: "o", content: "o" },
    q: { id: "q", content: "q" },
    r: { id: "r", content: "r" },
    p: { id: "p", content: "p" },
    s: { id: "s", content: "s" },
    t: { id: "t", content: "t" },
    y: { id: "y", content: "y" },
    v: { id: "v", content: "v" },
    w: { id: "w", content: "w" },
    x: { id: "x", content: "x" },
    z: { id: "z", content: "z" },
  },
  columns: [
    {
      id: "column 1",
      title: "column 1",
      taskIds: ["a", "b"],
    },
    {
      id: "column 2",
      title: "column 2",
      taskIds: [
        // "a",
        // "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "m",
        "n",
        "o",
        "q",
        "r",
        "p",
        "s",
        "t",
        "y",
        "v",
        "w",
        "x",
        "z",
      ],
    },
  ],
  columnOrder: ["column 1", "column 2"],
};

export default initialData;
