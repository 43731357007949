import MicIcon from "@mui/icons-material/Mic";
import { Box, Button, Typography } from "@mui/material";
import { Howl } from "howler";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SpeechRecognition, {
  ListeningOptions,
  useSpeechRecognition,
} from "react-speech-recognition";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import { Sentence } from "../../../types/supabase";
import VStack from "../../Unknown/VStack";
import "./styles.css";

const startListeningOptions: ListeningOptions = {
  language: "en-US",
  continuous: true,
};

const MicSentences: React.FC<{ audioUrls: Sentence[] | string[] }> = ({ audioUrls }) => {
  const aU = useMemo(() => {
    if (typeof audioUrls[0] === "object") {
      return audioUrls.map((i: unknown) => (i as Sentence).en);
    }
    return audioUrls as string[];
  }, [audioUrls]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1.0);
  const sound = useRef<Howl | null>(null);
  const [speed, setSpeed] = useState(50);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  const trackListRef = useRef<HTMLUListElement | null>(null);

  // Helper function to get CSS classes for track items
  const getTrackItemClasses = (index: number) => {
    const classes = ["track-item"];
    if (index === currentTrackIndex) {
      classes.push("active");
    } else if (currentTrackIndex !== undefined && index < currentTrackIndex) {
      classes.push("above-active");
    } else {
      classes.push("below-active");
    }
    return classes.join(" ");
  };

  useEffect(() => {
    const trackListElement = trackListRef.current;
    if (!trackListElement) return;

    const activeTrackElement: any =
      currentTrackIndex !== undefined && trackListElement.children[currentTrackIndex];
    if (!activeTrackElement) return;

    // Calculate scroll position to center the active track
    const trackListHeight = trackListElement.clientHeight;
    const activeTrackHeight = activeTrackElement.clientHeight;
    const scrollTop = activeTrackElement.offsetTop - trackListHeight / 2 + activeTrackHeight / 2;

    // Smoothly scroll to the calculated position
    trackListElement.scrollTo({
      top: scrollTop,
      behavior: "smooth",
    });
  }, [currentTrackIndex]);

  const cleanTranscript = useMemo(() => {
    return removePunctuationFromString(transcript.toLowerCase());
  }, [transcript]);

  // useEffect(() => {
  // }, [cleanTranscript]);
  const goToNextTrack = () => {};

  useEffect(() => {
    let timer: any = null;

    if (!listening && isPlaying) {
      timer = setTimeout(() => {
        if (
          cleanTranscript.replaceAll(" ", "") ===
          removePunctuationFromString(aU[currentTrackIndex].toLowerCase()).replaceAll(" ", "")
        ) {
          const nextIndex = (currentTrackIndex + 1) % aU.length;
          setCurrentTrackIndex(nextIndex);
        }

        resetTranscript();
        SpeechRecognition.startListening(startListeningOptions);
      }, 500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [listening, isPlaying, cleanTranscript]);

  return (
    <VStack>
      {!isPlaying && (
        <Button
          variant="contained"
          sx={{ zIndex: 1, position: "relative" }}
          onClick={() => {
            setIsPlaying(true);
          }}
        >
          Start
        </Button>
      )}
      {isPlaying && (
        <Button
          variant="contained"
          sx={{ zIndex: 1, position: "relative" }}
          onClick={() => {
            setIsPlaying(false);
            SpeechRecognition.stopListening();
          }}
        >
          Finish
        </Button>
      )}

      {isPlaying && (
        <MicIcon
          sx={{
            fontSize: 50,
            color: listening ? "#4aa805" : "black",
          }}
        />
      )}
      {isPlaying && (
        <Box width="100%" py={3} position="absolute">
          <Button
            variant="outlined"
            onClick={() => {
              const nextIndex = (currentTrackIndex + 1) % aU.length;
              setCurrentTrackIndex(nextIndex);
            }}
          >
            Пропустити
          </Button>
        </Box>
      )}
      <Box
        className="track-list"
        ref={trackListRef}
        style={{ height: "350px", overflow: "auto", width: "100%", display: "block" }}
      >
        {/* <Typography variant="caption">{transcript}</Typography> */}
        {aU.map((url, index) => {
          console.log("🚀 ~ url:", url);
          if (index === currentTrackIndex) {
            const cleanTranscriptWords = cleanTranscript?.split(" ");

            return (
              <Box
                key={index}
                className={getTrackItemClasses(index)}
                onClick={() => setCurrentTrackIndex(index)}
              >
                <Typography variant="body1" sx={{ display: "block" }}>
                  {transcript}
                </Typography>
                {url?.split(" ").reduce<any[]>((prev, word, wordIndex) => {
                  return [
                    ...prev,
                    <Box
                      sx={{
                        textDecoration: listening ? "underline" : "none",
                      }}
                      fontSize={20}
                      component="span"
                      color={
                        (removePunctuationFromString(word.toLowerCase()) ===
                          cleanTranscriptWords[wordIndex] &&
                          (wordIndex === 0 || prev[wordIndex - 1]?.props.color === "#4aa805")) ||
                        (cleanTranscriptWords[wordIndex] &&
                          cleanTranscriptWords[wordIndex].startsWith(word)) ||
                        (cleanTranscriptWords[wordIndex] &&
                          cleanTranscriptWords[wordIndex].endsWith(word))
                          ? "#4aa805"
                          : "black"
                      }
                    >
                      {`${word} `}
                    </Box>,
                  ];
                }, [])}
              </Box>
            );
          }

          return (
            <Box
              key={index}
              fontSize={20}
              className={getTrackItemClasses(index)}
              onClick={() => setCurrentTrackIndex(index)}
            >
              {url}
            </Box>
          );
        })}
      </Box>
    </VStack>
  );
};

export default MicSentences;
