import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import supabaseClient from "../../common/supabaseClient";
import useCreateAudio from "../../common/useCreateAudio";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import MenuLayout from "../Layout/MenuLayout";
import HStack from "../Unknown/HStack";
import quotesData from "./quotes";

const Quote: React.FC<{ quote: any; onUpdateQuotes: () => void; onUpdateQuotes2: () => void }> = ({
  quote,
  onUpdateQuotes,
  onUpdateQuotes2,
}) => {
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [storyText, setStoryText] = useState(quote.id);
  const [isLoading, setIsLoading] = useState(false);
  const { createAudio } = useCreateAudio();

  return (
    <Box key={quote.id} mb={3}>
      <Button
        component="a"
        target="_blank"
        href={`https://www.google.com/search?q=${quote.author}`}
      >
        {quote.author}
      </Button>
      <CopyToClipboard
        text={`
  Explain this phase of ${quote.author}:
  ${quote.id}

  When it was said (year and place)? What does it mean?

  Give me a description of this phrase in a format:
  [Phrase]

  [When was said]
  [The meaning]

  The answer must be in Ukrainian language. 
  Don't put some title in the answer. Just follow my answer pattern.
`}
      >
        <Button>What does it mean?</Button>
      </CopyToClipboard>
      <Button
        color="error"
        onClick={async () => {
          const { data } = await supabaseClient
            .from("english_quotes")
            .select()
            .is("grammar", null)
            .eq("author", quote.author);
          await Promise.all(
            data!.map((d) => {
              return supabaseClient.from("english_quotes").delete().eq("id", d.id);
            }),
          );
          onUpdateQuotes2();
        }}
      >
        Remove all author quotes
      </Button>
      <Typography>{quote.id}</Typography>
      <TextField
        color="primary"
        value={storyText}
        multiline={true}
        sx={{
          width: 500,
        }}
        placeholder="Description"
        onChange={(event) => {
          setStoryText(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <Box color="brown">Words: {quote.word_count}</Box>
      <Box color="brown">User intersection: {quote.all_intersection_count}</Box>
      <Box color="brown">User new words: {quote.new_words_count}</Box>
      <HStack>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            setIsLoading(true);
            const audio = await createAudio(quote.id);
            await supabaseClient
              .from("english_quotes")
              .update({
                audio,
              })
              .eq("id", quote.id);
            setIsLoading(false);
          }}
        >
          Create audio
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            setIsLoading(true);
            // await supabaseClient
            //   .from("english_quotes")
            //   .update({
            //     description,
            //   })
            //   .eq("id", quote.id);
            setIsLoading(false);
          }}
        >
          Update
        </LoadingButton>
      </HStack>
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          setIsLoading(true);
          await supabaseClient.from("english_quotes").delete().eq("id", quote.id);
          onUpdateQuotes();
          setIsLoading(false);
        }}
      >
        remove
      </Button>
      <Button
        variant="outlined"
        // color="error"
        onClick={async () => {
          setIsLoading(true);
          const result = await supabaseClient
            .from("english_quotes")
            .update({
              grammar: true,
            })
            .eq("id", quote.id);
          setIsLoading(false);
          console.log("🚀 ~ result:", result);
          // setQuotes(quotes.filter((q) => q.id != quote.id));
        }}
      >
        add to grammar
      </Button>

      {quote.row_new_words && (
        <>
          <Button
            variant="outlined"
            // color="error"
            onClick={async () => {
              setIsLoading(true);
              const { data } = await supabaseClient
                .from("words")
                .select()
                .in("name", quote.row_new_words);
              if (data) {
                setGlobalWords(data);
              }
              setIsLoading(false);
              // setQuotes(quotes.filter((q) => q.id != quote.id));
            }}
          >
            check words
          </Button>
          <CopyNewWordsForGemini words={quote.row_new_words} />
          <Box color="brown">
            <Typography variant="h4">New words:</Typography>
            {quote.row_new_words.map((word: string) => {
              const existingWord = globalWords.find((w) => w.name === word);
              return (
                <Button key={word}>
                  {word} ({existingWord?.examples?.length})
                </Button>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
const AdminCheckUserQuotes: React.FC = () => {
  const [quotes, setQuotes] = useState<any[]>([]);
  const [authors, setAuthors] = useState([]);
  const [search, setSearch] = useState("");
  const [initialQuotes, setInitialQuotes] = useState(() => {
    return quotesData.filter((q) => q.new_words_count < 10);
  });

  useMountedWaitAsyncEffect(
    () => {
      // quotesData
      if (search) {
        const quotes = initialQuotes
          .filter((q) => q.id.includes(search) && q.all_intersection_count > 5)
          .sort((a, b) => a.new_words_count - b.new_words_count);
        console.log("🚀 ~ quotes:", quotes.length);
        quotes.length = 1000;

        setQuotes(quotes);
      }
    },
    [search, initialQuotes],
    3000,
  );

  return (
    <MenuLayout>
      <Box
        sx={{
          backgroundColor: "white",
        }}
        p={5}
      >
        <TextField
          fullWidth
          color="primary"
          value={search}
          multiline={true}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          type="text"
          variant="outlined"
        />
        <Grid container>
          <Grid item xs={12}>
            {quotes.map((quote) => {
              return (
                <Quote
                  quote={quote}
                  key={quote.id}
                  onUpdateQuotes={() => {
                    setInitialQuotes(initialQuotes.filter((q) => q.id != quote.id));
                  }}
                  onUpdateQuotes2={() => {
                    setInitialQuotes(initialQuotes.filter((q) => q.author != quote.author));
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminCheckUserQuotes;
