import { useParams } from "react-router-dom";
import { Word } from "../../../types/supabase";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import LinearProgress from "../../User/LinearProgress";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import parsed from "../../../common/parsed";
import { format } from "date-fns";
import { useLessonsStore } from "../../../store/lessonsStore";
import ShowCurrentHomeWorkNumbersInDrawer from "../../Unknown/ShowCurrentHomeWorkNumbersInDrawer";
import { drawerContentStyle, userHomeWorkCurrentDay } from "../../../common/constants";
import useIsHomeWork from "../../../hooks/useIsHomeWork";
import Stories from "../../Grammar/Stories";
import getSentenceWords from "../../../common/getSentenceWords";
import HStack from "../../Unknown/HStack";
import WordAudio from "../../Audio/WordAudio";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import useGlobalWordsMap from "../../hooks/useGlobalWordsMap";
import isAdmin from "../../../common/isAdmin";
import RepetitionButtons from "../../Sentence/RepetitionButtons";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import ControllingStudentPage from "../../Unknown/Root/ControllingStudentPage";
import { usePrevious } from "react-use";

const _5and4Sentences = [
  "I have a cat.",
  "I see a tree. It is a big tree.",
  "This tree is big.",
  "This is a big tree.",
  "This root is big.",
  "I have seven apples.",
  "I see a dog.",
  "A dog ran to a cat.",
  "I have apples and pineapples.",
  "This is a big room.",
  "This room is big.",
  "I have a pan and a pen.",
  "I see a man and a dog.",
  "I have a room. It is a big room.",
  "I have five pens and nine apples.",
  "I have an apple and a pineapple.",
  "I have a fan. It is a big fan.",
  "This is a fan.",
  "This fan is big.",
  "A cat ran to a bag.",
  "I need an apple.",
  "I have a pen in a bag.",
  "This is a bag. It is pink.",
  "This bag is pink.",
  "This is a pink bag.",
  "I have five pans.",
  "Seven apples in a bag.",
  "This apple is red.",
  "This is a red apple.",
  "I have a doll and a hat.",
  "A belt in a bag.",
  "I need a banana.",
  "Cats sit on a bed.",
  "I have a big green lime.",
  "This lime is big and green.",
  "This is a big and green lime.",
  "I have a goose.",
  "I have seven geese.",
  "I have a plan.",
  "I have a banana.",
  "I have a dog and a cat.",
  "I have a big bedroom.",
  "This bedroom is big.",
  "This is a big room.",
  "I need a lime.",
  "This kite is green.",
  "I need it.",
  "This is a green kite.",
  "I have a kite. It is green and big.",
  "This bedroom is pink.",
  "I have a bedroom. It is big and pink.",
  "I have a pink and green pen.",
  "I have nine bananas and five apples.",
  "I need a red and pink pen.",
];
const _6Sentences = [
  "I have a pet. It is a rat.",
  "This is a rat. The rat is little.",
  "I have a pet. It is a cat. It is fat.",
  "This is a cat. The cat is little.",
  "This is a rat. This is a mat. The rat sat on the mat.",
  "Look, a rat ran. The rat is little.",
  "I have a green mat. The mat is big.",
  "Look, a cat ran. The cat sat on the mat.",
  "Look, the rat sat on the hat.",
  "Look, the cat sat on the hat.",
  "This coffee is too hot.",
  "This is green tea. The tea is too hot.",
  "I like this coffee.",
  "I like green tea.",
  "I like this tea.",
  "I like to eat apples.",
  "I like to eat bananas.",
  "I like green tea too.",
  "I like bananas too.",
  "I like apples too.",
  "I see a tree. The tree is big.",
  "This is a dog. This is a cat. The dog ran to the cat.",
];
const _7Sentences = [
  "John has a jeep.",
  "John and Jan have a van and a jeep.",
  "This is a big jeep.",
  "Jan likes to eat jam.",
  "I like to eat jam.",
  "John likes to eat apple jam.",
  "A trash bin.",
  "A trash can.",
  "I have a pet. It is a sheep.",
  "He has a pet. It is a cat.",
  "She has a big cat.",
  "He has a fat cat.",
  "The tree has big roots.",
  "She has a pet. It is a rat.",
  "I like to go to the shop.",
  "He has a little cat.",
  "She has a big dog.",
  "He has a big green mat.",
  "We have a goose.",
  "She likes to go to the park.",
  "This man has a big dog.",
  "Dan has a fish.",
  "He likes to eat bananas.",
  "The tree has nine big roots.",
  "She likes to eat apples.",
  "We have nine geese.",
  "We like to go to the park.",
  "She has a cat. It likes to climb a tree.",
  "Cats like to climb a tree.",
  "Dan has a pet. It is a rat.",
  "We need to do it.",
  "She is in the park.",
  "She has a pet. It is a fish.",
  "Look. A cat climbs a tree.",
  "He needs a red pen.",
  "She is in the park.",
  "He likes green tea.",
  "I am in the park.",
  "She is in the shop.",
  "She needs to do it.",
  "Kate has a pet. It is a hamster.",
  "This is an apple tree.",
  "I am in the shop.",
  "She likes coffee.",
  "The man has a fan.",
  "He is in the shop.",
  "She and he like apples.",
  "She has a big bag.",
  "He is in the park.",
  "I have a little hamster. It likes to eat apples.",
  "She has a lamb.",
  "We are in the shop.",
  "This man has a kite.",
  "He needs a green pen and a red pen.",
  "He has five lambs.",
  "I like to be in the park.",
  "She likes hot tea.",
  "He needs to do it.",
  "He likes to be in the park.",
  "We are in the park.",
  "She has a pink bag.",
  "He likes hot coffee.",
  "He likes to eat apples and bananas.",
  "She has nine pens.",
];
const _8Sentences = [
  "No problem.",
  "I don't have time. I have no time.",
  "I do not have a pen. I have no pen.",
  "I do not have an apple. I have no apple.",
  "We do not have time. We have no time.",
  "They do not have a van. They have no van.",
  "We do not have a jeep. We have no jeep.",
  "Cats and dogs are animals. Sheep and geese are animals too.",
  "I like to run in the park.",
  "I want a cup of tea.",
  "I understand you.",
  "I want a cup of coffee.",
  "She understands me.",
  "He understands me.",
  "This cup of coffee is too hot.",
  "We understand you.",
  "You understand me.",
  "This cup of coffee is too big.",
  "She likes to walk in the park.",
  "He needs to wash the dishes.",
  "I have water in the bag.",
  "I want an apple.",
  "This cup of tea is too hot.",
  "I want a glass of water.",
  "She wants to see a swan.",
  "This glass of water is too cold.",
  "I have a cold.",
];
// This tree has big roots.
const config: Record<string, { text: string[] }> = {
  "5.4": {
    text: [..._5and4Sentences],
  },
  "6": {
    text: [..._6Sentences, ..._5and4Sentences],
  },
  "7": {
    text: [..._7Sentences],
  },
  "8": {
    text: [..._8Sentences],
  },
};

const Reading: React.FC<{}> = ({}) => {
  const { lessonId } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  const authUser = useLessonsStore((state) => state.authUser);
  const adminControlReadingSentenceIndex = useLessonsStore(
    (state) => state.adminControlReadingSentenceIndex,
  );

  const words = useMemo(() => {
    if (!lessonId) return [];
    return getSentenceWords(config[lessonId].text.join(" "));
  }, [lessonId]);
  const [sentences, setSentences] = useState<string[]>([]);

  useEffect(() => {
    if (lessonId && config[lessonId]?.text) {
      setSentences(config[lessonId].text.sort((a, b) => a.localeCompare(b)));
    }
  }, [lessonId]);

  const currentSentence = useMemo(() => {
    return sentences[currentIndex];
  }, [sentences, currentIndex]);

  const curerntModuleWordsMap = useGlobalWordsMap({ words });

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const updateSentences = async (period: number) => {
    if (period + 1 < sentences.length) {
      const newSentences = sentences.slice(1).reduce<string[]>((prev, sentence, index) => {
        const updated = [...prev];
        if (index + 1 === period) updated.push(currentSentence);
        updated.push(sentence);
        return updated;
      }, []);
      setSentences(newSentences);
    } else {
      setSentences([...sentences.slice(1), sentences[0]]);
    }

    const randomNumber = Math.floor(Math.random() * Math.random() * 10000000) / 10000000;
    const [, randomNumberDecimal] = randomNumber.toString().split(".");

    await supabaseClient
      .from("admin_control")
      .update({
        // user: studentUserUuid,
        new_reading_sentence_index: `${period}.${randomNumberDecimal}`,
      })
      .eq("id", 0);
  };

  const prevAdminControlReadingSentenceIndex = usePrevious(adminControlReadingSentenceIndex);
  useMountedWaitAsyncEffect(
    () => {
      if (
        authUser &&
        adminControlReadingSentenceIndex &&
        prevAdminControlReadingSentenceIndex !== adminControlReadingSentenceIndex &&
        !isAdmin(authUser?.id)
      ) {
        const period = Math.floor(adminControlReadingSentenceIndex);
        if (period) updateSentences(period);
      }
    },
    [adminControlReadingSentenceIndex, prevAdminControlReadingSentenceIndex, authUser],
    0,
  );

  if (!lessonId || !config[lessonId]) return null;

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setIsOpen(true)}>
        Читати
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 999,
          // opacity: 0,
          // backgroundColor: "rgba(0, 0, 0, 1) !important",
        }}
      >
        <Box sx={drawerContentStyle}>
          <LinearProgress elementsCount={sentences.length - 1} currentValue={currentIndex} />
          <ModalCloseButton onClose={handleDrawerClose} />
          <VStack>
            <Box maxWidth={500} margin="1 auto" px={4} pt={15} pb={5}>
              <HStack sx={{ flexWrap: "nowrap", width: "100%" }}>
                <HoverDetails
                  // isCenter
                  fontSize={25}
                  words={
                    currentSentence?.split(" ")?.map((w: any, index: number) => {
                      const cleanWord = removePunctuationFromString(w).replace("__", " ");

                      const word =
                        curerntModuleWordsMap[cleanWord] ||
                        curerntModuleWordsMap[cleanWord.toLowerCase()];
                      // console.log("🚀 ~ cleanWord:", w, cleanWord, word);

                      return {
                        ...(word ? word : {}),
                        word: word?.name || cleanWord,
                        label: w.replace("__", " "),
                      };
                    }) as any
                  }
                />
              </HStack>
            </Box>
            <Box py={3}>
              {isAdmin(authUser?.id) && (
                <VStack py={5}>
                  <RepetitionButtons
                    isVisible
                    onlyCurrentDay
                    wordsCount={sentences.length}
                    goToNextSlide={async (period) => {
                      updateSentences(+period);
                    }}
                  />
                </VStack>
              )}
              {!isTeacherLesson && !isAdmin(authUser?.id) && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (currentIndex + 1 >= sentences.length) {
                      setCurrentIndex(0);
                    } else {
                      setCurrentIndex((prev) => prev + 1);
                    }
                  }}
                >
                  далі
                </Button>
              )}
            </Box>
            {(isTeacherLesson || isAdmin(authUser?.id)) && (
              <Box>
                <ControllingStudentPage isNested />
              </Box>
            )}
          </VStack>
        </Box>
      </Drawer>
    </>
  );
};

export default Reading;
