import { Box, Button, Drawer, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import VStack from "../../Unknown/VStack";
import initialData from "./data";

import { UniqueIdentifier } from "@dnd-kit/core";
import Test from "./Test";
import isAdmin from "../../../common/isAdmin";
import { useLessonsStore } from "../../../store/lessonsStore";

const BuildWordWithLetters: React.FC<{ words: string[] }> = ({ words }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Складанка
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <DrawerContent words={words} onClose={handleDrawerClose} />
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const DrawerContent: React.FC<{
  words: string[];
  onClose: () => void;
}> = ({ words, onClose }) => {
  const [items, setItems] = useState({ ...initialData });
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const uniqueLetters = useMemo(() => {
    return Array.from(new Set(words.join("").split("")));
  }, [words]);
  const authUser = useLessonsStore((state) => state.authUser);

  return (
    <Box sx={drawerContentStyle}>
      <ModalCloseButton onClose={onClose} />
      <VStack
        justifyContent="center"
        position="relative"
        width="100vw"
        flexWrap="nowrap"
        sx={{
          overflowX: "hidden",
        }}
      >
        <Test letters={uniqueLetters} />
        {isAdmin(authUser?.id) && <Typography>{words?.join(", ")}</Typography>}
      </VStack>
    </Box>
  );
};

export default BuildWordWithLetters;
