const storiesData = [
  {
    row_new_words: ["dad", "had", "then", "he", "no", "jan", "she"],
    words: ["dad", "had", "a", "fan", "then", "he", "no", "jan", "hat", "she"],
    link: "AvlOlGoCgQo",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Dad had a fan.\nThen he had no fan.\nJan had a hat.\nThen she had no hat.",
    story_id: 1699,
    word_count: 10,
    all_intersection_count: 3,
    new_words_count: 7,
  },
  {
    row_new_words: ["rat", "Matt", "the", "sat", "mat", "look", "too", "fat"],
    words: [
      "this",
      "is",
      "a",
      "cat",
      "rat",
      "Matt",
      "hat",
      "the",
      "sat",
      "on",
      "mat",
      "look",
      "too",
      "fat",
    ],
    link: "(1)uVeEAFy1z68",
    type: "song",
    grammar: true,
    pdf_name: null,
    storage_link: null,
    id: "This is a cat. This is a rat. This is a mat. This is a hat. The rat sat. The rat sat on the mat. The cat sat. The cat sat on the mat. The rat sat. The rat sat on the hat. The cat sat. The cat sat on the hat. Look, the cat is too fat.",
    story_id: 1698,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "how's",
      "the",
      "weather",
      "today",
      "sunny",
      "rainy",
      "cloudy",
      "snowy",
      "let's",
      "look",
      "outside",
    ],
    words: [
      "how's",
      "the",
      "weather",
      "today",
      "is",
      "it",
      "sunny",
      "rainy",
      "cloudy",
      "snowy",
      "let's",
      "look",
      "outside",
    ],
    link: "D1qV5PsDoQw",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "How's the weather? How's the weather? How's the weather today? Is it sunny? Is it rainy? Is it cloudy? Is it snowy? How's the weather today? Let's look outside. How's the weather? Is it sunny today? Let's look outside. How's the weather? Is it rainy today? Look outside. How's the weather? Is it cloudy today? Let's look outside. Is it snowy today? How's the weather? How's the weather? How's the weather today? Is it sunny? Is it rainy? Is it cloudy? Is it snowy? How's the weather today? How's the weather? How's the weather? How's the weather today? Is it sunny? Is it rainy? Is it cloudy? Is it snowy? How's the weather today?",
    story_id: 1712,
    word_count: 13,
    all_intersection_count: 2,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "jingle",
      "I",
      "ring",
      "my",
      "fast",
      "slow",
      "high",
      "low",
      "over",
      "here",
      "there",
      "everywhere",
    ],
    words: [
      "jingle",
      "little",
      "bell",
      "I",
      "can",
      "ring",
      "my",
      "it",
      "fast",
      "slow",
      "high",
      "low",
      "over",
      "here",
      "there",
      "everywhere",
    ],
    link: "VJXg2xmdG7k",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Jingle, jingle little bell I can ring my little bell.\nRing it fast, ring it slow, ring it high, ring it low.\nJingle, jingle little bell I can ring my little bell.\nRing it fast, ring it slow, ring it high, ring it low.\nJingle, jingle little bell I can ring my little bell.\nRing it over here, ring it over there, ring it everywhere\nJingle, jingle little bell I can ring my little bell.\nRing it over here, ring it over there, ring it everywhere.\nJingle, jingle little bell I can ring my little bell.",
    story_id: 1725,
    word_count: 16,
    all_intersection_count: 4,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "jan",
      "has",
      "her",
      "blue",
      "wet",
      "net",
      "she",
      "get",
      "let",
      "go",
      "jet",
      "vet",
      "fish",
    ],
    words: [
      "jan",
      "has",
      "a",
      "pet",
      "her",
      "is",
      "little",
      "blue",
      "wet",
      "net",
      "she",
      "can",
      "get",
      "let",
      "it",
      "go",
      "jet",
      "vet",
      "fish",
    ],
    link: "(1)fh-zRxSG6N8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Jan has a pet. Her pet is little. Her pet is blue. Her pet is wet. Jan has a net. She can get her wet pet. She can let it go. Her pet has a jet. Her pet has a vet. It is a fish.",
    story_id: 1751,
    word_count: 19,
    all_intersection_count: 6,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "hot",
      "cross",
      "buns",
      "one",
      "penny",
      "two",
      "give",
      "them",
      "your",
      "daughters",
      "sons",
      "1",
      "2",
    ],
    words: [
      "hot",
      "cross",
      "buns",
      "one",
      "a",
      "penny",
      "two",
      "give",
      "them",
      "to",
      "your",
      "daughters",
      "sons",
      "1",
      "2",
    ],
    link: "re3gXNTtwig",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Hot cross buns, Hot cross buns. One a penny, two a penny. Hot cross buns, Hot cross buns, Hot cross buns. One a penny, two a penny. Hot cross buns. Give them to your daughters, Give them to your sons. One a penny, two a penny. Hot cross buns, Hot cross buns, Hot cross buns. One a penny, two a penny. Hot cross buns, Hot cross buns, hot cross buns. 1 a penny, 2 a penny hot cross buns. Give them to your daughters, Give them to your sons. One a penny to a penny Hot cross buns.",
    story_id: 1600,
    word_count: 15,
    all_intersection_count: 2,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "starlight",
      "star",
      "bright",
      "first",
      "I",
      "tonight",
      "wish",
      "may",
      "might",
      "the",
      "say",
      "mighth",
      "night",
    ],
    words: [
      "starlight",
      "star",
      "bright",
      "first",
      "I",
      "see",
      "tonight",
      "wish",
      "may",
      "might",
      "have",
      "the",
      "say",
      "mighth",
      "to",
      "night",
    ],
    link: "OWip7yvXukI",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Starlight star bright first star I see tonight Wish I may Wish I might have the wish I wish tonight Starlight, star bright first star I say tonight Wish I may wish I might have the wish I wish tonight Starlight, star bright first star I see tonight Wish I may, Wish I might have the wish I wish tonight Starlight, star bright first star I see tonight Wish I may wish I mighth have the wish I wish to night",
    story_id: 1597,
    word_count: 16,
    all_intersection_count: 3,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "daddy",
      "finger",
      "where",
      "are",
      "you",
      "here",
      "I",
      "am",
      "how",
      "do",
      "mommy",
      "brother",
      "sister",
      "baby",
    ],
    words: [
      "daddy",
      "finger",
      "where",
      "are",
      "you",
      "here",
      "I",
      "am",
      "how",
      "do",
      "mommy",
      "brother",
      "sister",
      "baby",
    ],
    link: "G6k7dChBaJ8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "daddy finger. Where are you?\nhere I am.\nhow do you do?\nmommy finger. Where are you?\nhere I am.\nhow do you do?\nbrother finger. Where are you?\nhere I am.\nhow do you do?\nsister finger. Where are you?\nhere I am.\nhow do you do?\nbaby finger. Where are you?\nhere I am.\nhow do you do?",
    story_id: 1589,
    word_count: 14,
    all_intersection_count: 0,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "jungles",
      "of",
      "Brazil",
      "the",
      "that's",
      "where",
      "we",
      "go",
      "animals",
      "say",
      "hello",
      "snake",
      "parrot",
      "monkey",
      "lion",
    ],
    words: [
      "jungles",
      "of",
      "Brazil",
      "in",
      "the",
      "that's",
      "where",
      "we",
      "go",
      "to",
      "see",
      "animals",
      "say",
      "hello",
      "snake",
      "parrot",
      "monkey",
      "lion",
    ],
    link: "https://learnenglishkids.britishcouncil.org/listen-watch/songs/jungles-brazil",
    type: "song",
    grammar: true,
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/jungles.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ZqLTkqDPTuoTyQJ%2Fu%2Fcd9jcdNgO7Jccdv3qDnGLTFHiWWcouQoCQdrZ%2Bkj57ONQ8W2pYt0U2HRGlY%2FSh3sr%2FPvy2hzIM5XOsVyqvYphzsVsqB262XFgcFgT4zMxE%2FIvxPwWQ%2FyTiLj1JOKwH2IWg4dlNrb2c1lGhAV%2FhF76Xb%2Fg3e8MXmyloLPtJ%2F3NshW9kpBnVrDBrKcUk19EDlET6NBXLdrIj5rUmoCYu2YV13HkLMjgPIcXyFffTsUsfs1p9h%2BBhORUOhsBZqFU8CryuWnyVgBq4g56l0%2F5YaKrVFR9aLq%2F5B4LVkhlNB38aeQ31LZolPHoyKgPcx8qinsGYOg%3D%3D",
    id: "In the jungles of Brazil\nThat’s where we go to see the animals\nWe say hello to the snake\nIn the jungles of Brazil.\n\nIn the jungles of Brazil\nThat’s where we go to see the animals\nWe say hello to the parrot\nWe say hello to the snake\nIn the jungles of Brazil.\n\nIn the jungles of Brazil\nThat’s where we go to see the animals\nWe say hello to the monkey\nWe say hello to the parrot\nWe say hello to the snake\nIn the jungles of Brazil.\n\nIn the jungles of Brazil\nThat’s where we go to see the animals\nWe say hello to the lion\nWe say hello to the monkey\nWe say hello to the parrot\nWe say hello to the snake\nIn the jungles of Brazil.\n\nIn the jungles of Brazil.",
    story_id: 874,
    word_count: 18,
    all_intersection_count: 3,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "three",
      "times",
      "monkeys",
      "vine",
      "9",
      "3",
      "4",
      "12",
      "books",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
      "four",
    ],
    words: [
      "three",
      "times",
      "is",
      "nine",
      "monkeys",
      "on",
      "a",
      "vine",
      "9",
      "3",
      "4",
      "12",
      "books",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
      "four",
    ],
    link: "8vL-tlcPhC4",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Three times three. Three times three is nine. Nine monkeys on a vine. Three times three is nine. 9 monkeys on a vine. Three times 3 is monkeys on a vine. Three times 4 is 12. 12 books on a shelf, I will read them all. Three times four is 12. 12 books on a shelf, I will read them all.",
    story_id: 1664,
    word_count: 20,
    all_intersection_count: 4,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "rain",
      "go",
      "away",
      "come",
      "again",
      "another",
      "day",
      "daddy",
      "wants",
      "play",
      "",
      "mommy",
      "brother",
      "sister",
      "baby",
      "all",
      "the",
      "family",
    ],
    words: [
      "rain",
      "go",
      "away",
      "come",
      "again",
      "another",
      "day",
      "daddy",
      "wants",
      "to",
      "play",
      "",
      "mommy",
      "brother",
      "sister",
      "baby",
      "all",
      "the",
      "family",
    ],
    link: "LFrKYjrIDs8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Rain, rain, go away\nCome again another day\nDaddy wants to play\n\nRain, rain, go away\nCome again another day\nMommy wants to play\n\nRain, rain, go away\nCome again another day\nBrother wants to play\n\nRain, rain, go away \nCome again another day\nSister wants to play\n\nRain, rain, go away\nCome again another day\nBaby wants to play\n\nRain, rain, go away\nCome again another day\nAll the family family wants to play\n\nRain, rain, go away",
    story_id: 1599,
    word_count: 19,
    all_intersection_count: 1,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "row",
      "your",
      "boat",
      "gently",
      "down",
      "the",
      "stream",
      "merrily",
      "but",
      "dream",
      "ro",
      "throw",
      "stroll",
      "me",
      "your're",
      "gent",
      "fe",
      "dre",
    ],
    words: [
      "row",
      "your",
      "boat",
      "gently",
      "down",
      "the",
      "stream",
      "merrily",
      "life",
      "is",
      "but",
      "a",
      "dream",
      "ro",
      "throw",
      "stroll",
      "me",
      "your're",
      "gent",
      "fe",
      "on",
      "dre",
    ],
    link: "7otAJa3jui8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Row, row, row your boat gently down the stream Merrily, merrily, merrily, merrily Life is but a dream Row, row, row your boat gently down the stream Merrily, merrily, merrily, merrily Life is but a dream Row ro, row your boat gently down the stream Merrily, merrily, merrily, merrily Life is but a dream Row, row, row your boat gently down the stream Merrily, merrily, merrily, merrily Life is but a dream Row, row, row your boat gently down Throw your boat gently down the Stroll your boat Merrily, merrily Me your're merrily gent fe down on the stream Merrily, merrily, merrily, merrily Life is but a dream Life is a dre.",
    story_id: 1717,
    word_count: 22,
    all_intersection_count: 4,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "the",
      "jog",
      "with",
      "dan",
      "they",
      "fog",
      "by",
      "log",
      "but",
      "too",
      "walk",
      "jumps",
      "over",
      "good",
      "said",
      "you",
      "do",
      "lot",
    ],
    words: [
      "this",
      "is",
      "a",
      "dog",
      "the",
      "can",
      "jog",
      "with",
      "dan",
      "they",
      "in",
      "fog",
      "by",
      "van",
      "man",
      "to",
      "log",
      "but",
      "too",
      "big",
      "walk",
      "jumps",
      "over",
      "good",
      "said",
      "you",
      "do",
      "lot",
    ],
    link: "(1)XofVFIIDLdk",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "This is a dog. The dog can jog. The dog can jog with Dan. They jog in the fog. They jog by a dog. They jog by a van. They jog by a man. They jog to a log. But the log is too big. Walk the dog jumps over the log. Good dog, said Dan. You can do a lot.",
    story_id: 1749,
    word_count: 28,
    all_intersection_count: 10,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "my",
      "soft",
      "when",
      "quite",
      "alone",
      "god",
      "watches",
      "me",
      "with",
      "care",
      "sees",
      "at",
      "rising",
      "kneeling",
      "down",
      "listens",
      "prayer",
      "suffered",
      "pray",
    ],
    words: [
      "in",
      "my",
      "soft",
      "bed",
      "when",
      "quite",
      "alone",
      "god",
      "watches",
      "me",
      "with",
      "care",
      "sees",
      "at",
      "rising",
      "kneeling",
      "down",
      "and",
      "listens",
      "to",
      "prayer",
      "suffered",
      "see",
      "pray",
    ],
    link: "QzqYHCrKBO8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "In my soft bed when quite alone God watches me with care Sees me at rising, kneeling down and listens to my prayer in my suffered when quite alone God watches me with care See me at rising, kneeling down and listens to my pray",
    story_id: 1671,
    word_count: 24,
    all_intersection_count: 5,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "that",
      "evening",
      "when",
      "I",
      "go",
      "the",
      "starshine",
      "overhead",
      "they",
      "are",
      "daysies",
      "white",
      "dart",
      "midadle",
      "of",
      "night",
      "at",
      "dark",
      "middle",
    ],
    words: [
      "that",
      "evening",
      "when",
      "I",
      "go",
      "to",
      "bed",
      "see",
      "the",
      "starshine",
      "overhead",
      "they",
      "are",
      "little",
      "daysies",
      "white",
      "dart",
      "midadle",
      "of",
      "night",
      "at",
      "dark",
      "middle",
    ],
    link: "MSo_1WRkNf0",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "That evening when I go to bed I see the starshine overhead. They are the little daysies white that dart the midadle of the night of the night. At evening when I go to bed I see the starshine overhead. They are the little daysies white that dark the middle of the night of the night.",
    story_id: 1673,
    word_count: 23,
    all_intersection_count: 4,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "twinkle",
      "la",
      "star",
      "how",
      "I",
      "wonder",
      "what",
      "you",
      "are",
      "up",
      "above",
      "the",
      "world",
      "so",
      "high",
      "diamond",
      "sky",
      "littleow",
      "leo",
    ],
    words: [
      "twinkle",
      "la",
      "star",
      "how",
      "I",
      "wonder",
      "what",
      "you",
      "are",
      "up",
      "above",
      "the",
      "world",
      "so",
      "high",
      "like",
      "a",
      "diamond",
      "in",
      "sky",
      "littleow",
      "leo",
      "little",
    ],
    link: "yCjJyiqpAuU",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Twinkle, twinkle la star How I wonder what you are up above the world so high Like a diamond in the sky Twinkle, twinkle littleow star How I wonder what you are Twinkle, twinkle Leo star How I wonder what you are up above the world so high Like a diamond in the sky Twinkle, twinkle little little star How I wonder what you are",
    story_id: 1595,
    word_count: 23,
    all_intersection_count: 4,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "5",
      "times",
      "55",
      "20",
      "25",
      "bees",
      "beside",
      "hive",
      "205",
      "high",
      "3",
      "4",
      "1212",
      "books",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
    ],
    words: [
      "5",
      "times",
      "55",
      "is",
      "20",
      "25",
      "bees",
      "beside",
      "a",
      "hive",
      "205",
      "five",
      "high",
      "3",
      "4",
      "1212",
      "books",
      "on",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
    ],
    link: "NPfydhrQYzA",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "5 times 55 times 5 is 20 25, 25 bees beside a hive 5 times 5 is 20 25, 205 bees beside a hive 5 times five is bees beside a high 3 times 4 is 1212 books on a shelf, I will read them all. 3 times 4 is 1212 books ON A shelf, I will read them all.",
    story_id: 1665,
    word_count: 24,
    all_intersection_count: 4,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "the",
      "wheels",
      "bus",
      "go",
      "round",
      "door",
      "goes",
      "open",
      "shut",
      "wipers",
      "swish",
      "horn",
      "beep",
      "people",
      "up",
      "down",
      "babies",
      "wh",
      "wham",
      "where",
      "school",
      "mummy",
      "daddy'on",
    ],
    words: [
      "the",
      "wheels",
      "on",
      "bus",
      "go",
      "round",
      "and",
      "door",
      "goes",
      "open",
      "shut",
      "wipers",
      "swish",
      "horn",
      "beep",
      "people",
      "up",
      "down",
      "babies",
      "wh",
      "wham",
      "where",
      "school",
      "mummy",
      "daddy'on",
    ],
    link: "yWirdnSDsV4",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "The wheels on the bus go round and round round and round round and round the wheels on the bus go round and round, round and round the door on the bus goes open and shut open and shut open and shut the door on the bus goes open and shut open and shut the wipers on the bus go swish swish swish swish swish swish swish swish swish the wipers on the bus go swish swish swish swish swish swish the horn on the bus goes beep beep beep beep beep beep beep beep beep the horn on the bus goes beep beep beep beep beep beep the people on the bus go up and down, up and down, up and down the people on the bus go up and down, up and down the babies on the bus go wh wham wh wham wham wh wham wham where the babies on the bus go? School where where where where where where? The mummy on the bus go? The daddy'on the bus go.",
    story_id: 1715,
    word_count: 25,
    all_intersection_count: 2,
    new_words_count: 23,
  },
  {
    row_new_words: [
      "flea",
      "climb",
      "yes",
      "he",
      "eat",
      "drink",
      "hear",
      "but",
      "do",
      "the",
      "cancan",
      "me",
      "mouse",
      "build",
      "house",
      "no",
      "can't",
      "catch",
      "rat",
      "dance",
      "jig",
      "snake",
      "cake",
    ],
    words: [
      "can",
      "a",
      "flea",
      "climb",
      "tree",
      "yes",
      "he",
      "eat",
      "and",
      "drink",
      "hear",
      "see",
      "but",
      "do",
      "the",
      "cancan",
      "like",
      "me",
      "mouse",
      "build",
      "house",
      "no",
      "can't",
      "cat",
      "catch",
      "rat",
      "pig",
      "dance",
      "jig",
      "snake",
      "cake",
    ],
    link: "https://learnenglishkids.britishcouncil.org/listen-watch/songs/can-flea-climb-tree",
    type: "song",
    grammar: true,
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/flea.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=wGNPqPN4absXfPyM57NQE6BJq4VHy17MoKBMSWkI05CPNrWvoeVlQ9nL%2FGFcT%2BwERyKyNNeuBuvrp1UurulbGTKh8PFZnLjUTAE8AgNrBnPI8liECvDzinG7VnjhFnitMrP5EFqnH6djj8e4YMmDsEoH8dI7Fu7O9rMS9xHWLcKz0PDUIeVW%2Fq%2F6bq%2F1gBUmx2wJ0QQknvikn2mjggFNIGAIoQt6xYf0BfPt7wA0nvfVquOUgC1iolBmPQHyoCsa0mYHBTsJ11ydj9OdNlrVEIbG19aubrMKwXST3IimxLmms0byAF4VDY3okNKYD%2B2JOO0Eu5cn3j6P06VgrnJNqA%3D%3D",
    id: "Can a flea climb a tree?\nYes he can, yes he can\nCan a flea climb a tree?\nYes he can, can, can.\n\nCan a flea climb a tree?\nYes he can, yes he can\nCan a flea climb a tree?\nYes he can, can, can.\n\nHe can eat and drink\nHe can hear and see\nBut can he do the cancan like me?\n\nCan a mouse build a house?\nNo he can’t, no he can’t\nCan a mouse build a house?\nNo he can’t, can’t, can’t.\n\nCan a mouse build a house?\nNo he can’t, no he can’t\nCan a mouse build a house?\nNo he can’t, can’t, can’t.\n\nHe can eat and drink\nHe can hear and see\nBut can he do the cancan like me?\n\nCan a cat catch a rat?\nYes he can, yes he can\nCan a cat catch a rat?\nYes he can, can, can.\n\nCan a cat catch a rat?\nYes he can, yes he can\nCan a cat catch a rat?\nYes he can, can, can.\n\nHe can eat and drink\nHe can hear and see\nBut can he do the cancan like me?\n\nCan a pig dance a jig?\nNo he can’t, no he can’t\nCan a pig dance a jig?\nNo he can’t, can’t, can’t.\n\nCan a pig dance a jig?\nNo he can’t, no he can’t\nCan a pig dance a jig?\nNo he can’t, can’t, can’t.\n\nHe can eat and drink\nHe can hear and see\nBut can he do the cancan like me?\n\nCan a snake eat a cake?\nYes he can, yes he can\nCan a snake eat a cake?\nYes he can, can, can.\n\nCan a snake eat a cake?\nYes he can, yes he can\nCan a snake eat a cake?\nYes he can, can, can.\n\nHe can eat and drink\nHe can hear and see\nBut can he do the cancan like me?",
    story_id: 936,
    word_count: 31,
    all_intersection_count: 8,
    new_words_count: 23,
  },
  {
    row_new_words: [
      "walking",
      "hop",
      "running",
      "now",
      "let's",
      "stop",
      "tip",
      "toe",
      "jump",
      "swimming",
      "sleep",
      "wake",
      "up",
      "it's",
      "go",
      "are",
      "you",
      "ready",
      "fast",
      "okay",
      "run",
      "let",
      "runningning",
      "u",
    ],
    words: [
      "walking",
      "hop",
      "running",
      "now",
      "let's",
      "stop",
      "tip",
      "toe",
      "jump",
      "swimming",
      "sleep",
      "wake",
      "up",
      "it's",
      "time",
      "to",
      "go",
      "are",
      "you",
      "ready",
      "fast",
      "okay",
      "run",
      "and",
      "let",
      "runningning",
      "u",
    ],
    link: "fPMjnlTEZwU",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Running, running, running, running, running, running. Now let's stop. Now let's stop. Walking, walking, walking, walking. Hop, hop, hop, hop hop hop. Running, running, running, running, running, running. Now let's stop. Now let's stop. Tip toe tip, toe tip toe tip toe. Jump, jump, jump, jump, jump, jump. Swimming, swimming, swimming, swimming, swimming, swimming. Now let's sleep. Now let's sleep. Wake up, it's time to go Are you ready to go fast? Okay. Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Running, running, running run and running. Now let stop. Now let's stop. Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Runningning, running, running, running, running. Now let stop. Now let's stop. U.",
    story_id: 1713,
    word_count: 27,
    all_intersection_count: 3,
    new_words_count: 24,
  },
  {
    row_new_words: [
      "happy",
      "face",
      "my",
      "happ",
      "###y",
      "sleepy",
      "facease",
      "an",
      "angry",
      "surprise",
      "surprised",
      "excited",
      "sad",
      "nervous",
      "phase",
      "silly",
      "exc",
      "now",
      "let",
      "me",
      "those",
      "faces",
      "show",
      "your",
    ],
    words: [
      "this",
      "is",
      "a",
      "happy",
      "face",
      "my",
      "happ",
      "###y",
      "sleepy",
      "facease",
      "an",
      "angry",
      "surprise",
      "surprised",
      "excited",
      "sad",
      "nervous",
      "phase",
      "silly",
      "exc",
      "now",
      "let",
      "me",
      "see",
      "those",
      "faces",
      "show",
      "your",
    ],
    link: "lQZX1IIAnLw",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "This is a happy face. This is a happy face. This is a happy face. This is my happ ###y face. This is a sleepy face. This is a sleepy face. This is a sleepy facease. This is my sleepy face. This is an angry face. This is an angry face. This is an angry face. This is my angry face. This is a surprise face. This is a surprised face. This is a surprised face. This is my surprised face. Happy, sleepy, Angry. Surprised. Happy, sleepy, angry, Surprised. This is an excited face. This is an excited face. This is an excited face. This is my excited face. This is a sad face. This is a sad face. This is a sad face. This is my sad face. This is a nervous face. This is a nervous phase. This is a nervous face. This is my nervous face. This is a silly face. This is a silly face. This is a silly face. This is my silly face. Excited. Sad. Nervous. Silly. Exc. Excited. Sad. Nervous. Silly. Now let me see those faces. Show me your happy face. Show me your sleepy face. Show me your angry face. Show me your surprise face. Show me your excited face. Show me your sad face. Show me your nervous face. Show me your silly face. This is a happy face. This is a happy face. This is a happy face. This is my happy face.",
    story_id: 1720,
    word_count: 28,
    all_intersection_count: 4,
    new_words_count: 24,
  },
  {
    row_new_words: [
      "Mary",
      "had",
      "whose",
      "fleece",
      "was",
      "white",
      "as",
      "snow",
      "giraffe",
      "neck",
      "very",
      "long",
      "rhinoceros",
      "horn",
      "sharp",
      "ouch",
      "kangaroo",
      "too",
      "pouch",
      "warm",
      "camel",
      "huh",
      "hump",
      "round",
    ],
    words: [
      "Mary",
      "had",
      "a",
      "little",
      "lamb",
      "whose",
      "fleece",
      "was",
      "white",
      "as",
      "snow",
      "and",
      "big",
      "giraffe",
      "neck",
      "very",
      "long",
      "rhinoceros",
      "horn",
      "sharp",
      "ouch",
      "kangaroo",
      "too",
      "pouch",
      "warm",
      "camel",
      "huh",
      "hump",
      "round",
    ],
    link: "YE7PiTwhTQk",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Mary had a little lamb, little lamb, little lamb.\nMary had a little lamb whose fleece was white as snow.\nMary had a little lamb and a big giraffe.\nMary had a big giraffe.\nMary had a big giraffe whose neck was very long, long, long, very, very, very long.\nAnd a rhinoceros.\nMary had a rhinoceros.\nMary had a rhinoceros whose horn was very sharp.\nOuch. Sharp, sharp, sharp, sharp, sharp, sharp, sharp, sharp.\nVery, very, very sharp.\nA kangaroo too.\nMary had a kangaroo, kangaroo, kangaroo.\nMary had a kangaroo whose pouch was very warm, warm, warm, warm, warm, warm, warm, warm, warm.\nVery, very, very warm.\nAnd a camel, huh?\nMary had a camel, camel, camel.\nMary had a camel whose hump was very round, round, round, round, round, round, round, round, round.\nVery, very, very round.",
    story_id: 1593,
    word_count: 29,
    all_intersection_count: 5,
    new_words_count: 24,
  },
  {
    row_new_words: [
      "if",
      "you're",
      "happy",
      "you",
      "know",
      "it's",
      "been",
      "around",
      "really",
      "want",
      "show",
      "touch",
      "the",
      "ground",
      "spin",
      "reach",
      "high",
      "up",
      "shake",
      "your",
      "legs",
      "play",
      "guitar",
      "nod",
      "head",
    ],
    words: [
      "if",
      "you're",
      "happy",
      "and",
      "you",
      "know",
      "it's",
      "been",
      "around",
      "it",
      "really",
      "want",
      "to",
      "show",
      "touch",
      "the",
      "ground",
      "spin",
      "reach",
      "a",
      "high",
      "up",
      "shake",
      "your",
      "legs",
      "play",
      "guitar",
      "nod",
      "head",
    ],
    link: "04KMI21kFnc",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "If you're happy and you know it's been around if you're happy and you know it's been around if you're happy and you know it and you really want to show it if you're happy and you know it's been around if you're happy and you know it Touch the ground if you're happy and you know it Touch the ground if you're happy and you know it and you really want to show it if you're happy and you know it Touch the ground, spin around if you're happy and you know it Reach a high if you're happy and you know it Reach a high if you're happy and you know it and you really want to show it if you're happy and you know it Reach up high, touch the ground, spin around if you're happy and you know it Shake your legs if you're happy and you know it Shake your legs if you're happy and you know it and you really want to show it if you're happy and you know it Shake your legs reach a high Touch the ground, spin around if you're happy and you know it Play guitar if you're happy and you know it Play guitar if you're happy and you know it and you really want to show it if you're happy and you know it Play guitar, shake your legs, reach a high Touch the ground, spin around if you're happy and you know it nod your head if you're happy and you know it Nod your head if you're happy and you know it and you really want to show it if you're happy and you know it Nod your head play guitar, shake your legs reach a high Touch the ground, spin around.",
    story_id: 1719,
    word_count: 29,
    all_intersection_count: 4,
    new_words_count: 25,
  },
  {
    row_new_words: [
      "open",
      "shut",
      "them",
      "give",
      "clap",
      "put",
      "your",
      "lap",
      "small",
      "please",
      "no",
      "thank",
      "you",
      "fast",
      "slow",
      "loud",
      "quiet",
      "sh",
      "peekabooo",
      "peek",
      "###aooo",
      "pea",
      "peeka",
      "peekabo",
      "peekaboo",
    ],
    words: [
      "open",
      "shut",
      "them",
      "give",
      "a",
      "little",
      "clap",
      "put",
      "in",
      "your",
      "lap",
      "big",
      "and",
      "small",
      "please",
      "no",
      "thank",
      "you",
      "fast",
      "slow",
      "loud",
      "quiet",
      "sh",
      "peekabooo",
      "peek",
      "###aooo",
      "pea",
      "peeka",
      "peekabo",
      "peekaboo",
    ],
    link: "hXxHonmYz6c",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Open shut them open shut them give a little clap clap clap Open shut them open shut them Put them in your lap lap lap Big and small big big and small big and small big big big big small small small big and small big and small big big big big small small small Please no, thank you Please no, thank you please no, thank you please please please please no thank you Please no thank thank you Please no thank you please please please please no thank you Fast and slow fast and slow fast and slow fast fast fast fast slow slow slow fast and slow fast and slow fast fast fast fast slow slow slow Loud loud and quiet loud and quiet loud and quiet Loud loud loud loud sh quiet loud and quiet loud and quiet Loud loud loud loud sh quiet peekabooo peek ###aooo peekabooo pea peeka Peekabooo Peekabo Peekabooo pea pea peekaboo.",
    story_id: 1721,
    word_count: 30,
    all_intersection_count: 5,
    new_words_count: 25,
  },
  {
    row_new_words: [
      "English",
      "sing",
      "color",
      "the",
      "blue",
      "purple",
      "make",
      "yellow",
      "orange",
      "luck",
      "black",
      "white",
      "gray",
      "brown",
      "we",
      "are",
      "living",
      "colorful",
      "world",
      "rainbow",
      "indigo",
      "violet",
      "these",
      "colors",
      "review",
      "mixing",
    ],
    words: [
      "English",
      "sing",
      "color",
      "red",
      "the",
      "is",
      "blue",
      "and",
      "purple",
      "make",
      "yellow",
      "green",
      "orange",
      "luck",
      "black",
      "white",
      "a",
      "pink",
      "gray",
      "brown",
      "we",
      "are",
      "living",
      "in",
      "colorful",
      "world",
      "rainbow",
      "indigo",
      "violet",
      "these",
      "colors",
      "review",
      "mixing",
    ],
    link: "ybt2jhCQ3lA",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "English Sing sing Color Red the color is red Blue the color is blue red and blue Purple red and blue make purple Yellow the color is yellow blue and yellow Green blue and yellow make green yellow and red Orange yellow and red make orange Red blue and yellow Luck Red, blue and yellow make black Red purple blue green yellow orange black Red the color is red White the color is white red and white A pink, red and white make pink Black the color is black white and black Gray white and black make gray Orange the color is orange black and orange Brown black and orange make brown red pink white gray black brown orange we are living in a colorful world Rainbow Red orange yellow green blue indigo violet Red orange yellow green blue indigo violet these are rainbow colors Review Color mixing Red blue purple blue yellow green yellow red orange red yellow blue black red white pink white black gray black orange brown Rainbow colors Red orange yellow green blue indigo violet rainbow",
    story_id: 1592,
    word_count: 33,
    all_intersection_count: 7,
    new_words_count: 26,
  },
  {
    row_new_words: [
      "the",
      "rabbits",
      "play",
      "no",
      "more",
      "birds",
      "are",
      "weary",
      "buttercups",
      "folded",
      "up",
      "good",
      "night",
      "my",
      "dearary",
      "children",
      "country",
      "city",
      "go",
      "with",
      "nodding",
      "head",
      "could",
      "pretty",
      "heads",
      "u",
    ],
    words: [
      "the",
      "rabbits",
      "play",
      "no",
      "more",
      "little",
      "birds",
      "are",
      "weary",
      "buttercups",
      "folded",
      "up",
      "good",
      "night",
      "my",
      "dearary",
      "children",
      "in",
      "country",
      "city",
      "go",
      "to",
      "bed",
      "with",
      "nodding",
      "head",
      "could",
      "not",
      "pretty",
      "heads",
      "u",
    ],
    link: "sTbmRXDzWa0",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "The rabbits play no more the little birds are weary the buttercups are folded up Good night, Good night, my dearary the children in the country the children in the city Go to bed with nodding head could not good Good night, my pretty rabbits play no more the little birds are weary the buttercups are folded up Good night, Good night, my dearary the children in the country the children in the city Go to bed with nodding heads Good night, Good night, my pretty U",
    story_id: 1675,
    word_count: 31,
    all_intersection_count: 5,
    new_words_count: 26,
  },
  {
    row_new_words: [
      "if",
      "you're",
      "happy",
      "clap",
      "your",
      "hands",
      "angry",
      "stomp",
      "feet",
      "stopomp",
      "scared",
      "say",
      "oh",
      "no",
      "sleepy",
      "take",
      "nap",
      "the",
      "ducks",
      "farm",
      "quack",
      "do",
      "you",
      "broccoli",
      "1",
      "2",
      "3",
    ],
    words: [
      "if",
      "you're",
      "happy",
      "clap",
      "your",
      "hands",
      "angry",
      "stomp",
      "feet",
      "stopomp",
      "scared",
      "say",
      "oh",
      "no",
      "sleepy",
      "take",
      "a",
      "nap",
      "the",
      "ducks",
      "on",
      "farm",
      "quack",
      "do",
      "you",
      "like",
      "broccoli",
      "1",
      "2",
      "3",
    ],
    link: "l4WNrvVjiTw",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "If you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands Clap your hands if you're happy, happy, happy Clap your hands if you're angry, angry, angry Stomp your feet if you're angry, angry, angry Stomp your feet if you're angry, angry, angry Stopomp your feet Stomp your feet if you're angry, angry, angry Stomp your feet if you're scared, scared, scared say oh no, oh no if you're scared, scared, scared say oh no, oh no if you're scared, scared, scared say oh no say oh no if you're scared, scared, scared say oh no, oh no if you're sleepy, sleepy, sleepy Take a nap if you're sleepy, sleepy, sleepy Take a nap if you're sleepy, sleepy, sleepy Take a nap, take a nap if you're sleepy, sleepy, sleepy Take a nap if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands, clap your hands if you're happy, happy, happy Clap your hands the ducks on the farm say quack, quack, quack. Do you like broccoli? 1, 2, 3.",
    story_id: 1711,
    word_count: 30,
    all_intersection_count: 3,
    new_words_count: 27,
  },
  {
    row_new_words: [
      "brush",
      "bus",
      "hey",
      "everybody",
      "it's",
      "the",
      "find",
      "your",
      "toothbrush",
      "toothpaste",
      "let's",
      "fun",
      "teeth",
      "all",
      "them",
      "now",
      "won't",
      "you",
      "show",
      "me",
      "how",
      "move",
      "round",
      "brushed",
      "at",
      "back",
      "yes",
    ],
    words: [
      "brush",
      "bus",
      "hey",
      "everybody",
      "it's",
      "the",
      "find",
      "your",
      "toothbrush",
      "and",
      "toothpaste",
      "let's",
      "have",
      "fun",
      "teeth",
      "all",
      "them",
      "now",
      "won't",
      "you",
      "show",
      "me",
      "how",
      "move",
      "round",
      "brushed",
      "at",
      "back",
      "yes",
    ],
    link: "https://learnenglishkids.britishcouncil.org/listen-watch/songs/brush-bus",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Brush bus\n\nHey everybody it’s the brush bus\nFind your toothbrush and your toothpaste\nLet’s have fun and brush.\n\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\n\nMove your brush round and round\nMove your brush round and round\nMove your brush round and round\nMove your brush round and round.\n\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\n\nAll brushed at the back, brush at the back\nAll brushed at the back, brush at the back\nAll brushed at the back, brush at the back\nAll brushed at the back, yes, you brush at the back.",
    story_id: 853,
    word_count: 29,
    all_intersection_count: 2,
    new_words_count: 27,
  },
  {
    row_new_words: [
      "happy",
      "birthday",
      "you",
      "find",
      "out",
      "why",
      "over",
      "million",
      "parents",
      "used",
      "goodandbeautifulbooklistcom",
      "clean",
      "appropriate",
      "books",
      "check",
      "the",
      "free",
      "book",
      "list",
      "now",
      "dive",
      "into",
      "world",
      "of",
      "good",
      "beautiful",
      "literature",
    ],
    words: [
      "happy",
      "birthday",
      "to",
      "you",
      "find",
      "out",
      "why",
      "over",
      "a",
      "million",
      "parents",
      "have",
      "used",
      "goodandbeautifulbooklistcom",
      "clean",
      "appropriate",
      "books",
      "check",
      "the",
      "free",
      "book",
      "list",
      "now",
      "and",
      "dive",
      "into",
      "world",
      "of",
      "good",
      "beautiful",
      "literature",
    ],
    link: "HwTg7KHuRQQ",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Happy birthday to you Happy birthday to you Happy birthday, happy birthday, Happy birthday to you Happy birthday to you Happy birthday to you Happy birthday, happy birthday, Happy birthday to you Find out why over a million parents have used goodandbeautifulbooklist.com to find clean, appropriate books. Check out the free book list now and dive into the world of good and beautiful literature.",
    story_id: 1613,
    word_count: 31,
    all_intersection_count: 4,
    new_words_count: 27,
  },
  {
    row_new_words: [
      "chicks",
      "tucked",
      "their",
      "mother",
      "hen",
      "said",
      "it's",
      "rest",
      "one",
      "chick",
      "peep",
      "four",
      "stayed",
      "fast",
      "asleep",
      "two",
      "three",
      "but",
      "the",
      "that",
      "all",
      "of",
      "came",
      "back",
      "sleep",
      "none",
      "made",
    ],
    words: [
      "five",
      "little",
      "chicks",
      "tucked",
      "in",
      "their",
      "nest",
      "mother",
      "hen",
      "said",
      "it's",
      "time",
      "to",
      "rest",
      "one",
      "chick",
      "peep",
      "four",
      "stayed",
      "fast",
      "asleep",
      "two",
      "three",
      "but",
      "the",
      "that",
      "and",
      "all",
      "of",
      "came",
      "back",
      "sleep",
      "none",
      "made",
      "a",
    ],
    link: "FSZ9Wy0o4ww",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Five little chicks tucked in their nest Mother hen said, it's time to rest One little chick said peep, peep, peep. Four little chick stayed fast asleep. One, two, three, four. Four little chicks tucked in their nest Mother hen said, it's time to rest. One little chick said peep, peep, peep. But three little chicks stayed fast asleep. One, two, three. Three little chicks tucked in their nest Mother hen said, it's time to rest. One little chick said peep, peep, peep. The two little chicks stayed fast asleep One, two. Two little chicks tucked in their nest Mother hen said, it's time to rest One little chick said peep, peep, peep. But one little chick stayed fast asleep One One little chick tucked in their nest Mother hen said, it's time to rest that little chick said peep, peep, peep. And all of the chicks came back to sleep Five little chicks tucked in their nest Mother hen said, it's time to rest none of the little chicks made a peep and all of the chicks stayed fast asleep.",
    story_id: 1716,
    word_count: 35,
    all_intersection_count: 8,
    new_words_count: 27,
  },
  {
    row_new_words: [
      "500",
      "ducks",
      "went",
      "out",
      "one",
      "day",
      "over",
      "the",
      "hill",
      "far",
      "away",
      "mother",
      "said",
      "quack",
      "but",
      "only",
      "400",
      "came",
      "back",
      "1002-003004-00400",
      "duck",
      "300",
      "100",
      "200",
      "``ack",
      "all",
      "of",
      "quackack",
    ],
    words: [
      "500",
      "ducks",
      "went",
      "out",
      "one",
      "day",
      "over",
      "the",
      "hill",
      "and",
      "far",
      "away",
      "mother",
      "said",
      "quack",
      "but",
      "only",
      "400",
      "came",
      "back",
      "1002-003004-00400",
      "duck",
      "300",
      "100",
      "200",
      "``ack",
      "little",
      "all",
      "of",
      "quackack",
    ],
    link: "6VklRp158A4",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "500 ducks went out one day over the hill and far away the mother ducks said quack, quack quack quack but only 400 ducks came back 1002-003004-00400 ducks went out one day over the hill and far away the mother duck said quack quack quack quack but only 300 ducks came back 100, 200, 300, 300 ducks went out one day over the hill and far away the mother ducks said quack, quack, quack, quack but only 200 ducks came back 100, 200, 200 ducks went out one day over the hill and far away the mother ducks said quack quack quack quack but only 100 ducks came back 100, 100 ducks went out one day over the hill and far away the mother duck said ``ack quack quack quack quack but only one little duck came back one little duck went out one day over the hill and far away the mother duck said quack quack quack quack and all of the 500 ducks came back 500 ducks went out one day over the hill and far away the mother duck said quack, quack quack quack and all of the 500 ducks said quackack.",
    story_id: 1718,
    word_count: 30,
    all_intersection_count: 2,
    new_words_count: 28,
  },
  {
    row_new_words: [
      "spelled",
      "r",
      "e",
      "d",
      "the",
      "find",
      "out",
      "why",
      "over",
      "million",
      "parents",
      "used",
      "goodandbeautifulbooklistcom",
      "clean",
      "appropriate",
      "books",
      "check",
      "free",
      "book",
      "list",
      "now",
      "dive",
      "into",
      "world",
      "of",
      "good",
      "beautiful",
      "literature",
    ],
    words: [
      "red",
      "is",
      "spelled",
      "r",
      "e",
      "d",
      "apples",
      "on",
      "the",
      "apple",
      "tree",
      "find",
      "out",
      "why",
      "over",
      "a",
      "million",
      "parents",
      "have",
      "used",
      "goodandbeautifulbooklistcom",
      "to",
      "clean",
      "appropriate",
      "books",
      "check",
      "free",
      "book",
      "list",
      "now",
      "and",
      "dive",
      "into",
      "world",
      "of",
      "good",
      "beautiful",
      "literature",
    ],
    link: "cpcJqG99nPQ",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Red is spelled R E D. Red. Red apples on the apple tree. Red is spelled R E D. Red Red apples on the apple tree. Red is spelled R E D. Red Red apples on the apple tree. Find out why over a million parents have used goodandbeautifulbooklist.com to find clean, appropriate books. Check out the Free Book List now and dive into the world of good and beautiful literature.",
    story_id: 1614,
    word_count: 38,
    all_intersection_count: 10,
    new_words_count: 28,
  },
  {
    row_new_words: [
      "6",
      "times",
      "46",
      "4",
      "2424",
      "rain",
      "clouds",
      "waiting",
      "pour",
      "thunder",
      "lightning",
      "boom",
      "bank",
      "crash",
      "246",
      "crush",
      "four",
      "rainc",
      "ban",
      "3",
      "1212",
      "books",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
    ],
    words: [
      "6",
      "times",
      "46",
      "4",
      "is",
      "2424",
      "rain",
      "clouds",
      "waiting",
      "to",
      "pour",
      "thunder",
      "lightning",
      "boom",
      "bank",
      "crash",
      "246",
      "crush",
      "four",
      "rainc",
      "ban",
      "3",
      "1212",
      "books",
      "on",
      "a",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
    ],
    link: "Pnh4elnR6oU",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "6 times 46 times 4 is 2424 rain clouds waiting to pour Thunder Lightning boom bank crash 6 times 4 is 246 times 4 is 2424 rain clouds waiting to pour thunder lightning boom bank crush 6 times 4 is 246 times four is rainc clouds waiting to pour Thunder Lightning boom ban crash 6 times 4 is. 3 times 4 is 1212 books on a shelf I will read them all 3 times 4 is 1212 books ON A shelf I will read them all",
    story_id: 1666,
    word_count: 32,
    all_intersection_count: 4,
    new_words_count: 28,
  },
];

export default storiesData;
