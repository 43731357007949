import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import checkNewWords from "../../common/checkNewWords";
import getSentenceWords from "../../common/getSentenceWords";
import removePunctuationFromString from "../../common/removePunctuationFromString";
import supabaseClient from "../../common/supabaseClient";
import upperCaseWords from "../../common/uppercaseWords";
import useCreateAudio from "../../common/useCreateAudio";
import { useLessonsStore } from "../../store/lessonsStore";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import AdminCreateSentenceFields from "../AdminCreateSentenceFields";
import createNewNotExistingSentenceFromRowSentences from "../CreatePersonalLesson/createNewNotExistingSentenceFromRowSentences";
import getExistingAndNotExistingSentences from "../CreatePersonalLesson/getExistingAndNotExistingSentences";
import MenuLayout from "../Layout/MenuLayout";

const courseWords = {
  1: "I, speak, English, you, we, they, he, speaks, she, know, knows, do, does, don't, doesn't, study, in, school, studies, and, work, works, every, day, it, learn, learns, very, well, go, to, today",
};

const skipWords = ["/", "not"];

const AdminCreateBanglaGrammarCourse: React.FC = () => {
  const [text, setText] = useState("");
  const [topic, setTopic] = useState("");
  const [setnences, setSetnences] = useState<string[]>([]);
  const [newWords, setNewWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [words, setWords] = useState<string[]>([]);
  const { createAudio } = useCreateAudio();
  const [knowWordsInTheTopic, setKnowWordsInTheTopic] = useState<string[]>([]);
  const [newWordsInTheTopic, setNewWordsInTheTopic] = useState<string[]>([]);
  const [enValue, setEnValue] = useState("");
  const [module, setModule] = useState(0);
  const [uaValue, setUaValue] = useState("");
  const [meaningValue, setMeaningValue] = useState("");

  useEffect(() => {
    const allWords = getSentenceWords(enValue);
    console.log("🚀 ~ allWords:", allWords);
    const uniqueWords = Array.from(new Set(allWords)).filter(Boolean);
    console.log("🚀 ~ uniqueWords:", uniqueWords);
    setWords(uniqueWords);
  }, [enValue]);

  const authUser = useLessonsStore((state) => state.authUser);

  const getTextWords = (text: string) => {
    return Array.from(
      new Set(
        text
          .split(/[\s\n]+/)
          .map((word) => {
            if (upperCaseWords.includes(word)) {
              return removePunctuationFromString(word);
            }
            return removePunctuationFromString(word).toLowerCase();
          })
          .filter(Boolean),
      ),
    );
  };

  return (
    <MenuLayout>
      <Box p={5} sx={{ backgroundColor: "white" }}>
        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              Words count: {words.length}
            </Grid>

            <Grid item xs={12}>
              <Typography>Copy all course words</Typography>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="info"
                onClick={async () => {
                  setIsLoading(true);
                  const { data } = await supabaseClient
                    .from("user-words")
                    .select("word")
                    .eq("user", "7dd6fd1b-a333-4fbf-98a7-245491416070");

                  if (data) {
                    navigator.clipboard.writeText(data.map((i) => i.word).join(", "));
                  }

                  setNewWords(newWords);
                  setIsLoading(false);
                }}
              >
                Copy
              </LoadingButton>
            </Grid>

            <br />
            {!!newWords.length && <CopyNewWordsForGemini words={newWords} />}
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="info"
              onClick={async () => {
                setIsLoading(true);
                const { words: returnWords, newWords } = await checkNewWords(words);
                console.log("🚀 ~ newWords:", newWords);
                console.log("🚀 ~ returnWords:", returnWords);

                setNewWords(newWords);
                setIsLoading(false);
              }}
            >
              Check new words
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              variant="contained"
              onClick={async () => {
                setIsLoading(true);
                console.log(words);
                await Promise.all(
                  words.map((word) =>
                    supabaseClient.from("user-words").insert({
                      id: `7dd6fd1b-a333-4fbf-98a7-245491416070-${word}`,
                      user: "7dd6fd1b-a333-4fbf-98a7-245491416070",
                      word,
                    }),
                  ),
                );

                setIsLoading(false);
              }}
            >
              Upload new word to grammar user
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              variant="contained"
              onClick={async () => {
                try {
                  const userUuid = authUser?.uuid;

                  if (!userUuid) return;

                  setIsLoading(true);

                  const rowEnSentences = enValue
                    .split("\n")
                    .map((s) => s.trim())
                    .filter(Boolean);

                  const { notExistingSentences } = await getExistingAndNotExistingSentences({
                    currentUserUuid: userUuid,
                    textFieldMultipleString: enValue,
                    textFiledTranslationMultipleString: uaValue,
                    meanings: meaningValue,
                  });

                  console.log("notExistingSentences", notExistingSentences);

                  const sentencesToInsert = await createNewNotExistingSentenceFromRowSentences({
                    notExistingSentences: notExistingSentences,
                    createAudio,
                  });

                  console.log("sentencesToInsert", sentencesToInsert);

                  // try {
                  // } catch (err) {
                  //   console.log("🚀 ~ err:", err);
                  // }
                  const uploadNewSetnencesResult = await Promise.all(
                    sentencesToInsert.map((s) => supabaseClient.from("sentences").insert(s)),
                  );
                  console.log("🚀 ~ uploadNewSetnencesResult:", uploadNewSetnencesResult);
                  // }

                  await Promise.all(
                    rowEnSentences.map((sentence, index) =>
                      supabaseClient.from("grammar_course_sentences").insert({
                        id: sentence,
                        sentence,
                        module: module,
                        order: index,
                      }),
                    ),
                  );

                  await supabaseClient
                    .from("grammar_course")
                    .insert({
                      module: module,
                      sentence_count: rowEnSentences.length,
                      title: `Урок ${module}`,
                    })
                    .throwOnError();
                } catch (err) {
                  console.log("🚀 ~ err:", err);
                }

                setIsLoading(false);
              }}
            >
              Upload sentences
            </LoadingButton>
          </Grid>
          <Grid item xs={12} gap={4} display="flex" flexWrap="wrap">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(`
Translate the following text to Ukrainian language. The response is a list of Ukrainian language strings without quotes at the beginning and at the end. Each translated sentence starts on the new line.
${enValue}`);
              }}
            >
              Copy for gemini translation
            </Button>
            <TextField
              color="primary"
              value={module}
              multiline={true}
              placeholder="Module"
              onChange={(event) => {
                const module = event.target.value;

                setModule(+module);
              }}
              type="text"
              variant="outlined"
            />
            <AdminCreateSentenceFields
              columnSize={400}
              onUpdateValues={(en, ua, meaning) => {
                setEnValue(en);
                setUaValue(ua);
                setMeaningValue(meaning);
              }}
            />
            {/* <TextField
              fullWidth
              color="primary"
              value={text}
              multiline={true}
              placeholder="English sentences"
              onChange={(event) => {
                const text = event.target.value;

                const sentences = text
                  .split(/[\n]+/)
                  .map((sentence) => sentence.trim())
                  .filter(Boolean);

                setSetnences(sentences);

                const words = getTextWords(text);

                setWords(Array.from(new Set(words)));
                setText(text);
              }}
              type="text"
              variant="outlined"
            /> */}
          </Grid>
          {/* <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            value={topic}
            multiline={true}
            placeholder="Topic"
            onChange={(event) => {
              setTopic(event.target.value);

              const cWords = Object.values(courseWords)
                .map((words) => words.split(", "))
                .flat();
              console.log("🚀 ~ cWords:", cWords);
              const topicWords = getTextWords(topic);
              console.log("🚀 ~ topicWords:", topicWords);
              setKnowWordsInTheTopic(topicWords.filter((w) => cWords.includes(w)));
              setNewWordsInTheTopic(topicWords.filter((w) => !cWords.includes(w)));
            }}
            type="text"
            variant="outlined"
          />
        </Grid>
        {!!knowWordsInTheTopic.length && (
          <Grid item xs={12}>
            Topic known words count: {knowWordsInTheTopic.length}
          </Grid>
        )}
        {!!newWordsInTheTopic.length && (
          <Grid item xs={12}>
            Topic new words count: {newWordsInTheTopic.length}
          </Grid>
        )}
        {!!newWordsInTheTopic.length && (
          <Grid item xs={12}>
            New words:{" "}
            {newWordsInTheTopic.map((w) => (
              <Typography p={1}>{w}</Typography>
            ))}
          </Grid>
        )} */}
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminCreateBanglaGrammarCourse;
