import { Box, Button, Typography } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";

import ModalScheme from "../../../ModalScheme";
import { useWindowSize } from "react-use";
import removePunctuationFromString from "../../../../common/removePunctuationFromString";
import VStack from "../../../Unknown/VStack";
import LinearProgress from "../../../User/LinearProgress";
import AccentWord from "../../../Difference/components/AccentWord";
import AccentString, {
  AccentType,
} from "../../../Description/ModuleDescription/utils/AccentString";

const SimpleCarousel: React.FC<{ elements: any }> = ({ elements }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentElement = useMemo(() => {
    return elements[currentIndex];
  }, [elements, currentIndex]);

  if (currentIndex === elements.length) {
    return (
      <Box>
        <Box py={5}>
          <Typography textAlign="center" variant="h3">
            На цьому все
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            setCurrentIndex(0);
          }}
        >
          Почати з початку
        </Button>
      </Box>
    );
  }

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <LinearProgress height={5} currentValue={currentIndex} elementsCount={elements.length - 1} />
      <Box mb={5} mt={4}>
        <Typography variant="h3">{currentElement}</Typography>
      </Box>
      <Button variant="outlined" onClick={() => setCurrentIndex((prev) => prev + 1)}>
        Далі
      </Button>
    </Box>
  );
};

export const UaDifferenceBetweenVoicedAndUnVoicedLetters: React.FC = () => {
  return (
    <Box>
      <Box mb={5}>
        <Typography gutterBottom>Глухі приголосні: c, т, п, ц, к, ш </Typography>
        <Typography gutterBottom>Дзвінкі приголосні: з, д, б, дз, ґ, ж</Typography>
      </Box>
      <Box mb={5}>
        <Typography gutterBottom variant="h3">
          Глухі та дзвінкі приголосні утворюють пари:
        </Typography>
        <Typography gutterBottom>
          c → з,
          <br />
          т → д,
          <br />
          п → б,
          <br />
          ц → дз,
          <br />
          к → ґ,
          <br />ш → ж
        </Typography>
      </Box>
      <VStack>
        <SimpleCarousel
          elements={[
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з у префіксах
              </Typography>
              <Typography gutterBottom variant="h3">
                <AccentString text="з" type={[AccentType.goal]} />
                казати → <AccentString text="с" type={[AccentType.goal]} />
                казати
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з у префіксах
              </Typography>
              <Typography gutterBottom variant="h3">
                <AccentString text="з" type={[AccentType.goal]} />
                фотографувати → <AccentString text="с" type={[AccentType.goal]} />
                фотографувати
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з у префіксах
              </Typography>
              <Typography gutterBottom variant="h3">
                <AccentString text="з" type={[AccentType.goal]} />
                питати → <AccentString text="с" type={[AccentType.goal]} />
                питати
              </Typography>
            </VStack>,

            <VStack>
              <Typography gutterBottom variant="caption">
                с → з у префіксах
              </Typography>
              <Typography gutterBottom variant="h3">
                <AccentString text="з" type={[AccentType.goal]} />
                терти → <AccentString text="с" type={[AccentType.goal]} />
                терти
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з у префіксах
              </Typography>
              <Typography gutterBottom variant="h3">
                <AccentString text="з" type={[AccentType.goal]} />
                хотіти → <AccentString text="с" type={[AccentType.goal]} />
                хотіти
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                про
                <AccentString text="c" type={[AccentType.goal]} />
                ьба → про
                <AccentString text="з" type={[AccentType.goal]} />
                ьба
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                фей
                <AccentString text="c" type={[AccentType.goal]} />
                бук → фей
                <AccentString text="з" type={[AccentType.goal]} />
                бук
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                бей
                <AccentString text="c" type={[AccentType.goal]} />
                бол → бей
                <AccentString text="з" type={[AccentType.goal]} />
                бол
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                ро
                <AccentString text="з" type={[AccentType.goal]} />
                клад → ро
                <AccentString text="с" type={[AccentType.goal]} />
                клад
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                прика
                <AccentString text="з" type={[AccentType.goal]} />
                ка → прика
                <AccentString text="с" type={[AccentType.goal]} />
                ка
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                бе
                <AccentString text="з" type={[AccentType.goal]} />
                хмарний → бе
                <AccentString text="с" type={[AccentType.goal]} />
                хмарний
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                с → з перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                ука
                <AccentString text="з" type={[AccentType.goal]} />
                ка → ука
                <AccentString text="с" type={[AccentType.goal]} />
                ка
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                боро
                <AccentString text="т" type={[AccentType.goal]} />
                ьба → боро
                <AccentString text="д" type={[AccentType.goal]} />
                ьба
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                фу
                <AccentString text="т" type={[AccentType.goal]} />
                бол → фу
                <AccentString text="д" type={[AccentType.goal]} />
                бол
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                фу
                <AccentString text="т" type={[AccentType.goal]} />
                болка → фу
                <AccentString text="д" type={[AccentType.goal]} />
                болка
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                баске
                <AccentString text="т" type={[AccentType.goal]} />
                бол → баске
                <AccentString text="д" type={[AccentType.goal]} />
                бол
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                ноу
                <AccentString text="т" type={[AccentType.goal]} />
                бук → ноу
                <AccentString text="д" type={[AccentType.goal]} />
                бук
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                о
                <AccentString text="т" type={[AccentType.goal]} />
                же → о
                <AccentString text="д" type={[AccentType.goal]} />
                же
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                зві
                <AccentString text="д" type={[AccentType.goal]} />
                ки → зві
                <AccentString text="т" type={[AccentType.goal]} />
                ки
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                шви
                <AccentString text="д" type={[AccentType.goal]} />
                ко → шви
                <AccentString text="т" type={[AccentType.goal]} />
                ко
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                шви
                <AccentString text="д" type={[AccentType.goal]} />
                кий → шви
                <AccentString text="т" type={[AccentType.goal]} />
                кий
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                д - т перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                ві
                <AccentString text="д" type={[AccentType.goal]} />
                почивати → ві
                <AccentString text="т" type={[AccentType.goal]} />
                почивати
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                б - п перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                ри
                <AccentString text="б" type={[AccentType.goal]} />
                ка → ри
                <AccentString text="п" type={[AccentType.goal]} />
                ка
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                б - п перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                коро
                <AccentString text="б" type={[AccentType.goal]} />
                ка → коро
                <AccentString text="п" type={[AccentType.goal]} />
                ка
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                б - п перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                гу
                <AccentString text="б" type={[AccentType.goal]} />
                ка → гу
                <AccentString text="п" type={[AccentType.goal]} />
                ка
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                г - х перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                ле
                <AccentString text="г" type={[AccentType.goal]} />
                ко → ле
                <AccentString text="х" type={[AccentType.goal]} />
                ко
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                г - х перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                стри
                <AccentString text="г" type={[AccentType.goal]} />
                ти → стри
                <AccentString text="х" type={[AccentType.goal]} />
                ти
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                г - х перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                бi
                <AccentString text="г" type={[AccentType.goal]} />
                ти → бі
                <AccentString text="х" type={[AccentType.goal]} />
                ти
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                ж - ш перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                лі
                <AccentString text="ж" type={[AccentType.goal]} />
                ко → лі
                <AccentString text="ш" type={[AccentType.goal]} />
                ко
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                ж - ш перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                тя
                <AccentString text="ж" type={[AccentType.goal]} />
                ко → тя
                <AccentString text="ш" type={[AccentType.goal]} />
                ко
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                ж - ш перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                сте
                <AccentString text="ж" type={[AccentType.goal]} />
                ка → сте
                <AccentString text="ш" type={[AccentType.goal]} />
                ка
              </Typography>
            </VStack>,
            <VStack>
              <Typography gutterBottom variant="caption">
                ж - ш перед глухою/дзвінкою приголосною
              </Typography>
              <Typography gutterBottom variant="h3">
                кни
                <AccentString text="ж" type={[AccentType.goal]} />
                ка → кни
                <AccentString text="ш" type={[AccentType.goal]} />
                ка
              </Typography>
            </VStack>,
          ]}
        />
      </VStack>
    </Box>
  );
};

// const Word: React.FC<{ word: string; nouns: string[] }> = ({ word, nouns }) => {
//   const [color, setColor] = useState("black");
//   const cleanWord = removePunctuationFromString(word);

//   return (
//     <Typography
//       fontSize={20}
//       color={color}
//       component="span"
//       sx={{ display: "inline-block", mr: 1.5, cursor: "pointer" }}
//       onClick={() => {
//         if (nouns.includes(cleanWord.toLowerCase())) {
//           setColor("green");
//         } else {
//           setColor("red");
//         }
//       }}
//     >
//       {word}
//     </Typography>
//   );
// };
