import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { useLessonsStore } from "../../../../store/lessonsStore";

const usePageBeforeAuthNavigate = () => {
  const session = useLessonsStore((state) => state.session);
  const navigate = useNavigate();
  const location = useLocation();
  const [externalPrevAuthPage, setExternalPrevAuthPage, removeExternalPrevAuthPage] =
    useLocalStorage<string | null>("external-prev-auth-page", null);
  const authUser = useLessonsStore((state) => state.authUser);

  useEffect(() => {
    const tiemout = setTimeout(() => {
      if (!session && location.pathname !== "/") {
        // http://localhost:3000/difference/it-this
        setExternalPrevAuthPage(location.pathname);
        navigate("/");
      }
    }, 1000);
    return () => {
      clearTimeout(tiemout);
    };
  }, [session, location.pathname]);

  useEffect(() => {
    if (externalPrevAuthPage && authUser && session) {
      removeExternalPrevAuthPage();
      navigate(externalPrevAuthPage);
    }
  }, [authUser, session, externalPrevAuthPage]);
};

export default usePageBeforeAuthNavigate;
