import { Box, Button, Typography } from "@mui/material";
import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import ModalScheme from "../../../ModalScheme";
import { useWindowSize } from "react-use";
import removePunctuationFromString from "../../../../common/removePunctuationFromString";
import ItalicTitle from "../../../Title/ItalicTitle";
import Quote from "../../../Unknown/Quote";
import VStack from "../../../Unknown/VStack";
import HStack from "../../../Unknown/HStack";
import LinearProgress from "../../../User/LinearProgress";

export const UaThisIt: React.FC = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  return (
    <Box>
      <Box mb={2}>
        <Typography gutterBottom>
          <strong>Займенники</strong> — це слова, які використовуються замість іменників, щоб не
          повторювати їх у реченні або тексті.
        </Typography>
        <ItalicTitle text="Наприклад:" />, &nbsp;
        <Typography gutterBottom>
          <strong>Моя подруга</strong> дуже добра. <strong>Моя подруга</strong> любить грати у
          футбол.
        </Typography>
        <Typography gutterBottom>
          <strong>Моя подруга</strong> дуже добра. <strong style={{ color: "green" }}>Вона</strong>{" "}
          любить грати у футбол.
        </Typography>
        <br />
        <ItalicTitle text="Особові займенники:" />, &nbsp;
        <Typography gutterBottom>я, він, вона, воно, вони, ти, ви, ми</Typography>
        <br />
        <ItalicTitle text="Вказівні займенники:" />, &nbsp;
        <Typography gutterBottom>цей, ця, це, ці, той, та, те, ті</Typography>
        <br />
      </Box>
    </Box>
  );
};
