import { CSS } from "@dnd-kit/utilities";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
// externals
import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as SvgBinIcon } from "./bin.svg";
import { ReactComponent as SvgEraserIcon } from "./eraser.svg";

// dnd-kit
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  UniqueIdentifier,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import { rectIntersection } from "@dnd-kit/core";
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import HStack from "../../Unknown/HStack";
import Box from "../../Unknown/Box";
import { Button, Typography } from "@mui/material";
import { useWindowSize } from "react-use";
import VStack from "../../Unknown/VStack";

const redColor = "#b10d0d";

const getLetterById = (id: any, vowels: ItemType[], consonants: ItemType[]) => {
  let vowelLetter = "";
  const vowelsIds = vowels.map((i) => i.id);
  const consonantsIds = consonants.map((i) => i.id);
  if (vowelsIds.includes(id)) {
    vowelLetter = vowels.find((i) => i.id === id)?.letter || "";
  } else if (consonantsIds.includes(id)) {
    vowelLetter = consonants.find((i) => i.id === id)?.letter || "";
  }

  return vowelLetter;
};

const LetterButton: React.FC<
  PropsWithChildren & {
    sx?: any;
    onClick?: () => void;
    isSlim?: boolean;
    isKeyBoard?: boolean;
    isVisible?: boolean;
  }
> = ({ children, sx, isKeyBoard, isVisible, isSlim, onClick }) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const buttonSize = useMemo(() => {
    return Math.floor(windowWidth / 12) + 5;
  }, [windowWidth]);

  const [color, setColor] = useState("#010231");

  useEffect(() => {
    let timer: any = null;
    if (color === redColor && onClick) {
      timer = setTimeout(() => {
        setColor("#010231");
      }, 200);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [color, onClick]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        width: isSlim ? "auto" : buttonSize,
        height: buttonSize,
        // backgroundColor: "red",
        // color: "#010231",
        paddingLeft: isSlim ? 1 : 0,
        paddingRight: isSlim ? 1 : 0,
        color: color || "#010231",
        fontFamily: "Rubik",
        // border: "1px solid black",
        // overflow: "hidden",
        position: "relative",
        cursor: "pointer",
        // lineHeight: 0,
        // boxShadow: boxShadow ? "3px 3px 0px #4aa805" : "none",
        fontSize: isKeyBoard
          ? Math.floor(windowWidth * 0.06)
          : Math.ceil(buttonSize * (isSlim ? 1.5 : 0.8)),

        // borderRadius: "2px",
        ...(isVisible === false ? { color: "gray", opacity: 0.4, pointerEvents: "none" } : {}),
        ...(sx ? sx : {}),
        ...(typeof children === "string" && children === " "
          ? {
              paddingLeft: 2,
              paddingRight: 2,
            }
          : {}),
      }}
      onClick={() => {
        if (onClick) {
          setColor(redColor);
          onClick();
        }
      }}
    >
      {children}
    </Box>
  );
};

interface SortableItemProps {
  id: string | number;
  letter: string;
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, letter }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: (CSS as any).Transform?.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    // backgroundColor: isDragging ? "red" : "transparent",
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <LetterButton sx={{ color: isDragging ? "red" : "#010231" }} isSlim>
        {letter}
      </LetterButton>
    </Box>
  );
};

const Trash: React.FC<{ active: boolean }> = ({ active }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: "trash",
  });

  return (
    <Box
      sx={{
        color: isOver && active ? redColor : "grey",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 100,
        position: "relative",
        height: 150,
        overflow: "hidden",
        py: 2,
      }}
      ref={setNodeRef}
    >
      <Box
        position="absolute"
        sx={{
          zIndex: 1,
          top: 10,
          left: 0,
          width: "100%",
          height: 20,
          overflow: "hidden",
          transformOrigin: "0 0",
          transition: "ease transform 0.3s",
          transform: isOver
            ? "translate(-8%, 31%) rotate(-12deg)"
            : "translate(0%, 0%) rotate(0deg)",
        }}
      >
        <SvgBinIcon
          style={{ width: "100px", height: 90, fill: isOver && active ? redColor : "grey" }}
        />
      </Box>
      <Box
        position="absolute"
        sx={{
          zIndex: 1,
          top: 30,
          left: 0,
          width: "100%",
          height: 120,
          overflow: "hidden",
          // transformOrigin: "0 0",
          // transition: "ease transform 0.3s",
          // transform: isOver
          //   ? "translate(-8%, 31%) rotate(-12deg)"
          //   : "translate(0%, 0%) rotate(0deg)",
        }}
      >
        <SvgBinIcon
          style={{
            width: "100px",
            position: "relative",
            top: -20,
            height: 90,
            fill: isOver && active ? redColor : "grey",
          }}
        />
      </Box>
      <SvgBinIcon style={{ opacity: 0, fill: isOver && active ? redColor : "grey" }} />
      <Box mt={1}>
        <Typography variant="h3" fontSize={30}>
          bin
        </Typography>
      </Box>
    </Box>
  );
};
const Eraser: React.FC<{ onErase: () => void }> = ({ onErase }) => {
  // const { setNodeRef, isOver } = useDroppable({
  //   id: "trash",
  // });

  const [color, setColor] = useState(redColor);

  useEffect(() => {
    let timer: any = null;
    if (color === "gray") {
      timer = setTimeout(() => {
        setColor(redColor);
      }, 200);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [color]);
  return (
    <Box
      sx={{
        // color: isOver && active ? "red" : "grey",

        display: "flex",
        // flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 100,
        height: 100,
        py: 2,
        cursor: "pointer",
      }}
      onClick={() => {
        setColor("gray");
        onErase();
      }}
    >
      <SvgEraserIcon style={{ width: 90, transition: "ease fill 0.2s" }} fill={color} />
      {/* <Box mt={1}>
        <Typography variant="h3">eraser</Typography>
      </Box> */}
    </Box>
  );
};

const DropZone: React.FC<{ children: ReactNode; id: UniqueIdentifier }> = ({ children, id }) => {
  const { setNodeRef } = useDroppable({ id });

  return <Box ref={setNodeRef}>{children}</Box>;
};

interface DraggableItemProps {
  id: string | number;
  letter?: string;
}
const DraggableItem: React.FC<DraggableItemProps> = ({ id, letter }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
  });

  const style = {};

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <LetterButton>{letter}</LetterButton>
    </div>
  );
};

interface ColorPalletteProps {
  items: ItemType[];
  width?: string;
}

const ColorPallette: React.FC<ColorPalletteProps> = ({ items, width }) => {
  const { setNodeRef } = useDroppable({ id: "pallette" });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        position: "relative",
        width: "100%",
        minHeight: "80px",
        display: "flex",
        flexWrap: "wrap",
        padding: 3,
        // backgroundColor: "green",
        border: "1px dashed black",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.1,
      }}
    >
      {items.map(({ id, letter }) => (
        <SortableItem key={id} letter={letter} id={id} />
      ))}
    </Box>
  );
};

export interface ItemType {
  id: UniqueIdentifier;
  letter: string;
}

const Test: React.FC<{ letters: string[] }> = ({ letters }) => {
  const [vowels, setVowels] = useState<ItemType[]>(
    ["a", "e", "i", "o", "u", "y"].map((letter) => ({ id: uuidv4(), letter })),
  );
  const [consonants, setConsonants] = useState<ItemType[]>(
    [
      "b",
      "c",
      "d",
      "f",
      "g",
      "h",
      "j",
      "k",
      "l",
      "m",
      "n",
      "p",
      "q",
      "r",
      "s",
      "t",
      "v",
      "w",
      "y",
      "z",
    ].map((letter) => ({ id: uuidv4(), letter })),
  );

  const [palletteItems, setPalletteItems] = useState<ItemType[]>(() =>
    [].map((letter) => ({ id: uuidv4(), letter })),
  );

  const [activeItem, setActiveItem] = useState<ItemType | null>(null);
  const [activeItemOrigin, setActiveItemOrigin] = useState<string | null>(null);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    console.log("o/a", over, active);
    updateVowels();
    updateConsonants();
    if (!over) {
      setActiveItem(null);
      setActiveItemOrigin(null);
      return;
    }

    if (over.id === "trash") {
      if (palletteItems.find((item) => item.id === active.id)) {
        setPalletteItems(palletteItems.filter((item) => item.id !== active.id));
      }
    }
    // else if (over.id === active.id) {
    // } else if (over.id === "pallette") {
    // }
    setActiveItem(null);
    setActiveItemOrigin(null);
  };

  const getItem = (id: UniqueIdentifier) => {
    const letter = getLetterById(id, vowels, consonants);

    if (letter) {
      return { id, letter };
    }
    // if (id === pickerId) return { id: pickerId };
    // if (vowels.map((i) => i.id).includes(id)) {
    //   return { id };
    // }
    return palletteItems.find((x) => x.id === id)!;
  };

  const updateVowels = () => {
    setVowels((prev) => prev.map((i) => ({ ...i, id: uuidv4() })));
  };
  const updateConsonants = () => {
    setConsonants((prev) => prev.map((i) => ({ ...i, id: uuidv4() })));
  };

  const onDragOver = ({ active, over }: DragOverEvent) => {
    if (!over) {
      if (activeItemOrigin === null) return;
      const indx = palletteItems.findIndex((x) => x.id === active.id);
      if (indx === -1) return;
      setPalletteItems(palletteItems.filter((x) => x.id !== active.id));
      // if (activeItemOrigin === "current") setPickerId(active.id);
      return;
    }

    if (over.id === "current" && activeItemOrigin !== null) {
      // we're not dragging over the pallette, so we may need to remove the item from the pallette
      const active_indx = palletteItems.findIndex((x) => x.id === active.id);
      if (active_indx === -1) return;
      setPalletteItems(palletteItems.filter((x) => x.id !== active.id));

      return;
    }

    const active_indx = palletteItems.findIndex((x) => x.id === active.id);
    const over_indx = palletteItems.findIndex((x) => x.id === over.id);

    if (active_indx !== -1 && over_indx !== -1) {
      if (active_indx === over_indx) return;
      setPalletteItems(arrayMove(palletteItems, active_indx, over_indx));
    } else if (over.id === "pallette" || active.id) {
      if (palletteItems.findIndex((x) => x.id === active.id) === -1) {
        const letter = getLetterById(active.id, vowels, consonants);
        if (letter) {
          setPalletteItems([...palletteItems, { id: active.id, letter }]);
        }
      }
    }
  };

  return (
    <Box pt={5} pb={20}>
      <DndContext
        onDragStart={({ active }) => {
          const letter = getLetterById(active.id, vowels, consonants);
          if (letter) setActiveItemOrigin("current");
          setActiveItem(getItem(active.id));
        }}
        onDragOver={onDragOver}
        onDragCancel={() => {
          setActiveItem(null);
          setActiveItemOrigin(null);
        }}
        onDragEnd={handleDragEnd}
        collisionDetection={rectIntersection}
      >
        <HStack alignItems="center" justifyContent="center">
          <Trash active={activeItemOrigin === null} />
          <Eraser
            onErase={() => {
              setPalletteItems([]);
            }}
          />
        </HStack>
        <ColorPallette items={palletteItems} />
        <DropZone id="current">
          {/* <Box>
            <KeyboardLine>
              <KeyBoardLetter letter="q" upperCaseLetter="Q" isVisible={letters.includes("q")} />
              <KeyBoardLetter letter="w" upperCaseLetter="W" isVisible={letters.includes("w")} />
              <KeyBoardLetter letter="e" upperCaseLetter="E" isVisible={letters.includes("e")} />
              <KeyBoardLetter letter="r" upperCaseLetter="R" isVisible={letters.includes("r")} />
              <KeyBoardLetter letter="t" upperCaseLetter="T" isVisible={letters.includes("t")} />
              <KeyBoardLetter letter="y" upperCaseLetter="Y" isVisible={letters.includes("y")} />
              <KeyBoardLetter letter="u" upperCaseLetter="U" isVisible={letters.includes("u")} />
              <KeyBoardLetter letter="i" upperCaseLetter="I" isVisible={letters.includes("i")} />
              <KeyBoardLetter letter="o" upperCaseLetter="O" isVisible={letters.includes("o")} />
              <KeyBoardLetter letter="p" upperCaseLetter="P" isVisible={letters.includes("p")} />
            </KeyboardLine>
          </Box> */}
          <HStack gap={0.5} justifyContent="center">
            {vowels
              .filter((l) => letters.includes(l.letter))
              .map((item) => (
                // <DraggableItem key={item.letter} id={item.id} letter={item.letter} />
                <LetterButton
                  key={item.letter}
                  onClick={() => {
                    setPalletteItems((prev) => [...prev, { id: uuidv4(), letter: item.letter }]);
                  }}
                >
                  {item.letter}
                </LetterButton>
              ))}
            {/* {vowels
              .filter((l) => letters.includes(l.letter))
              .map((item) => (
                <DraggableItem key={item.letter} id={item.id} letter={item.letter} />
              ))} */}
          </HStack>
          <HStack gap={0.5} justifyContent="center">
            {consonants
              .filter((l) => letters.includes(l.letter))
              .map((item) => (
                <LetterButton
                  key={item.letter}
                  onClick={() => {
                    setPalletteItems((prev) => [...prev, { id: uuidv4(), letter: item.letter }]);
                  }}
                >
                  {item.letter}
                </LetterButton>
              ))}
            {/* {consonants
              .filter((l) => letters.includes(l.letter))
              .map((item) => (
                <DraggableItem key={item.letter} id={item.id} letter={item.letter} />
              ))} */}
          </HStack>
          <HStack gap={0.5} justifyContent="center">
            <LetterButton
              // key={'_'}
              onClick={() => {
                setPalletteItems((prev) => [...prev, { id: uuidv4(), letter: " " }]);
              }}
            >
              _
            </LetterButton>
          </HStack>
        </DropZone>

        {/* The Drag Overlay is always rendered but the children are
      conditionally rendered based on the active item. */}
        <DragOverlay>
          {activeItem ? <LetterButton isSlim>{activeItem.letter}</LetterButton> : null}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};

const KeyBoardLetter: React.FC<
  PropsWithChildren & { letter: string; upperCaseLetter: string; isVisible: boolean }
> = ({ letter, isVisible, upperCaseLetter }) => {
  return (
    <LetterButton isKeyBoard isSlim isVisible={isVisible}>
      {letter}
    </LetterButton>
  );
};
const KeyboardLine: React.FC<PropsWithChildren & {}> = ({ children }) => {
  return <HStack justifyContent="center">{children}</HStack>;
};

export default Test;
