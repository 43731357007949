import { format } from "date-fns";
import { useEffect } from "react";
import { useLessonsStore } from "../../../../store/lessonsStore";

const usePageReloadOnHomeWorkOldDate = () => {
  const homeWork = useLessonsStore((state) => state.homeWork);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);

  // if it is 00:00, reload page to update homework
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = format(new Date(), "yyyy-MM-dd");
      // console.log("🚀 ~ currentDate:", currentDate);
      // console.log("🚀 ~ homeWork:", homeWork.currentDate);
      if (homeWork.currentDate !== currentDate) {
        window.location.reload();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [homeWork]);

  useEffect(() => {
    const currentDate = format(new Date(), "yyyy-MM-dd");

    updateHomeWork({ currentDate });
  }, []);
};

export default usePageReloadOnHomeWorkOldDate;
