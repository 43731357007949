import { useEffect } from "react";
import supabaseClient from "../../../../../common/supabaseClient";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import { RPCUser } from "../../../../../common/fetchCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";

const useUserUpdate = () => {
  const setRPCUser = useLessonsStore((state) => state.setRPCUser);
  const authUser = useLessonsStore((state) => state.authUser);
  const setSession = useLessonsStore((state) => state.setSession);
  const session = useLessonsStore((state) => state.session);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const channel = supabaseClient
      .channel("room8")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "users" },
        async (payload) => {
          // console.log("🚀 ~ payload:", payload);
          if (payload.new.id === authUser?.id) {
            setRPCUser(payload.new as RPCUser);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const newSS = await supabaseClient.auth.getSession();
      console.log("🚀------------- ~ newSS:", newSS);
      const {
        data: { session: newSession },
      } = await supabaseClient.auth.getSession();
      if (!session && newSession) {
        console.log("🚀 ~ newSession:", newSession);
        const { data: userData, error } = await supabaseClient.rpc("get_user", {
          userprop: newSession.user.email,
        });

        if (!error) {
          setSession(newSession);
          if (!authUser) {
            const { data: rpcUser } = await supabaseClient.rpc("create_user_google_auth");
            console.log("🚀 ~ rpcUser:", rpcUser);
            setRPCUser(rpcUser);
            // if (location.pathname === "/" && mounted) {
            //   navigate("/1");
            //   navigate("/");
            // }
          }
        }
      } else if (session && !newSession) {
        // setSession(null);
        // navigate("/");
      }
    })();
    return () => {
      mounted = false;
    };
  });
};

export default useUserUpdate;
