import { useNavigate } from "react-router-dom";
import supabaseClient from "../../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../../store/lessonsStore";
import fetchCurrentUser from "../../../../common/fetchCurrentUser";

const useFetchIsPersonalLesson = () => {
  const isPersonalLesson = useLessonsStore((state) => state.isPersonalLesson);
  const setIsPersonalLesson = useLessonsStore((state) => state.setIsPersonalLesson);
  const session = useLessonsStore((state) => state.session);
  const navigate = useNavigate();

  useMountedWaitAsyncEffect(async () => {
    if (session?.user && session.user.email && isPersonalLesson === null) {
      const { data: userData, error } = await supabaseClient.rpc("get_user", {
        userprop: session.user.email,
      });
      if (error) {
        sessionStorage.clear();
        navigate("/");
        return;
      }
      const fetchedUser = await fetchCurrentUser(session.user.email);
      if (fetchedUser) {
        const { is_personal_lesson } = fetchedUser;
        setIsPersonalLesson(Boolean(is_personal_lesson));
      }
    }
  }, [session]);
};

export default useFetchIsPersonalLesson;
