import { Box, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";

import ModalScheme from "../../../ModalScheme";
import { useWindowSize } from "react-use";
import removePunctuationFromString from "../../../../common/removePunctuationFromString";

export const initialNodes = (windowWidth: number) => [
  { id: "1", position: { x: windowWidth / 2 - 72, y: 0 }, data: { label: <span>Собака</span> } },
  { id: "2", position: { x: windowWidth / 2 - 75, y: 0 }, data: { label: <span>Кіт</span> } },
  { id: "3", position: { x: windowWidth / 2 - 100, y: 0 }, data: { label: <span>Мавпа</span> } },
  { id: "4", position: { x: windowWidth / 2 - 61, y: 0 }, data: { label: <span>Крокодил</span> } },
  { id: "5", position: { x: windowWidth / 2 - 38, y: 0 }, data: { label: <span>Кенгуру</span> } },
  { id: "6", position: { x: windowWidth / 2 - 70, y: 100 }, data: { label: "Тварини" } },

  { id: "7", position: { x: windowWidth / 2 - 200, y: 0 }, data: { label: <span>Василь</span> } },
  {
    id: "8",
    position: { x: windowWidth / 2 - 180, y: 0 },
    data: { label: <span>Марія</span> },
  },
  {
    id: "9",
    position: { x: windowWidth / 2 - 165, y: 0 },
    data: { label: <span>Роман</span> },
  },
  {
    id: "10",
    position: { x: windowWidth / 2 - 155, y: 0 },
    data: { label: <span>Катерина</span> },
  },
  {
    id: "11",
    position: { x: windowWidth / 2 - 132, y: 0 },
    data: { label: <span>Дмитро</span> },
  },
  { id: "12", position: { x: windowWidth / 2 - 160, y: 100 }, data: { label: "Люди" } },

  { id: "13", position: { x: windowWidth / 2, y: 0 }, data: { label: <span>Банан</span> } },
  {
    id: "14",
    position: { x: windowWidth / 2 + 32, y: 0 },
    data: { label: <span>Яблуко</span> },
  },
  {
    id: "15",
    position: { x: windowWidth / 2 + 72, y: 0 },
    data: { label: <span>Слива</span> },
  },
  {
    id: "16",
    position: { x: windowWidth / 2 + 45, y: 0 },
    data: { label: <span>Апельсин</span> },
  },
  {
    id: "17",
    position: { x: windowWidth / 2 + 17, y: 0 },
    data: { label: <span>Груша</span> },
  },
  { id: "18", position: { x: windowWidth / 2 + 40, y: 100 }, data: { label: "Фрукти" } },

  { id: "19", position: { x: windowWidth / 2 + 179, y: 0 }, data: { label: <span>Буряк</span> } },
  {
    id: "20",
    position: { x: windowWidth / 2 + 155, y: 0 },
    data: { label: <span>Морква</span> },
  },
  {
    id: "21",
    position: { x: windowWidth / 2 + 138, y: 0 },
    data: { label: <span>Перець</span> },
  },
  {
    id: "22",
    position: { x: windowWidth / 2 + 115, y: 0 },
    data: { label: <span>Картопля</span> },
  },
  {
    id: "23",
    position: { x: windowWidth / 2 + 102, y: 0 },
    data: { label: <span>Капуста</span> },
  },
  { id: "24", position: { x: windowWidth / 2 + 130, y: 100 }, data: { label: "Овочі" } },

  { id: "25", position: { x: windowWidth / 2 - 80, y: 165 }, data: { label: "Істоти" } },
  { id: "26", position: { x: windowWidth / 2 + 60, y: 165 }, data: { label: "Їжа" } },
  { id: "27", position: { x: windowWidth / 2 - 20, y: 230 }, data: { label: "Іменники" } },
];

export const initialEdges = [
  {
    id: "e1-1",
    source: "1",
    target: "6",
  },
  {
    id: "e1-2",
    source: "2",
    target: "6",
  },
  {
    id: "e1-3",
    source: "3",
    target: "6",
  },
  {
    id: "e1-4",
    source: "4",
    target: "6",
  },
  {
    id: "e1-5",
    source: "5",
    target: "6",
  },
  {
    id: "e1-7",
    source: "7",
    target: "12",
  },
  {
    id: "e1-8",
    source: "8",
    target: "12",
  },
  {
    id: "e1-9",
    source: "9",
    target: "12",
  },
  {
    id: "e1-10",
    source: "10",
    target: "12",
  },
  {
    id: "e1-11",
    source: "11",
    target: "12",
  },
  {
    id: "e1-13",
    source: "13",
    target: "18",
  },
  {
    id: "e1-14",
    source: "14",
    target: "18",
  },
  {
    id: "e1-15",
    source: "15",
    target: "18",
  },
  {
    id: "e1-16",
    source: "16",
    target: "18",
  },
  {
    id: "e1-17",
    source: "17",
    target: "18",
  },
  {
    id: "e1-19",
    source: "19",
    target: "24",
  },
  {
    id: "e1-20",
    source: "20",
    target: "24",
  },
  {
    id: "e1-21",
    source: "21",
    target: "24",
  },
  {
    id: "e1-22",
    source: "22",
    target: "24",
  },
  {
    id: "e1-23",
    source: "23",
    target: "24",
  },
  {
    id: "e1-24",
    source: "6",
    target: "25",
  },
  {
    id: "e1-25",
    source: "12",
    target: "25",
  },
  {
    id: "e1-26",
    source: "18",
    target: "26",
  },
  {
    id: "e1-27",
    source: "24",
    target: "26",
  },
  {
    id: "e1-28",
    source: "25",
    target: "27",
  },
  {
    id: "e1-29",
    source: "26",
    target: "27",
  },
];

const findNounsConfig = [
  {
    text: "Кіт сидів на килимку біля школи в місті.",
    nouns: ["кіт", "килимку", "школи", "місті"],
  },
  {
    text: "Діти гуляли в парку біля озера, де росли дерева.",
    nouns: ["діти", "парку", "озера", "дерева"],
  },
  {
    text: "Літак летів через гори, а потім приземлився на аеродром.",
    nouns: ["літак", "гори", "аеродром"],
  },
  {
    text: "Вчора ми відвідали музей і купили книги в магазині.",
    nouns: ["музей", "книги", "магазині"],
  },
];
export const Nouns: React.FC = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  return (
    <Box>
      <Typography gutterBottom>Іменники — це назви людей, тварин, речей, місць чи явищ.</Typography>
      <Typography gutterBottom>Іменники відповідають на питання: хто це?, що це?.</Typography>
      <Box>
        <Typography gutterBottom variant="h3">
          Запитання
        </Typography>
        <Typography>Що спільного у Києва, Луцька і Ковеля?</Typography>
        <Typography>Що спільного у кота, собаки та мавпи?</Typography>
        <Typography>Що спільного у футболу, тенісу і бігу?</Typography>
      </Box>
      <Box py={3}>
        <ModalScheme initialEdges={initialEdges} initialNodes={initialNodes(windowWidth)} />
      </Box>
      <Typography variant="h3">ІМЕННИК</Typography>
      <Typography gutterBottom variant="caption">
        вірш Григорія Фальковича
      </Typography>
      <Typography>
        Тиша. Сон. Будильник. Ранок.
        <br />
        Мама. Тато. Джек. Сніданок.
        <br />
        Сумка. Термос. Ковбаса.
        <br />
        Ліфт. Автобус. Шлях. Краса!
        <br />
        Стежка. Ліс. Дуби. Ялиці.
        <br />
        Вуж. Гриби. Пташки. Суниці.
        <br />
        Білка. Небо. Синь. Роса.
        <br />
        Тиша. Термос. Ковбаса.
        <br />
        Комарі. Вогонь. Димок.
        <br />
        Шлях. Автобус. Ліфт. Замок.
        <br />
        Ванна. Мило. Ліжко. Сон.
        <br />
        Джек зітхає, наче слон.
      </Typography>

      <Box py={10}>
        <Typography gutterBottom variant="h3">
          Знайди іменники
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {findNounsConfig.map(({ text, nouns }) => {
            return (
              <Typography gutterBottom>
                {text.split(" ").map((word) => {
                  return <Word word={word} nouns={nouns} />;
                })}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const Word: React.FC<{ word: string; nouns: string[] }> = ({ word, nouns }) => {
  const [color, setColor] = useState("black");
  const cleanWord = removePunctuationFromString(word);

  return (
    <Typography
      fontSize={20}
      color={color}
      component="span"
      sx={{ display: "inline-block", mr: 1.5, cursor: "pointer" }}
      onClick={() => {
        if (nouns.includes(cleanWord.toLowerCase())) {
          setColor("green");
        } else {
          setColor("red");
        }
      }}
    >
      {word}
    </Typography>
  );
};
