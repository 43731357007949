import ReactPlayer from "react-player";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PauseIcon from "@mui/icons-material/Pause";
import Box from "../Box";
import { useEffect, useMemo, useRef, useState } from "react";
import Loader from "../Loader";
import HStack from "../HStack";
import IconButton from "../IconButton";
import { Button, Slider } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useWindowSize } from "react-use";

const YouTubePlayer: React.FC<{
  id: string;
  start: number;
  duration: number;
  width?: number;
  height?: number;
}> = ({ id, start, width, height, duration }) => {
  const playerRef = useRef<any>(null);

  const [playing, setPlaying] = useState(false);
  const [preparePlaying, setPreparePlaying] = useState<any>(null);
  console.log("🚀 ~ playing:", playing);
  const { width: windowSize } = useWindowSize();
  const [currentTime, setCurrentTime] = useState(start);

  const [firstVideoLoading, setFirstVideoLoading] = useState(true);

  useEffect(() => {
    if (currentTime > start + 1) {
      setFirstVideoLoading(false);
    }
  }, [start, currentTime]);

  useEffect(() => {
    setCurrentTime(start);
  }, [start]);

  useEffect(() => {
    let timer2: any = null;
    if (firstVideoLoading && preparePlaying === true) {
      console.log("stop video");
      timer2 = setTimeout(() => {
        setFirstVideoLoading(false);
        setPreparePlaying(false);
        setPlaying(true);
      }, 3000);
    }

    return () => {
      if (timer2) clearTimeout(timer2);
    };
  }, [firstVideoLoading, preparePlaying]);

  const handlePlayPause = () => {
    if (preparePlaying === null) {
      setPreparePlaying(true);
    }
    setPlaying(!playing);
  };

  const { videoWidth, videoHeight, videoContainerWidth, videoContainerHight } = useMemo(() => {
    if (windowSize < 400) {
      return {
        videoWidth: 9,
        videoHeight: 5,
        videoContainerWidth: 280,
        videoContainerHight: 154,
      };
    }
    if (windowSize < 500) {
      return {
        videoWidth: 12,
        videoHeight: 7,
        videoContainerWidth: 355,
        videoContainerHight: 210,
      };
    }
    return {
      videoWidth: 16,
      videoHeight: 9,
      videoContainerWidth: 455,
      videoContainerHight: 277,
    };
  }, [windowSize]);

  const handleSliderChange = (e: any) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(Math.ceil(newTime * 1000) / 1000);
    (playerRef.current as any).seekTo(Math.ceil(newTime * 1000) / 1000);
  };

  const timeUpdateLoop = () => {
    if (playerRef.current && !firstVideoLoading) {
      const newCurrentTime = (playerRef.current as any).getCurrentTime();

      if (newCurrentTime > start + duration) {
        setCurrentTime(Math.ceil(start));
        setPlaying(false);
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else if (newCurrentTime < start) {
        setCurrentTime(Math.ceil(start));
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else {
        setCurrentTime(newCurrentTime);
      }
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    timer = setInterval(() => {
      if (playing) {
        timeUpdateLoop();
      }
    }, 500);
    // }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentTime, playing]);

  const [videoLoading, setVideoLoading] = useState(true);

  return (
    <>
      <Box
        position="relative"
        pt={`${videoContainerHight}px`}
        // width={width || "100%"}
        width={videoContainerWidth}
        // mb={4}
        sx={{
          display: "flex",
          overflow: "hidden",
          border: "2px solid #dbdada",
        }}
      >
        {(videoLoading || preparePlaying === true) && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "white",
            }}
            zIndex={4}
          >
            <Loader />
          </Box>
        )}
        <Box position="absolute" top={0} left={0} width="100%" height="100%">
          <Box
            component={ReactPlayer}
            controls={false}
            ref={playerRef}
            playing={playing}
            width="100%"
            height={height || "100%"}
            onReady={() => {
              setVideoLoading(false);
            }}
            onPlay={() => {
              if (firstVideoLoading) {
                setPlaying(false);
              }
            }}
            onPause={() => setPlaying(false)}
            // onProgress={handleProgress}
            sx={{
              width: "100% !important",
              height: "100% !important",
              // height: "305px !important",
              // height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
              display: "flex",
              justifyContent: "center",
              "& div": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                // backgroundColor: "gray",
                // backgroundColor: "red",
              },
              "& iframe": {
                // width: "10px",
                // height: "6px",
                width: videoWidth,
                height: videoHeight,
                transform: "scale(31)",
                // transform: "translate(-50%, -50%) scale(52)",
                transformOrigin: "center center",
                // transformOrigin: "0 0",
                // left: "50%",
                // top: "50%",
                // top: "-20px",
                // top: "0",
                position: "relative",
              },
            }}
            config={{
              youtube: {
                playerVars: {
                  controls: false,
                  start, // Set the start time
                },
              },
            }}
            url={`https://youtu.be/${id}`}
          />
          {!playing && preparePlaying !== true && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 2,
                backgroundColor: "red",
                backgroundPosition: "center",
                backgroundSize: "100%",
                backgroundImage: `url(https://img.youtube.com/vi/${id}/0.jpg)`,
              }}
            >
              {/* <IconButton
                sx={{
                  position: "relative",
                  zIndex: 5,
                  // backgroundColor: "white",
                  "&::before": {
                    content: "' '",
                    position: "absolute",
                    top: 32,
                    left: 35,
                    backgroundColor: "white",
                    width: 20,
                    height: 20,
                  },
                  "&:hover": {},
                }}
                onClick={() => {
                  if (preparePlaying === null) {
                    setPreparePlaying(true);
                    setPlaying(true);
                  } else {
                    setPlaying((prev) => !prev);
                  }
                }}
              >
                <YouTubeIcon sx={{ fontSize: 70, color: "red", position: "relative", zIndex: 1 }} />
              </IconButton> */}
            </Box>
          )}
        </Box>
      </Box>
      <HStack
        width={videoContainerWidth}
        sx={{
          backgroundColor: "white",
          // position: "absolute",
          // bottom: -40,
          left: 0,
          // width: "100%",
          zIndex: 5,
          flexWrap: "nowrap",
          alignItems: "center",
          // borderTop: "2px solid gray",
        }}
      >
        <IconButton onClick={handlePlayPause}>
          {playing ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>

        <Box position="relative" width="100%" display="flex">
          <Slider
            aria-label="Volume"
            value={currentTime}
            min={start}
            max={start + duration}
            size="small"
            sx={{ width: "100%" }}
            // step="any" // Allow for smooth seeking
            onChange={handleSliderChange}
          />
        </Box>

        <Button
        // onClick={() => {
        //   setDuration(currentTime - start);
        // }}
        >
          {Math.floor((currentTime - start) / 60)}.{Math.floor((currentTime - start) % 60)}
        </Button>
      </HStack>
    </>
  );
};

export default YouTubePlayer;
