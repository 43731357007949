import { useMemo } from "react";
import { useLessonsStore } from "../store/lessonsStore";

const useIsHomeWork = ({
  lessonId,
  lessonType,
}: {
  lessonId: number;
  lessonType: string;
}): boolean => {
  const authUser = useLessonsStore((state) => state.authUser);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);

  const isHomeWork = useMemo(() => {
    return Boolean(!isTeacherLesson && authUser?.read_lesson);
  }, [authUser, isTeacherLesson]);
  console.log("🚀 ~ isHomeWork:", isHomeWork);

  return isHomeWork;
};

export default useIsHomeWork;
