import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, Box, Typography } from "@mui/material";
import "./style.css";
import ItalicTitle from "../../../Title/ItalicTitle";
import VStack from "../../../Unknown/VStack";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import getRandomGreetings from "../../../../common/getRandomGreetings";
import WordAudio from "../../../Audio/WordAudio";

interface ConfettiProps {
  open: boolean;
  message?: string;
  subMessage?: string;
  continueButton?: boolean;
  onClose: () => void;
  onContinue?: () => void;
}

const CongratulationsModal: React.FC<ConfettiProps> = ({
  open,
  message,
  subMessage,
  continueButton,
  onClose,
  onContinue,
}) => {
  const containerRef = useRef<any>();
  const [shitCount, setShitCount] = useState(0);

  useEffect(() => {
    if (open && containerRef?.current) {
      const confettiful: any = new Confettiful(containerRef.current);
      return () => clearInterval(confettiful.confettiInterval); // Clear interval when component unmounts
    }
  }, [open, containerRef, shitCount]);

  useEffect(() => {
    let interval = open
      ? setInterval(() => {
          setShitCount((prev) => prev + 1);
        }, 1000)
      : null;

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [open]);

  const endGreeting = useMemo(() => {
    return getRandomGreetings("");
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confetti-modal"
      aria-describedby="celebration-confetti"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Box
          className="confetti-container"
          ref={containerRef}
          sx={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            // backgroundColor: "rgba(255,255,255,0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999999999,
          }}
        ></Box>
        <VStack
          sx={{
            textAlign: "center",
            backgroundColor: "rgba(255,255,255,1)",
            padding: 10,
            width: "auto",
            minWidth: 300,
            zIndex: 1302,
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ModalCloseButton onClose={onClose} />
          {!message && <ItalicTitle size={25} text="Вітаю!" />}
          {!message && endGreeting}
          {message && <ItalicTitle size={25} text={message} />}
          {subMessage && <Typography fontSize={16}>{subMessage}</Typography>}
          <Box sx={{ pointerEvents: "none" }}>
            {open && <WordAudio boxHide autoPlay url="/audio/congrats-audio.mp3" />}
          </Box>
          {continueButton && (
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                if (onContinue) onContinue();
              }}
              sx={{ mt: 2 }}
            >
              Продовжити практикуватися
            </Button>
          )}
          <Button variant="contained" color="success" onClick={onClose} sx={{ mt: 2 }}>
            Завершити
          </Button>
        </VStack>
      </Box>
    </Modal>
  );
};

export default CongratulationsModal;

// Confettiful class in TypeScript
class Confettiful {
  private el: HTMLElement;
  private containerEl: HTMLDivElement | null;
  private confettiFrequency: number;
  private confettiColors: string[];
  private confettiAnimations: string[];
  public confettiInterval: NodeJS.Timeout | null;

  constructor(el: HTMLElement) {
    this.el = el;
    this.containerEl = null;
    this.confettiFrequency = 3;
    this.confettiColors = ["#EF2964", "#00C09D", "#2D87B0", "#48485E", "#EFFF1D"];
    this.confettiAnimations = ["slow", "medium", "fast"];
    this.confettiInterval = null;

    this._setupElements();
    this._renderConfetti();
  }

  private _setupElements() {
    const containerEl = document.createElement("div");
    this.el.style.position = "relative";
    containerEl.classList.add("confetti-container");
    this.el.appendChild(containerEl);
    this.containerEl = containerEl;
  }

  private _renderConfetti() {
    this.confettiInterval = setInterval(() => {
      const confettiEl: any = document.createElement("div");
      const confettiSize = `${Math.floor(Math.random() * 3) + 7}px`;
      const confettiBackground =
        this.confettiColors[Math.floor(Math.random() * this.confettiColors.length)];
      const confettiLeft = `${Math.floor(Math.random() * this.el.offsetWidth)}px`;
      const confettiAnimation =
        this.confettiAnimations[Math.floor(Math.random() * this.confettiAnimations.length)];

      confettiEl.classList.add("confetti", `confetti--animation-${confettiAnimation}`);
      confettiEl.style.left = confettiLeft;
      confettiEl.style.width = confettiSize;
      confettiEl.style.height = confettiSize;
      confettiEl.style.backgroundColor = confettiBackground;

      confettiEl.removeTimeout = setTimeout(() => {
        confettiEl.parentNode?.removeChild(confettiEl);
      }, 3000);

      this.containerEl?.appendChild(confettiEl);
    }, 25);
  }
}
