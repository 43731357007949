import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { adminUserId } from "../../common/constants";
import { RPCUser } from "../../common/fetchCurrentUser";
import { useLessonsStore } from "../../store/lessonsStore";

const AdminUsers: React.FC<{
  users: RPCUser[];
  studentUserId?: string | null;
  onChange: (user: RPCUser) => void;
}> = ({ users, studentUserId: inputStudentUserId, onChange }) => {
  const authUserId = useLessonsStore((state) => state.authUser?.id);
  const [currentUser, setCurrentUser] = useState<RPCUser | null>(null);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);

  useEffect(() => {
    if (inputStudentUserId === null) setCurrentUser({ id: "" } as any);
  }, [inputStudentUserId]);

  if (authUserId !== adminUserId) return null;

  return (
    <Select
      // value={currentUser?.id || ""}
      value={studentUser?.id || ""}
      variant="standard"
      style={{
        // height: "16px",
        // fontSize: "12px",
        minHeight: 0,
        minWidth: 200,
      }}
      onChange={(value) => {
        if (value) {
          const newUser = users.find((u) => u.id === value.target.value)!;
          setCurrentUser(newUser);
          setStudentUser(newUser);
          onChange(newUser);
        } else {
          setCurrentUser({ id: "" } as any);
          onChange({ id: "" } as any);
        }
      }}
    >
      <MenuItem key="none" value={""}>
        None
      </MenuItem>
      {users.map((w, index) => (
        <MenuItem key={w.id} value={w.id}>
          {w.id}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AdminUsers;
