import { format, getDay } from "date-fns";
import Box from "../../Unknown/Box";
import { useEffect, useMemo, useState } from "react";
import { useLocalStorage, useWindowSize } from "react-use";
import { Link as MuiLink } from "@mui/material";
import useLessonWords from "../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../store/lessonsStore";
import { initialLessons } from "../../LearnToRead/LearnToReadScreen";
import WordsCarouselContainer from "../WordsCarouselContainer";
import parsed from "../../../common/parsed";
import BuildWordsContainer from "../BuildWordsContainer";
import ItalicTitle from "../../Title/ItalicTitle";
import VStack from "../../Unknown/VStack";
import SimpleLessonTasksContainer from "../SimpleLessonTasksContainer";
import { rules } from "../../Unknown/Root/grammarMap";
import { lessonRules } from "../../LearnToRead/SimpleLessonTasks";
import useLearnToReadLastLesson from "../../../hooks/useLearnToReadLastLesson";
import { HOME_WORK_TASKS } from "../../../hooks/useHomeWorkDetection";
import VideosContainer from "../VideosContainer";
import { Link } from "react-router-dom";
import HomeWorkCalendar from "./HomeWorkCalendar";

const HomeWorkScreen = () => {
  const { width } = useWindowSize();
  const authUser = useLessonsStore((state) => state.authUser);
  const readToLearnExplanationLessons = useLessonsStore(
    (state) => state.homeWork.readToLearnExplanationLessons || [],
  );
  const lastLesson = useLearnToReadLastLesson();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [width]);

  const calendar = useMemo(() => {
    return null;
  }, [width]);

  const [hmWordsTracking] = useLocalStorage("user-homework-current-day");
  const homeWork = useLessonsStore((state) => state.homeWork);
  const homeWorkProgress = useLessonsStore((state) => state.homeWork.progress);

  if (!lastLesson) return null;

  return (
    // <Box width={`${width - 100}px`} px={2}>
    <Box
      px={2}
      sx={{
        // m: 2,
        pl: 4,
        position: "relative",
        "&::before": {
          content: '" "',
          width: 4,
          position: "absolute",
          height: "calc(100%)",
          top: 0,
          left: 0,
          backgroundColor: "#4aa805",
        },
        // boxShadow: "0px 0px 10px 5px  rgba(0, 0, 0, 0.1)",
      }}
    >
      <ItalicTitle
        fontFamily="Roboto"
        color={homeWorkProgress < 50 ? "red" : homeWorkProgress < 80 ? "#b3ae21" : "green"}
        size={14}
        text={`Виконано на: ${homeWorkProgress || 0}%`}
      />
      <br />
      <ItalicTitle fontFamily="Roboto" size={14} text="Завдання на сьогодні:" />
      <br />
      <ItalicTitle
        size={14}
        fontFamily="Roboto"
        text={`${
          ["Неділя", "Понеділок", "Вівторок", "Середа", "Четвер", "Пʼятниця", "Субота"][
            getDay(new Date())
          ]
        }`}
      />
      &nbsp;
      <ItalicTitle size={12} color="gray" text={`(${format(new Date(), "dd-MM-yyyy")})`} />
      <hr />
      <VStack gap={5} mb={3} alignItems="flex-start">
        {(() => {
          const taskEntries = Object.entries(homeWork.tasks);

          const additionalTaskIndexOne = taskEntries.findIndex(
            (t) =>
              t[0] === HOME_WORK_TASKS.Також_ти_можеш_переглянути_пояснення_з_попереднього_уроку,
          );

          if (additionalTaskIndexOne !== -1) {
            const additionalTask = taskEntries[additionalTaskIndexOne];
            taskEntries.splice(additionalTaskIndexOne, 1);
            taskEntries.push(additionalTask);
          }

          return taskEntries || [];
        })().map(([title, node], index) => {
          if (title === HOME_WORK_TASKS.Повтори_вивчи_слова_з_попереднього_уроку) {
            return (
              <Box key={title} width="100%" maxWidth={width - 50}>
                <ItalicTitle
                  lineHeight={1.1}
                  size={16}
                  color={!!homeWork.wordsToRepeat.length ? "red" : "primary"}
                  text={HOME_WORK_TASKS.Повтори_вивчи_слова_з_попереднього_уроку}
                />
                <WordsCarouselContainer words={lastLesson.words} />
              </Box>
            );
          } else if (title === HOME_WORK_TASKS.Склади_слова_з_попереднього_уроку) {
            return (
              <Box key={title} width="100%" maxWidth={width - 50}>
                <ItalicTitle
                  size={16}
                  lineHeight={1.1}
                  color={!!homeWork.wordsToBuild.length ? "red" : "primary"}
                  text={HOME_WORK_TASKS.Склади_слова_з_попереднього_уроку}
                />
                <BuildWordsContainer words={lastLesson.words} />
              </Box>
            );
          } else if (title === HOME_WORK_TASKS.Повтори_правила) {
            const rules = lessonRules[lastLesson.number];

            return (
              <Box key={title} width="100%" maxWidth={width - 50}>
                <ItalicTitle
                  size={16}
                  lineHeight={1.1}
                  color={!!homeWork.rulesToRepeat.length ? "red" : "primary"}
                  text={HOME_WORK_TASKS.Повтори_правила}
                />
                <SimpleLessonTasksContainer rules={rules} />
              </Box>
            );
          } else if (
            title === HOME_WORK_TASKS.Переглянь_декілька_відео &&
            homeWork.videosToRepeat.length
          ) {
            return (
              <Box key={title} width="100%" maxWidth={width - 50}>
                <ItalicTitle
                  size={16}
                  lineHeight={1.1}
                  color={!!homeWork.wordsToBuild.length ? "red" : "primary"}
                  text={HOME_WORK_TASKS.Переглянь_декілька_відео}
                />
                <VideosContainer />
              </Box>
            );
          } else if (
            title === HOME_WORK_TASKS.Також_ти_можеш_переглянути_пояснення_з_попереднього_уроку &&
            readToLearnExplanationLessons?.length
          ) {
            return (
              <VStack alignItems="flex-start" gap={0} key={title}>
                <ItalicTitle
                  size={16}
                  lineHeight={1.1}
                  color="primary"
                  text={HOME_WORK_TASKS.Також_ти_можеш_переглянути_пояснення_з_попереднього_уроку}
                />
                {(readToLearnExplanationLessons || []).map((lessonNumber) => (
                  <MuiLink
                    key={lessonNumber}
                    color="secondary.dark"
                    // sx={{ textDecoration: "none" }}
                    component={Link}
                    display="inline-block"
                    // to={`/grammar/grammar-lesson/${lesson.number}/build-words-list`}
                    to={`/learn-to-read/${lessonNumber}`}
                  >
                    {initialLessons[lessonNumber.toString()]?.title}
                  </MuiLink>
                ))}
              </VStack>
            );
          }
          return <Box key={title}>{node}</Box>;
        })}
      </VStack>
      {/* {isLoading && <Loader />} */}
      {/* {!isLoading && calendar} */}
      <HomeWorkCalendar />
    </Box>
  );
};

export default HomeWorkScreen;
