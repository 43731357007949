import { useMemo } from "react";
import { useLessonsStore } from "../../../store/lessonsStore";
import Button from "../Button";
import HStack from "../HStack";
import ItalicTitle from "../../Title/ItalicTitle";
import { Typography } from "@mui/material";
import VStack from "../VStack";
import CongratulationsModal from "../../LearnToRead/UkTrainers/CongratulationsModal";
import { userHomeWorkCurrentDay } from "../../../common/constants";
import parsed from "../../../common/parsed";
import { format } from "date-fns";
import { typesOfHomeWork } from "../../../types";
import useLastPrev from "../ShowLastAndPrevCountButtons/useLastPrev";

function getWordForm(number: number) {
  const absNumber = Math.abs(number); // У разі від'ємного числа беремо модуль
  const lastDigit = absNumber % 10;
  const lastTwoDigits = absNumber % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return "слів"; // Особливий випадок для 11–14
  }

  if (lastDigit === 1) {
    return "слово"; // Наприклад, 1, 21, 101
  }

  if (lastDigit >= 2 && lastDigit <= 4) {
    return "слова"; // Наприклад, 2, 3, 4, 22, 33
  }

  return "слів"; // Усі інші випадки
}

const buttonStyles = {
  pointerEvents: "none",
  minWidth: 0,
  p: 0,
  fontSize: 15,
  lineHeight: 1,
};

const ShowCurrentHomeWorkNumbersInDrawer: React.FC<{
  type: typesOfHomeWork;
}> = ({ type }) => {
  const repeatedWords = useLessonsStore((state) => state.homeWork.repeatedWords);
  const wordsToRepeat = useLessonsStore((state) => state.homeWork.wordsToRepeat);
  const builtWords = useLessonsStore((state) => state.homeWork.builtWords);
  const wordsToBuild = useLessonsStore((state) => state.homeWork.wordsToBuild);
  const rulesToRepeat = useLessonsStore((state) => state.homeWork.rulesToRepeat);
  const repeatedRules = useLessonsStore((state) => state.homeWork.repeatedRules);
  const buildWordsGreeting = useLessonsStore((state) => state.homeWork.greetings["build-words"]);
  const learnWordsGreeting = useLessonsStore((state) => state.homeWork.greetings["learn-words"]);
  const repeatRulesGreeting = useLessonsStore((state) => state.homeWork.greetings["rules"]);
  const repeatVideosGreeting = useLessonsStore((state) => state.homeWork.greetings["videos"]);
  const homeworkGreeting = useLessonsStore((state) => state.homeWork.greetings);
  const homework = useLessonsStore((state) => state.homeWork);
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);

  const { last, prev } = useLastPrev({ type });

  const greetingText = useMemo(() => {
    if (type === "learn-words") {
      return "Всі слова вивчено/повторено!";
    } else if (type === "build-words") {
      return "Всі слова складено!";
    } else if (type === "rules") {
      return "Всі правила повторені!";
    } else if (type === "videos") {
      return "Всі відео на сьогодні переглянуто!";
    }
    return "";
  }, [type]);

  const lastButton = useMemo(() => {
    if (!last) return null;
    if (type === "learn-words" || type === "build-words" || type === "rules") {
      return (
        <Typography>
          Залишилося&nbsp;
          <Button sx={buttonStyles} color="error">
            {last}
          </Button>
          &nbsp;{getWordForm(last)}
        </Typography>
      );
    } else if (type === "videos") {
      return (
        <Typography>
          Залишилося&nbsp;
          <Button sx={buttonStyles} color="error">
            {last}
          </Button>
          &nbsp;відео
        </Typography>
      );
    }

    return "";
  }, [type, last, prev]);

  const prevButton = useMemo(() => {
    if (type === "learn-words" || type === "rules") {
      return (
        <Typography>
          Повторено&nbsp;
          <Button sx={buttonStyles} color="primary">
            {prev}
          </Button>
          &nbsp;{getWordForm(prev)}
        </Typography>
      );
    } else if (type === "build-words") {
      return (
        <Typography>
          Складено&nbsp;
          <Button sx={buttonStyles} color="primary">
            {prev}
          </Button>
          &nbsp;{getWordForm(prev)}
        </Typography>
      );
    } else if (type === "videos") {
      return (
        <Typography>
          Переглянуто&nbsp;
          <Button sx={buttonStyles} color="primary">
            {prev}
          </Button>
          &nbsp;відео
        </Typography>
      );
    }
    return "";
  }, [type, prev, last]);

  const congratulationText = useMemo(() => {
    if (type === "learn-words") {
      return ["Всі слова повторено.", "Але ти можеш продовжити якщо хочеш ще потренуватися."];
    } else if (type === "build-words") {
      return ["Всі слова складено.", "Але ти можеш продовжити якщо хочеш ще потренуватися."];
    } else if (type === "rules") {
      return ["Всі правила повторено."];
    } else if (type === "videos") {
      return ["Всі відео переглянуто."];
    }
    return "";
  }, [type]);

  const updateLocalStorageGreeting = () => {
    const storage = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});
    const currentDate = format(new Date(), "yyyy-MM-dd");
    if (type === "build-words") {
      localStorage.setItem(
        userHomeWorkCurrentDay,
        JSON.stringify({
          ...storage,
          [currentDate]: {
            ...(storage[currentDate] || {}),
            buildWordsGreeting: true,
          },
        }),
      );
    } else if (type === "learn-words") {
      localStorage.setItem(
        userHomeWorkCurrentDay,
        JSON.stringify({
          ...storage,
          [currentDate]: {
            ...(storage[currentDate] || {}),
            learnWordsGreeting: true,
          },
        }),
      );
    } else if (type === "rules") {
      localStorage.setItem(
        userHomeWorkCurrentDay,
        JSON.stringify({
          ...storage,
          [currentDate]: {
            ...(storage[currentDate] || {}),
            repeatRulesGreeting: true,
          },
        }),
      );
    } else if (type === "videos") {
      localStorage.setItem(
        userHomeWorkCurrentDay,
        JSON.stringify({
          ...storage,
          [currentDate]: {
            ...(storage[currentDate] || {}),
            repeatVideosGreeting: true,
          },
        }),
      );
    }
  };

  const updateStateGreeting = () => {
    if (type === "build-words" && !buildWordsGreeting) {
      updateHomeWork({
        greetings: {
          ...homeworkGreeting,
          ["build-words"]: true,
        },
      });
    } else if (type === "learn-words" && !learnWordsGreeting) {
      updateHomeWork({
        greetings: {
          ...homeworkGreeting,
          ["learn-words"]: true,
        },
      });
    } else if (type === "rules" && !repeatRulesGreeting) {
      updateHomeWork({
        greetings: {
          ...homeworkGreeting,
          ["rules"]: true,
        },
      });
    } else if (type === "videos" && !repeatVideosGreeting) {
      updateHomeWork({
        greetings: {
          ...homeworkGreeting,
          ["videos"]: true,
        },
      });
    }
  };

  const isGreetingOpen = useMemo(() => {
    if (type === "build-words" && !last && !buildWordsGreeting) {
      return true;
    } else if (type === "learn-words" && !last && !learnWordsGreeting) {
      return true;
    } else if (type === "rules" && !last && !repeatRulesGreeting) {
      return true;
    } else if (type === "videos" && !last && !repeatVideosGreeting) {
      return true;
    }
    return false;
  }, [
    last,
    type,
    buildWordsGreeting,
    learnWordsGreeting,
    repeatRulesGreeting,
    repeatVideosGreeting,
  ]);
  console.log("🚀 ~ last:", last);

  return (
    <VStack
      gap={0}
      alignItems="flex-start"
      sx={{
        position: "fixed",
        top: type === "videos" ? 0 : 10,
        left: type === "videos" ? 0 : 10,
        p: 2,
        backgroundColor: "white",
        boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
        zIndex: 3,
      }}
    >
      <CongratulationsModal
        open={isGreetingOpen}
        message={congratulationText[0]}
        subMessage={congratulationText[1]}
        continueButton={["build-words", "learn-words"].includes(type)}
        onClose={() => {
          // restart();
          // setIsOpen(false);
          updateStateGreeting();
          updateLocalStorageGreeting();
          setLessonModalOpen("");
        }}
        onContinue={
          ["build-words", "learn-words"].includes(type)
            ? () => {
                updateStateGreeting();
                updateLocalStorageGreeting();
              }
            : undefined
        }
      />
      <ItalicTitle size={15} text="Виконання домашнього завдання:" />
      {!last && greetingText && <ItalicTitle text={greetingText} />}
      {lastButton}
      {prevButton}
    </VStack>
  );
};

export default ShowCurrentHomeWorkNumbersInDrawer;
