import Box from "../../Unknown/Box";

const FoodItemImage: React.FC<{ image: string; size?: "big"; folderName?: string }> = ({
  image,
  size,
  folderName,
}) => {
  return (
    <Box
      width="100%"
      maxWidth={320}
      // height={size ? 250 : 100}
      height="auto"
      overflow="hidden"
      component="img"
      src={`/read-to-learn-images/${image}.jpg`}
      // sx={{
      //   backgroundImage: folderName
      //     ? `url(/read-to-learn-images/${image}.jpg)`
      //     : `url(food-items-images/${image}.jpg)`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      // }}
    />
  );
};

export default FoodItemImage;
