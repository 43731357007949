import { Typography } from "@mui/material";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import VStack from "../../../Unknown/VStack";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";

const StartMessage: React.FC<{ text: any; onStart: () => void }> = ({ text, onStart }) => {
  return (
    <VStack
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        overflow: "hidden",
        minHeight: "100%",
        alignItems: "center",
        zIndex: 999999,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 0,
          backgroundColor: "black",
          opacity: 0.5,
        }}
      ></Box>
      <VStack
        maxWidth={400}
        zIndex={2}
        position="relative"
        p={10}
        sx={{
          backgroundColor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ModalCloseButton onClose={onStart} />
        <Typography
          sx={{
            // color: "text.secondary",
            textAlign: "center",
            fontSize: 30,
          }}
        >
          {text}
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            onStart();
          }}
        >
          Почати
        </Button>
      </VStack>
    </VStack>
  );
};

export default StartMessage;
